import React, { useState, useEffect } from 'react';
import {
    TextField,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
    InputAdornment,
    TablePagination,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Search as SearchIcon } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import ConfirmDeleteModal from '../components/sessions/ConfirmDeleteModal';
import CancelSessionModal from "../components/sessions/CancelSessionModal"; // Import Cancel Modal


const SearchBar = styled(TextField)({
    marginBottom: '20px',
    width: '100%',
    backgroundColor: '#202020',
    borderRadius: '8px',
    color: '#fff',
    '& .MuiInputBase-root': {
        color: '#fff',
        fontSize: '1rem',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#12c099',
    },
    '& .MuiInputLabel-root': {
        color: '#12c099',
        fontSize: '1rem',
    },
});

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#202020',
    borderRadius: '12px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
});

const CompactTableCell = styled(TableCell)({
    padding: '16px 20px',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
        display: 'block',
        width: '100%',
        padding: '12px 0',
        fontSize: '0.9rem',
        textAlign: 'left',
    },
    color: '#fff',
});

const CompactTableRow = styled(TableRow)({
    '@media (max-width: 768px)': {
        display: 'block',
        width: '100%',
        marginBottom: '20px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        backgroundColor: '#202020',
        overflow: 'hidden',
    },
});

const PaginationBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    '@media (max-width: 768px)': {
        marginTop: '10px',
    },
});

const SessionsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    backgroundColor: '#1c1c1c',
    minHeight: '100vh',
    '@media (max-width: 768px)': {
        padding: '0.5rem',
    },
});

const HeaderContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    flexWrap: 'wrap',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
});

const Title = styled(Typography)({
    fontSize: '1.8rem',
    color: '#fff',
    fontWeight: 'bold',
    '@media (max-width: 768px)': {
        fontSize: '1.5rem',
    },
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    marginTop: 'auto',
});

const Sessions = () => {
    const [sessions, setSessions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [deleteSessionId, setDeleteSessionId] = useState(null); // Track sessions to delete
    const [cancelSessionId, setCancelSessionId] = useState(null); // Track sessions to cancel


    useEffect(() => {
        const fetchSessions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sessions`);
                console.log("Fetched Sessions Data:", response.data);
                setSessions(response.data);
            } catch (error) {
                console.error('Error fetching sessions data:', error);
            }
        };
        fetchSessions();
    }, []);

    const handleDeleteSuccess = (sessionId) => {
        setSessions((prev) => prev.filter(session => session._id !== sessionId));
    };

    const handleCancelSuccess = (sessionId) => {
        setSessions((prev) => prev.filter((session) => session._id !== sessionId));
    };

    const sortedSessions = [...sessions].sort((a, b) => {
        const today = new Date();
        const dateA = new Date(a.eventDate);
        const dateB = new Date(b.eventDate);

        if (dateA >= today && dateB >= today) {
            return dateA - dateB; // Sort upcoming events in ascending order
        }
        if (dateA < today && dateB < today) {
            return dateB - dateA; // Sort past events in descending order
        }
        return dateA >= today ? -1 : 1; // Prioritize future sessions over past
    });


    const filteredSessions = sortedSessions.filter((session) => {
        const matchesSearch = session.name.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesDateRange =
            (!startDate || new Date(session.eventDate) >= startDate) &&
            (!endDate || new Date(session.eventDate) <= endDate);
        return matchesSearch && matchesDateRange;
    });

    const paginatedSessions = sortedSessions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <SessionsContainer>
            <HeaderContainer>
                <Title variant="h4" gutterBottom>Sessions</Title>
                <SearchBar
                    variant="outlined"
                    label="Search Sessions"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: '#fff' }} />
                            </InputAdornment>
                        ),
                    }}
                />
            </HeaderContainer>

            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '1rem',
                alignItems: 'center',
                marginBottom: '20px',
            }}>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="Start Date"
                    customInput={<TextField variant="outlined" placeholder="Start Date" sx={{ backgroundColor: '#282828', color: '#fff', width: '100%' }} />}
                />
                <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="End Date"
                    minDate={startDate}
                    customInput={<TextField variant="outlined" placeholder="End Date" sx={{ backgroundColor: '#282828', color: '#fff', width: '100%' }} />}
                />
            </Box>

            <StyledTableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CompactTableCell>Session</CompactTableCell>
                            <CompactTableCell>Host</CompactTableCell>
                            <CompactTableCell>Date</CompactTableCell>
                            <CompactTableCell>Reservations</CompactTableCell>
                            <CompactTableCell>Check-Ins</CompactTableCell>
                            <CompactTableCell align="center">Actions</CompactTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedSessions.map((session) => {
                            const sessionDate = new Date(session.eventDate);
                            const isUpcoming = sessionDate >= new Date(); // Check if sessions is in the future

                            return (
                                <CompactTableRow key={session._id}>
                                    <CompactTableCell>
                                        <Link to={`/session/${session._id}`} style={{ color: '#12c099', textDecoration: 'none', fontWeight: 'bold' }}>
                                            {session.name}
                                        </Link>
                                    </CompactTableCell>
                                    <CompactTableCell>{session.hostName}</CompactTableCell>
                                    <CompactTableCell>{sessionDate.toLocaleDateString()}</CompactTableCell>
                                    <CompactTableCell>{session.reservedBy?.length || 0}</CompactTableCell>
                                    <CompactTableCell>{session.attendees?.length || 0}</CompactTableCell>
                                    <CompactTableCell align="center">
                                        {isUpcoming && session.currentStatus !== 'CANCELLED' ? (
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: '#00ACC1',
                                                    color: '#fff',
                                                    fontSize: '0.75rem',
                                                    padding: '4px 8px',
                                                    '&:hover': { backgroundColor: '#00838F' },
                                                }}
                                                onClick={() => {
                                                    setCancelSessionId(session._id);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor: '#e57373', // Softer red
                                                    color: '#fff',
                                                    fontSize: '0.75rem',
                                                    padding: '4px 8px',
                                                    '&:hover': { backgroundColor: '#ef5350' },
                                                }}
                                                onClick={() => setDeleteSessionId(session._id)}
                                            >
                                                Delete
                                            </Button>
                                        )}
                                    </CompactTableCell>

                                </CompactTableRow>
                            );
                        })}
                    </TableBody>
                </Table>

                <TablePagination
                    component="div"
                    count={filteredSessions.length}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                    sx={{ backgroundColor: '#202020', color: '#fff' }}
                />
            </StyledTableContainer>

            {/* Modals */}
            {deleteSessionId && (
                <ConfirmDeleteModal
                    open
                    onClose={() => setDeleteSessionId(null)}
                    sessionId={deleteSessionId}
                    onDeleteSuccess={handleDeleteSuccess}
                />
            )}

            {cancelSessionId && (() => {
                const selectedSession = sessions.find(session => session._id === cancelSessionId);

                // Add logging to verify the selected session and the createdBy field
                console.log("Selected Session:", selectedSession);
                console.log("CreatedBy Field:", selectedSession?.createdBy);

                // Render the CancelSessionModal only if the createdBy field exists
                if (!selectedSession?.createdBy) {
                    console.error("Error: No createdBy field found for session ID:", cancelSessionId);
                    return null;
                }

                return (
                    <CancelSessionModal
                        open
                        onClose={() => setCancelSessionId(null)}
                        sessionId={cancelSessionId}
                        createdBy={selectedSession.createdBy} // Ensure the proper employee is always referenced
                        onCancelSuccess={handleCancelSuccess}
                    />
                );
            })()}

            <Box textAlign="center" color="white" mt={4}>
                &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
            </Box>
        </SessionsContainer>
    );
};

export default Sessions;
