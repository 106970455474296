import React from "react";
import { Typography } from "@mui/material";

const PartnerName = ({ name }) => (
    <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: "bold", color: "#12c099" }}>
        {name}
    </Typography>
);

export default PartnerName;
