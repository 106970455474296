import React from 'react';
import { Box, CssBaseline, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import CAC from '../components/marketing/CAC';
import CPL from '../components/marketing/CPL';
import TotalLeads from '../components/marketing/TotalLeads';
import TopPartners from '../components/marketing/TopPartners';
import UnderPerformers from '../components/marketing/UnderPerformers';
import MarketingChannels from '../components/marketing/MarketingChannels';
import TotalSpend from '../components/marketing/TotalSpend';

const Root = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#1c1c1c',
    color: '#fff',
    boxSizing: 'border-box',
    overflowX: 'hidden',
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    marginTop: 'auto',
    width: '100%',
});

const Marketing = () => {
    return (
        <Root>
            <CssBaseline />
            <Box sx={{ flex: 1, padding: '2rem' }}>
                <Box sx={{ marginBottom: '2.5rem' }}>
                    <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Marketing Overview
                    </Typography>
                </Box>

                <Grid container spacing={4} alignItems="stretch">
                    {/* 🔹 Row 2: Bigger Total Spend & Total Leads Side by Side */}
                    <Grid item xs={12} md={6}>
                        <TotalSpend />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TotalLeads />
                    </Grid>

                    {/* 🔹 Row 3: Bigger CAC & CPL for Financial Insights */}
                    <Grid item xs={12} md={6}>
                        <CAC />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CPL />
                    </Grid>

                    {/* 🔹 Row 4: Partner Performance Metrics */}
                    <Grid item xs={12} sm={6} md={6}>
                        <TopPartners />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <UnderPerformers />
                    </Grid>
                </Grid>
            </Box>

            {/* 🔹 Footer Section */}
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </Root>
    );
};

export default Marketing;
