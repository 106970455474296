import React from "react";
import { Box, Typography } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { styled } from "@mui/material/styles"; // ✅ Import styled
import axios from "axios"; // ✅ Import axios
import Card from "./Card"; // ✅ Import Card component

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || "https://api.admin.academy.marsreel.com";

const BoardContainer = styled(Box)({
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gap: "8px",
    width: "100%",
    overflowX: "auto",
});

const ColumnContainer = styled(Box)({
    backgroundColor: "#202020",
    borderRadius: "10px",
    padding: "8px",
    boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.3)",
    minHeight: "300px",
    overflowY: "auto",
});

// ✅ Define pipeline stages
const stages = ["New Leads", "Attempted Contact", "Engaged", "Closed"];

const DragDropBoard = ({ leads, setLeads, refreshLeads, employee }) => {
    // ✅ Drag-and-Drop Functionality
    const handleDragEnd = async (result) => {
        const { source, destination, draggableId } = result;

        if (!destination) {
            console.warn("❌ Dropped outside a valid drop target!");
            return;
        }

        console.log("📌 Moving", draggableId, "from", source.droppableId, "to", destination.droppableId);

        const updatedLeads = [...leads];
        const leadIndex = updatedLeads.findIndex((lead) => String(lead._id) === draggableId);

        if (leadIndex === -1) {
            console.error("❌ ERROR: Could not find lead with ID:", draggableId);
            return;
        }

        // ✅ Ensure `destination.droppableId` is valid
        const validStages = ["New Leads", "Attempted Contact", "Engaged", "Closed"];
        if (!validStages.includes(destination.droppableId)) {
            console.error("❌ ERROR: Invalid stage:", destination.droppableId);
            return;
        }

        // ✅ Create a NEW object instead of mutating existing state
        const movedLead = { ...updatedLeads[leadIndex], stage: destination.droppableId };

        // ✅ Create a new array with updated lead
        const newLeads = [...updatedLeads];
        newLeads[leadIndex] = movedLead;

        setLeads(newLeads); // ✅ Correctly update state

        try {
            const requestBody = { newStage: destination.droppableId };

            console.log("🚀 Sending API Request:", requestBody);

            const response = await axios.put(`${API_BASE_URL}/api/outreach/move/${draggableId}`, requestBody);
            console.log("✅ API Response:", response.data);
        } catch (error) {
            console.error("❌ ERROR updating backend:", error.response?.data || error.message);
        }
    };



    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <BoardContainer>
                {stages.map((stage) => (
                    <Droppable droppableId={stage} key={stage}>
                        {(provided) => (
                            <ColumnContainer ref={provided.innerRef} {...provided.droppableProps}>
                                <Typography variant="subtitle1" sx={{ color: "#12c099", textAlign: "center", fontWeight: "bold" }}>
                                    {stage}
                                </Typography>

                                {/* 🔹 Ensures space is reserved for empty Droppable */}
                                <div style={{ minHeight: "100px" }}>
                                    {leads
                                        .filter((lead) => lead.stage === stage)
                                        .map((lead, index) => (
                                            <Draggable key={String(lead._id)} draggableId={String(lead._id)} index={index}>
                                                {(provided, snapshot) => (
                                                    <Box
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        sx={{
                                                            opacity: snapshot.isDragging ? 0.8 : 1,
                                                        }}
                                                    >
                                                        <Card lead={lead} index={index} refreshLeads={refreshLeads} employee={employee} />
                                                    </Box>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </div>
                            </ColumnContainer>
                        )}
                    </Droppable>
                ))}
            </BoardContainer>
        </DragDropContext>
    );
};

export default DragDropBoard;
