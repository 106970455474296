import React, { useState } from "react";
import { Modal, Box, Typography, Button, TextField } from "@mui/material";
import axios from "axios";

const ConfirmDeleteModal = ({ open, onClose, sessionId, onDeleteSuccess }) => {
    const [confirmText, setConfirmText] = useState("");
    const [loading, setLoading] = useState(false);
    const [caseError, setCaseError] = useState(false); // Case-sensitive error tracking
    const correctText = "DELETE"; // Required confirmation text

    const handleDelete = async () => {
        if (confirmText !== correctText) return;

        setLoading(true);
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/sessions/${sessionId}`);
            onDeleteSuccess(sessionId); // ✅ Remove the deleted session from the list
            onClose(); // ✅ Close the modal after successful deletion
        } catch (error) {
            console.error("Error deleting session:", error);
        } finally {
            setLoading(false);
            onClose(); // ✅ Ensure the modal closes even if the delete fails
        }
    };


    const handleInputChange = (e) => {
        const input = e.target.value;
        setConfirmText(input);

        // Show warning if text is not in correct case
        setCaseError(input && input !== correctText);
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="delete-session-modal" disableEnforceFocus>
            <Box sx={{
                width: 400,
                bgcolor: "#202020", // Match dark theme
                color: "#fff",
                p: 3,
                borderRadius: 2,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            }}>
                <Typography variant="h6" gutterBottom sx={{ color: "#fff", fontWeight: "bold" }}>
                    Confirm Delete
                </Typography>

                {/* 🆕 Conditional explanation for deletion */}
                <Box sx={{
                    bgcolor: "#282828",
                    color: "#ffcc00",
                    p: 2,
                    borderRadius: 2,
                    mb: 2,
                    textAlign: "center",
                    fontSize: "0.9rem"
                }}>
                    ⚠️ <strong>No reservations found.</strong><br />
                    Since no attendees have booked this session, it's safe to delete.
                    You can proceed with deleting it below.
                </Box>

                <Typography variant="body2" sx={{ mb: 2, color: "#bbb" }}>
                    Type <strong style={{ color: "#12c099" }}>{correctText}</strong> to confirm session deletion.
                    <br />This action <strong>cannot</strong> be undone.
                </Typography>

                {/* Confirmation TextField */}
                <TextField
                    fullWidth
                    variant="outlined"
                    value={confirmText}
                    onChange={handleInputChange}
                    placeholder={`Type "${correctText}" to confirm`}
                    sx={{
                        bgcolor: "#282828",
                        color: "#fff",
                        mb: 2,
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#12c099" },
                            "&:hover fieldset": { borderColor: "#12c099" },
                            "&.Mui-focused fieldset": { borderColor: "#12c099" },
                        },
                        "& .MuiInputBase-input": {
                            color: "#fff",
                            fontWeight: "bold"
                        }
                    }}
                />

                {/* Case Sensitivity Error Display */}
                {caseError && (
                    <Box sx={{
                        bgcolor: "#e57373",
                        color: "#fff",
                        p: 1,
                        borderRadius: 1,
                        textAlign: "center",
                        mb: 2
                    }}>
                        ⚠️ Case-Sensitive Warning: Please type "DELETE" in all caps.
                    </Box>
                )}

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#12c099",
                            color: "#fff",
                            "&:hover": { backgroundColor: "#0fa888" },
                        }}
                        onClick={onClose}
                        disabled={loading}
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: "#e57373",
                            color: "#fff",
                            "&:hover": { backgroundColor: "#ef5350" },
                        }}
                        onClick={handleDelete}
                        disabled={confirmText !== correctText || loading}
                    >
                        {loading ? "Deleting..." : "Delete"}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmDeleteModal;
