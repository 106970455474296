import React, { useState } from "react";
import { Box, Paper, TextField, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";

const SectionTitle = styled(Typography)({
    color: "#12c099",
    fontWeight: "bold",
    marginBottom: "1rem",
});

const AthleteCredits = ({ firebaseUid, athlete, setAthlete }) => {
    const [creditAmount, setCreditAmount] = useState("");

    const handleAddCredits = async () => {
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BACKEND_URL}/api/update-user-balance/${firebaseUid}`,
                { creditsToAdd: parseFloat(creditAmount) }
            );
            setAthlete({ ...athlete, accountBalance: response.data.accountBalance });
            setCreditAmount("");
        } catch (error) {
            console.error("Error adding credits:", error);
        }
    };

    return (
        <Paper sx={{ padding: "1.5rem", backgroundColor: "#202020", color: "#fff", borderRadius: "8px", marginTop: "2rem" }}>
            <SectionTitle variant="h6">Add Credits to Account</SectionTitle>
            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center", flexWrap: "wrap" }}>
                <TextField
                    variant="outlined"
                    label="Credit Amount"
                    type="number"
                    value={creditAmount}
                    onChange={(e) => setCreditAmount(e.target.value)}
                    sx={{ backgroundColor: "#282828", color: "#fff", width: "200px" }}
                    InputLabelProps={{ style: { color: "#12c099" } }}
                    InputProps={{ style: { color: "#fff" } }}
                />
                <Button variant="contained" color="primary" onClick={handleAddCredits} sx={{ backgroundColor: "#12c099", color: "#fff" }}>
                    Add Credits
                </Button>
            </Box>
        </Paper>
    );
};

export default AthleteCredits;
