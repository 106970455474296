import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import axios from 'axios';

const GlobalQuotaComponent = ({ setGlobalQuotaForOtherComponents }) => {
    const [globalQuota, setGlobalQuota] = useState(0);

    useEffect(() => {
        const fetchTotalUsers = async () => {
            try {
                const response = await axios.get('/api/users/count');
                const totalUsers = response.data.totalUsers || 0;

                // ✅ FIX: Group users in 35s before applying the 4-session multiplier
                const userGroups = Math.ceil(totalUsers / 35);
                const calculatedQuota = userGroups * 4;

                setGlobalQuota(calculatedQuota);
                setGlobalQuotaForOtherComponents(calculatedQuota); // Pass to other components
            } catch (error) {
                console.error('Error fetching total users:', error);
            }
        };

        fetchTotalUsers();
    }, []);

    return (
        <Card
            sx={{
                backgroundColor: '#202020',
                color: '#fff',
                padding: '2rem',
                borderRadius: '12px',
                minHeight: '180px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CardContent sx={{ textAlign: 'center' }}>
                <Typography variant="h5" sx={{ marginBottom: '1rem', fontWeight: '500' }}>
                    Monthly Session Quota (Global)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '80px',
                    }}
                >
                    <Typography variant="h3" sx={{ color: '#12c099', fontWeight: 'bold' }}>
                        {globalQuota}
                    </Typography>
                </Box>
                <Typography variant="body2">Based on user signups</Typography>
            </CardContent>
        </Card>
    );
};

export default GlobalQuotaComponent;
