import React, { useState, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useParams } from 'react-router-dom';

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#202020',
    borderRadius: '12px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    overflowX: 'auto',
});

const CompactTableCell = styled(TableCell)({
    padding: '16px 20px',
    fontSize: '1rem',
    color: '#fff',
});

const FacilitiesRegions = () => {
    const { country } = useParams(); // Get the selected country from the URL
    const [regions, setRegions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRegions = async () => {
            try {
                console.log(`🔹 Fetching regions for ${country}...`);
                const response = await fetch(`https://api.admin.marsreel.com/api/venue-regions/${country}`, {
                    method: "GET",
                    headers: { "Cache-Control": "no-cache" },
                });

                if (!response.ok) {
                    throw new Error(`HTTP Error: ${response.status}`);
                }

                const data = await response.json();
                console.log("✅ Fetched Regions Data:", data);

                if (!Array.isArray(data) || data.length === 0) {
                    setError(`No regions found for ${country}.`);
                    setRegions([]);
                    return;
                }

                // ✅ Sort regions alphabetically for consistency
                const sortedRegions = data.sort((a, b) => a.name.localeCompare(b.name));
                setRegions(sortedRegions);
            } catch (error) {
                console.error(`❌ Error fetching regions for ${country}:`, error);
                setError("Failed to load regions. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchRegions();
    }, [country]);

    return (
        <Box sx={{ padding: '1rem', backgroundColor: '#1c1c1c', minHeight: '100vh', color: '#fff' }}>
            <Typography variant="h4" gutterBottom>{country} Facilities Directory</Typography>

            {/* Loading & Error Handling */}
            {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <CircularProgress sx={{ color: "#12c099" }} />
                </Box>
            )}

            {error && !loading && (
                <Typography sx={{ textAlign: "center", marginTop: "20px", color: "red" }}>
                    {error}
                </Typography>
            )}

            {/* Regions Table */}
            {!loading && !error && regions.length > 0 && (
                <StyledTableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <CompactTableCell>Region</CompactTableCell>
                                <CompactTableCell>Total Facilities</CompactTableCell> {/* ✅ New Column */}
                                <CompactTableCell>View Facilities</CompactTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {regions.map((region) => (
                                <TableRow key={region.name}>
                                    <CompactTableCell>{region.name}</CompactTableCell>
                                    <CompactTableCell>{region.venueCount || 0}</CompactTableCell>
                                    <CompactTableCell>
                                        <Button
                                            component={Link}
                                            to={`/facilities/${encodeURIComponent(country)}/${encodeURIComponent(region.name)}/city/`}
                                            sx={{ color: "#12c099", textTransform: "none" }}
                                        >
                                            View Cities
                                        </Button>
                                    </CompactTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            )}
        </Box>
    );
};

export default FacilitiesRegions;
