import React from 'react';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const RecentUpdates = ({ facilityId }) => {  // ✅ Only facilityId is passed now
    // ✅ Hardcoded Venue Notes
    const venueNotes = [
        { date: '2025-02-01', note: 'Rescheduled basketball league due to weather conditions.' },
        { date: '2025-01-25', note: 'Confirmed venue availability for upcoming soccer clinic.' },
        { date: '2025-01-10', note: 'Updated facility rental pricing structure.' },
    ];

    return (
        <Card
            sx={{
                backgroundColor: '#202020',
                color: '#fff',
                borderRadius: '12px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                marginBottom: '1rem',
            }}
        >
            <CardContent>
                <Typography variant="h6">Recent Updates</Typography>
                <TableContainer component={Paper} sx={{ backgroundColor: '#202020' }}>
                    <Table>
                        <TableBody>
                            {venueNotes.slice(0, 3).map((note, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{ color: '#ddd' }}>{note.date}</TableCell>
                                    <TableCell sx={{ color: '#ddd' }}>{note.note}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button component={Link} to={`/facilities/${facilityId}/updates`} sx={{ color: '#12c099', mt: 2 }}>
                    View All Updates
                </Button>
            </CardContent>
        </Card>
    );
};

export default RecentUpdates;
