import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography, InputAdornment, Card } from '@mui/material';
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox, Circle, InfoWindow } from '@react-google-maps/api';
import { Search as SearchIcon } from '@mui/icons-material';
import axios from 'axios';
import { OverlayView } from '@react-google-maps/api';

const NeighborhoodsComponent = ({ mapCenter, setMapCenter }) => {
    const [searchBox, setSearchBox] = useState(null);
    const [clusters, setClusters] = useState([]);
    const [selectedCluster, setSelectedCluster] = useState(null);
    const [cityNames, setCityNames] = useState({}); // Store city names mapped by zip code

    useEffect(() => {
        const fetchClusters = async () => {
            try {
                console.log('Fetching Clusters from:', `${process.env.REACT_APP_BACKEND_URL}/api/clusters`);
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/clusters`);

                // ✅ Filter out clusters with invalid lat/lng values
                const validClusters = response.data.filter(cluster =>
                    cluster.centroid && cluster.centroid.latitude && cluster.centroid.longitude && cluster.country
                );

                console.log('Fetched Clusters:', validClusters);
                setClusters(validClusters);
            } catch (error) {
                console.error('Error fetching clusters:', error);
            }
        };

        fetchClusters();
    }, []);

    // Function to fetch city name from Google Geocoding API
    const fetchCityName = async (zipCode) => {
        if (cityNames[zipCode]) return cityNames[zipCode]; // Return cached name if available

        try {
            const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
                params: { address: zipCode, key: apiKey }
            });

            if (response.data.results.length > 0) {
                const addressComponents = response.data.results[0].address_components;
                const cityComponent = addressComponents.find(component =>
                    component.types.includes('locality') || component.types.includes('political')
                );

                if (cityComponent) {
                    setCityNames(prev => ({ ...prev, [zipCode]: cityComponent.long_name }));
                    return cityComponent.long_name;
                }
            }
        } catch (error) {
            console.error('Error fetching city name:', error);
        }
        return zipCode; // Fallback to zip code if city name not found
    };

    // Function to handle cluster selection and fetch city name
    const handleClusterClick = async (cluster) => {
        if (cluster.zipCodes.length > 0) {
            const cityName = await fetchCityName(cluster.zipCodes[0]);
            setSelectedCluster({ ...cluster, cityName });
        } else {
            setSelectedCluster({ ...cluster, cityName: 'Unknown' });
        }
    };

    // Function to determine circle color based on affluence score
    const getCircleColor = (affluenceScore) => {
        const brightness = affluenceScore > 0 ? Math.min(255, 50 + affluenceScore * 2) : 50;
        return `rgb(${brightness}, ${brightness}, 255)`;
    };

    return (
        <Card
            sx={{
                backgroundColor: '#202020',
                color: '#fff',
                padding: '1rem',
                height: '600px',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Typography variant="h6" gutterBottom>Your Neighborhoods</Typography>
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY} libraries={['places']}>
                <StandaloneSearchBox onLoad={(ref) => setSearchBox(ref)} onPlacesChanged={() => {
                    const places = searchBox.getPlaces();
                    if (places.length > 0) {
                        setMapCenter({
                            lat: places[0].geometry.location.lat(),
                            lng: places[0].geometry.location.lng(),
                        });
                    }
                }}>
                    <TextField
                        variant="outlined"
                        placeholder="Search locations"
                        sx={{
                            width: '100%',
                            marginBottom: '1rem',
                            backgroundColor: '#fff',
                            color: '#000',
                            zIndex: 1,
                            position: 'relative',
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </StandaloneSearchBox>
                <Box sx={{ flexGrow: 1 }}>
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '100%' }}
                        center={mapCenter}
                        zoom={10}
                        options={{
                            tilt: 45,
                            heading: 90,
                            zoomControl: true,
                            streetViewControl: false,
                            fullscreenControl: false,
                            disableDefaultUI: true,
                        }}
                    >
                        {clusters.map((cluster) => (
                            cluster.centroid && cluster.centroid.latitude && cluster.centroid.longitude ? (
                                <Circle
                                    key={cluster._id}
                                    center={{
                                        lat: cluster.centroid.latitude,
                                        lng: cluster.centroid.longitude
                                    }}
                                    radius={cluster.leadCount * 400}
                                    options={{
                                        strokeColor: getCircleColor(cluster.affluenceScore),
                                        strokeOpacity: 0.8,
                                        strokeWeight: 2,
                                        fillColor: getCircleColor(cluster.affluenceScore),
                                        fillOpacity: 0.35,
                                    }}
                                    onClick={() => handleClusterClick(cluster)}
                                />
                            ) : null
                        ))}
                        {selectedCluster && (
                            <OverlayView
                                position={{
                                    lat: selectedCluster.centroid.latitude,
                                    lng: selectedCluster.centroid.longitude,
                                }}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} // Ensures it's clickable
                            >
                                <div style={{
                                    background: 'rgba(25, 25, 25, 0.98)', // Darker, richer contrast
                                    padding: '18px',
                                    borderRadius: '14px',
                                    boxShadow: '0 6px 16px rgba(0, 0, 0, 0.6)', // More depth
                                    color: '#fff',
                                    width: '260px', // Wider layout
                                    textAlign: 'left',
                                    position: 'relative', // Keeps button in place
                                    fontSize: '16px', // Increased text size
                                    fontWeight: '400',
                                    lineHeight: '1.6', // Better spacing
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '12px',
                                }}>
                                    {/* Close Button - More Visible */}
                                    <button
                                        onClick={() => setSelectedCluster(null)}
                                        style={{
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px',
                                            border: 'none',
                                            background: 'rgba(255, 255, 255, 0.9)', // Bright for contrast
                                            color: '#222', // Darker "X" for readability
                                            borderRadius: '50%',
                                            width: '24px',
                                            height: '24px',
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            transition: '0.2s ease-in-out'
                                        }}
                                    >
                                        ✕
                                    </button>

                                    {/* City Name - Large and Clear */}
                                    <h3 style={{
                                        fontSize: '20px',
                                        fontWeight: 'bold',
                                        marginBottom: '6px',
                                        textTransform: 'capitalize',
                                        letterSpacing: '0.5px',
                                        color: '#ffffff',
                                    }}>
                                        {selectedCluster.cityName || selectedCluster.name}
                                    </h3>

                                    {/* Key Information - Simplified Layout */}
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '6px',
                                        fontSize: '17px',
                                    }}>
                                        <div><strong>Leads:</strong> {selectedCluster.leadCount}</div>
                                        <div><strong>Local Affluence Score:</strong> {selectedCluster.affluenceScore}</div>
                                        <div><strong>Global Affluence Score:</strong> {selectedCluster.globalAffluenceScore}</div>
                                    </div>
                                </div>
                            </OverlayView>
                        )}

                        <Marker position={mapCenter} />
                    </GoogleMap>
                </Box>
            </LoadScript>
        </Card>
    );
};

export default NeighborhoodsComponent;
