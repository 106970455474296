import React, { useState, useEffect } from "react";
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Typography, CircularProgress, IconButton
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDeleteModal from "../components/partner-list/ConfirmDeleteModal";

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: "#202020",
    borderRadius: "12px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
    overflowX: "auto",
});

const CompactTableCell = styled(TableCell)({
    padding: "16px 20px",
    fontSize: "1rem",
    color: "#fff",
});

const PartnersPage = () => {
    const [partners, setPartners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState(null);

    useEffect(() => {
        const fetchPartners = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/all-marketing-partners`);
                setPartners(response.data);
            } catch (error) {
                console.error("Error fetching partners:", error);
                setError("Failed to load partners. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchPartners();
    }, []);

    // Open the delete confirmation modal
    const handleOpenDeleteDialog = (partner) => {
        setSelectedPartner(partner);
        setDeleteDialogOpen(true);
    };

    // Close the delete confirmation modal
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setSelectedPartner(null);
    };

    // Remove the deleted partner from UI
    const handleDeleteSuccess = (deletedId) => {
        setPartners(partners.filter(p => p._id !== deletedId));
    };

    return (
        <Box sx={{ padding: "1rem", backgroundColor: "#1c1c1c", minHeight: "100vh", color: "#fff" }}>
            <Typography variant="h4" gutterBottom>Marketing Partner Directory</Typography>

            {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <CircularProgress sx={{ color: "#12c099" }} />
                </Box>
            )}

            {error && !loading && (
                <Typography sx={{ textAlign: "center", marginTop: "20px", color: "red" }}>
                    {error}
                </Typography>
            )}

            {!loading && (
                <StyledTableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <CompactTableCell>Partner Name</CompactTableCell>
                                <CompactTableCell>Revenue</CompactTableCell>
                                <CompactTableCell>Actions</CompactTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {partners.length > 0 ? (
                                partners.map((partner) => (
                                    <TableRow key={partner._id}>
                                        <CompactTableCell>
                                            <Link to={`/partner/${encodeURIComponent(partner._id)}`} style={{ textDecoration: "none", color: "#12c099", fontWeight: "bold" }}>
                                                {partner.name}
                                            </Link>
                                        </CompactTableCell>
                                        <CompactTableCell>{partner.revenue}</CompactTableCell>
                                        <CompactTableCell>
                                            <IconButton
                                                onClick={() => handleOpenDeleteDialog(partner)}
                                                sx={{ color: "red" }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </CompactTableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3} align="center" sx={{ color: "#fff" }}>
                                        No partners available.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            )}

            {/* Importable Delete Confirmation Modal */}
            {selectedPartner && (
                <ConfirmDeleteModal
                    open={deleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    partner={selectedPartner}
                    onDeleteSuccess={handleDeleteSuccess}
                />
            )}
        </Box>
    );
};

export default PartnersPage;
