import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    Box, Typography, Grid, CircularProgress
} from "@mui/material";
import { styled } from "@mui/material/styles";
import QRCode from "../components/partner/QRCode";
import TotalScans from "../components/partner/TotalScans";
import UniqueScans from "../components/partner/UniqueScans";
import FirstScanDate from "../components/partner/FirstScanDate";
import Region from "../components/partner/Region";
import RateCard from "../components/partner/RateCard";
import Comments from "../components/partner/Comments";
import PartnerName from "../components/partner/PartnerName";
import Campaigns from "../components/partner/Campaigns";
import ScanDensityMap from "../components/partner/ScanDensityMap";

import axios from "axios";

const Root = styled(Box)({
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: "#1c1c1c",
    color: "#fff",
    padding: "2rem",
});

const ContentWrapper = styled(Box)({
    flex: 1,
});

const StyledCard = styled(Box)({
    backgroundColor: "#202020",
    padding: "1.5rem",
    borderRadius: "12px",
    color: "#fff",
});

const Footer = styled(Box)({
    textAlign: "center",
    padding: "1rem",
    marginTop: "auto",
    backgroundColor: "#1c1c1c",
    color: "#fff",
});

const Partner = () => {
    const { id } = useParams();
    const [partner, setPartner] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPartner = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/get-marketing-partner/${id}`);
                setPartner(response.data);
            } catch (error) {
                console.error("Error fetching partner details:", error);
                setError("Failed to load partner data.");
            } finally {
                setLoading(false);
            }
        };

        fetchPartner();
    }, [id]);

    if (loading) {
        return <Typography align="center"><CircularProgress sx={{ color: "#12c099" }} /></Typography>;
    }

    if (error) {
        return <Typography align="center" color="red">{error}</Typography>;
    }

    return (
        <Root>
            <ContentWrapper>
                <PartnerName name={partner.name} />

                <Grid container spacing={4}>
                    {/* Partner Info */}
                    <Grid item xs={12}>
                        <StyledCard>
                            <Typography variant="h5" sx={{ color: "#12c099" }}>Partner Type:</Typography>
                            <Typography variant="h6" sx={{ mb: 2 }}>{partner.partnerType}</Typography>

                            <Typography variant="h5" sx={{ color: "#12c099" }}>Primary Contact:</Typography>
                            <Typography variant="h6">{partner.primaryContact}</Typography>
                            <Typography variant="body1">{partner.primaryContactEmail}</Typography>
                            <Typography variant="body1">{partner.primaryContactPhoneNumber}</Typography>
                        </StyledCard>
                    </Grid>

                    <Grid item xs={12}>
                        <ScanDensityMap totalScans={partner.totalScans} />
                    </Grid>

                    {/* QR Code */}
                    <Grid item xs={12} md={4}>
                        <QRCode qrCodes={partner.qrCodes} />
                    </Grid>

                    {/* Scans Overview */}
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TotalScans totalScans={partner.totalScans} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <UniqueScans uniqueScans={partner.uniqueScans} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FirstScanDate firstScanDates={partner.firstScanDates} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Region scanRegions={partner.scanRegions} />
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Rate Card */}
                    <Grid item xs={12}>
                        <RateCard rateCard={partner.rateCard} />
                    </Grid>

                    <Grid item xs={12}>
                        <Campaigns campaigns={partner.campaigns} />
                    </Grid>


                    {/* Comments */}
                    <Grid item xs={12}>
                        <Comments comments={partner.comments} />
                    </Grid>
                </Grid>
            </ContentWrapper>

            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp.
                </Typography>
            </Footer>
        </Root>
    );
};

export default Partner;
