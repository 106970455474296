import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import axios from "axios";
import AthleteProfile from "../components/athlete/AthleteProfile";
import AthleteCredits from "../components/athlete/AthleteCredits";
import AthletePlan from "../components/athlete/AthletePlan";
import AthleteReservations from "../components/athlete/AthleteReservations";
import AthleteCheckins from "../components/athlete/AthleteCheckins"; // ✅ Import AthleteCheckins Component
import Footer from "../components/Footer/Footer";

const OverviewContainer = styled(Box)({
    padding: "2rem",
    backgroundColor: "#1c1c1c",
    color: "#fff",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
});

const Athlete = () => {
    const { firebaseUid } = useParams();
    const [athlete, setAthlete] = useState(null);
    const [reservations, setReservations] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/user/profile/${firebaseUid}`)
            .then((response) => {
                setAthlete(response.data.user);
                setReservations(response.data.reservations);
            })
            .finally(() => setLoading(false));
    }, [firebaseUid]);

    if (loading || !athlete) return <Typography>Loading...</Typography>;

    return (
        <OverviewContainer>
            <Typography variant="h4">Athlete Overview</Typography>

            {/* ✅ Athlete Profile */}
            <AthleteProfile athlete={athlete} />

            {/* ✅ Athlete Credits */}
            <AthleteCredits firebaseUid={firebaseUid} athlete={athlete} setAthlete={setAthlete} />

            {/* ✅ Athlete Plan */}
            <AthletePlan planDetails={athlete.planDetails} />

            {/* ✅ Athlete Reservations */}
            <AthleteReservations reservations={reservations} />

            {/* ✅ Athlete Check-ins */}
            <AthleteCheckins firebaseUid={firebaseUid} />

            <Footer />
        </OverviewContainer>
    );
};

export default Athlete;
