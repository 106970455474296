import React from 'react';
import { Card, Typography, Button, Box } from '@mui/material';

const AssignedNeighborhoodsComponent = ({ neighborhoods, userLocation, handleNeighborhoodClick }) => {
    const cityName = userLocation?.city || 'your city';

    return (
        <Card sx={{ backgroundColor: '#202020', color: '#fff', padding: '1rem' }}>
            <Typography variant="h6">Assigned Neighborhoods in {cityName}</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {neighborhoods.length ? (
                    neighborhoods.map((neighborhood, index) => (
                        <Button
                            key={index}
                            variant="outlined"
                            sx={{ borderColor: '#12c099', color: '#12c099' }}
                            onClick={() => handleNeighborhoodClick(neighborhood)}
                        >
                            {neighborhood}
                        </Button>
                    ))
                ) : (
                    <Typography>No assigned neighborhoods</Typography>
                )}
            </Box>
        </Card>
    );
};

export default AssignedNeighborhoodsComponent;
