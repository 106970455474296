import React from "react";
import { Avatar, Box, Typography, Paper, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

const ProfileCard = styled(Paper)({
    padding: "1.5rem",
    backgroundColor: "#202020",
    color: "#fff",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    gap: "1.5rem",
});

const InfoContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
});

const CoachProfile = ({ coach }) => {
    return (
        <ProfileCard>
            <Avatar src={coach.avatarImageUrl} sx={{ width: 120, height: 120 }} />
            <InfoContainer>
                <Typography variant="h5">{coach.name}</Typography>
                <Divider sx={{ backgroundColor: "#fff", margin: "0.5rem 0" }} />
                <Typography variant="body1"><strong>Email:</strong> {coach.email}</Typography>
                <Typography variant="body1"><strong>Phone:</strong> {coach.phone}</Typography>
                <Typography variant="body1"><strong>Location:</strong> {`${coach.city}, ${coach.state}`}</Typography>
                <Typography variant="body1"><strong>Sports:</strong> {coach.sports.join(", ")}</Typography>
                <Typography variant="body1"><strong>Rating:</strong> {coach.rating}</Typography>
                <Typography variant="body1"><strong>Attendance Rate:</strong> {coach.attendanceRate}%</Typography>
            </InfoContainer>
        </ProfileCard>
    );
};

export default CoachProfile;
