import { IconButton, Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import axios from 'axios';

const InviteButton = ({ email, zipCode }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleInvite = async () => {
        console.log('Payload for API call:', { email, zipCode }); // Confirm the payload
        try {
            await axios.post('/api/invite', { email, zipCode });
            alert('Invitation sent successfully!');
            handleClose();
        } catch (error) {
            console.error('Error inviting user:', error);
            alert('Failed to send invitation. Please try again.');
        }
    };

    return (
        <>
            <IconButton onClick={handleOpen} color="primary">
                <AddIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Invite User to Create an Account?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleInvite} color="secondary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InviteButton;
