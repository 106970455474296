import React, { useState, useEffect } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Button,
    CircularProgress
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: "#202020",
    borderRadius: "12px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
    overflowX: "auto",
});

const CompactTableCell = styled(TableCell)({
    padding: "16px 20px",
    fontSize: "1rem",
    color: "#fff",
});

const Facilities = () => {
    const { country, region, city } = useParams(); // Get selected city from URL
    const [facilities, setFacilities] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFacilities = async () => {
            try {
                console.log(`🔹 Fetching facilities in ${city}, ${region}, ${country}...`);
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/facilities/${country}/${region}/${city}`
                );

                if (!response.data || response.data.length === 0) {
                    setError(`No facilities found in ${city}.`);
                    setFacilities([]);
                    return;
                }

                setFacilities(response.data);
                fetchNeighborhoods(response.data);
            } catch (error) {
                console.error(`❌ Error fetching facilities:`, error);
                setError("Failed to load facilities. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchFacilities();
    }, [country, region, city]);

    const fetchNeighborhoods = async (facilitiesList) => {
        const newNeighborhoods = {};

        for (const facility of facilitiesList) {
            const { latitude, longitude } = facility.location;

            try {
                // Fetch neighborhood from backend API
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/neighborhood/${latitude}/${longitude}`
                );

                if (response.data && response.data.neighborhood) {
                    newNeighborhoods[facility._id] = response.data.neighborhood;
                } else {
                    newNeighborhoods[facility._id] = "Unknown";
                }
            } catch (error) {
                console.error(`❌ Error fetching neighborhood for ${facility.name}:`, error);
                newNeighborhoods[facility._id] = "Unknown";
            }
        }

        setNeighborhoods(newNeighborhoods);
    };

    return (
        <Box sx={{ padding: "1rem", backgroundColor: "#1c1c1c", minHeight: "100vh", color: "#fff" }}>
            <Typography variant="h4" gutterBottom>
                Facilities in {city}, {region}
            </Typography>

            {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <CircularProgress sx={{ color: "#12c099" }} />
                </Box>
            )}

            {error && !loading && (
                <Typography sx={{ textAlign: "center", marginTop: "20px", color: "red" }}>
                    {error}
                </Typography>
            )}

            {!loading && !error && facilities.length > 0 && (
                <StyledTableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <CompactTableCell>Facility Name</CompactTableCell>
                                <CompactTableCell>Neighborhood</CompactTableCell> {/* ✅ Uses Backend Route */}
                                <CompactTableCell>Changelog</CompactTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {facilities.map((facility) => (
                                <TableRow key={facility._id}>
                                    {/* ✅ Facility Name now links to the Facility Details Page */}
                                    <CompactTableCell>
                                        <Button
                                            component={Link}
                                            to={`/facilities/${country}/${region}/${city}/${facility._id}`}
                                            sx={{ color: "#12c099", textTransform: "none" }}
                                        >
                                            {facility.name}
                                        </Button>
                                    </CompactTableCell>

                                    {/* ✅ Fetch and Display Neighborhood from Backend API */}
                                    <CompactTableCell>{neighborhoods[facility._id] || "Loading..."}</CompactTableCell>

                                    {/* ✅ Changelog Link */}
                                    <CompactTableCell>
                                        <Button
                                            component={Link}
                                            to={`/facilities/${facility._id}/changelog`}
                                            sx={{ color: "#12c099", textTransform: "none" }}
                                        >
                                            View Changelog
                                        </Button>
                                    </CompactTableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            )}
        </Box>
    );
};

export default Facilities;
