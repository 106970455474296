import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, Button, TextField } from "@mui/material";
import axios from "axios";

const CancellationPreviewModal = ({ open, onClose, sessionId, initialPreviewData, onConfirmCancel, cancellationReason, createdBy }) => {
    const [previewData, setPreviewData] = useState(initialPreviewData ?? { smsPreview: "Loading...", emailPreview: "Loading..." });
    const [customSmsMessage, setCustomSmsMessage] = useState("");
    const [customEmailMessage, setCustomEmailMessage] = useState("");
    const [usingCustom, setUsingCustom] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (initialPreviewData) {
            setPreviewData(initialPreviewData);
        }
    }, [initialPreviewData]);

    const handleApprove = async () => {
        setLoading(true);
        try {
            // Directly use createdBy, ensuring it's valid
            const firebaseUid = createdBy;

            if (!firebaseUid) {
                console.error("Error: No valid Employee UID found! Aborting cancel operation.");
                return;
            }

            console.log("Sending to Backend - Employee UID:", firebaseUid);

            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/session/${sessionId}/cancel`, {
                smsMessage: usingCustom ? customSmsMessage : previewData?.smsPreview,
                emailMessage: usingCustom ? customEmailMessage : previewData?.emailPreview,
                reason: cancellationReason ?? "No specific reason provided.",
                employeeUid: firebaseUid, // Use the correct UID for the backend
            });

            onConfirmCancel();
            onClose();

        } catch (error) {
            console.error("Error confirming cancellation:", error?.response?.data || error.message);
        } finally {
            setLoading(false);
        }
    };





    const handleRetry = async () => {
        setLoading(true);
        setPreviewData({
            smsPreview: "Loading...",
            emailPreview: "Loading...",
            createdBy: previewData?.createdBy // Keep existing createdBy
        });

        // ✅ Add the console.log here
        console.log("CreatedBy before retry:", previewData?.createdBy);

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/session/${sessionId}/cancel-preview`,
                { params: { reason: cancellationReason } }
            );

            console.log("Retry API Response:", response.data);

            if (response.data?.smsPreview && response.data?.emailPreview) {
                setPreviewData({
                    smsPreview: response.data.smsPreview,
                    emailPreview: response.data.emailPreview,
                    cancellationReason,
                    createdBy: previewData?.createdBy // Preserve createdBy
                });
            } else {
                setPreviewData({
                    smsPreview: "Failed to load SMS preview.",
                    emailPreview: "Failed to load email preview.",
                    createdBy: previewData?.createdBy // Preserve createdBy
                });
            }
        } catch (error) {
            console.error("Error fetching new cancellation preview:", error);
            setPreviewData({
                smsPreview: "Error fetching SMS preview.",
                emailPreview: "Error fetching email preview.",
                createdBy: previewData?.createdBy // Preserve createdBy
            });
        } finally {
            setLoading(false);
        }
    };


    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    width: 500,
                    bgcolor: "#202020",
                    p: 3,
                    borderRadius: 2,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                <Typography variant="h6" sx={{ fontWeight: "bold", color: "#fff" }}>
                    Cancellation Preview
                </Typography>
                <Typography variant="body2" sx={{ color: "#ccc", mb: 2 }}>
                    Here is a preview of what will be sent to attendees.
                </Typography>

                {/* Display AI-generated or Custom Messages */}
                {/* Display AI-generated or Custom Messages only when not using custom inputs */}
                {!usingCustom && (
                    <>
                        <Typography variant="subtitle1" sx={{ color: "#12c099", fontWeight: "bold", mb: 1 }}>
                            SMS Preview:
                        </Typography>
                        <Box
                            sx={{
                                backgroundColor: "#282828",
                                p: 2,
                                borderRadius: 1,
                                mb: 3,
                                whiteSpace: "pre-line",
                            }}
                        >
                            <Typography variant="body2" sx={{ color: "#fff" }}>
                                {previewData?.smsPreview || "Loading..."}
                            </Typography>
                        </Box>

                        <Typography variant="subtitle1" sx={{ color: "#12c099", fontWeight: "bold", mb: 1 }}>
                            Email Preview:
                        </Typography>
                        <Box
                            sx={{
                                backgroundColor: "#282828",
                                p: 2,
                                borderRadius: 1,
                                whiteSpace: "pre-line",
                            }}
                        >
                            <Typography variant="body2" sx={{ color: "#fff", whiteSpace: "pre-line" }}>
                                {previewData?.emailPreview?.split('\n').slice(1).join('\n') || "Loading..."}
                            </Typography>
                        </Box>
                    </>
                )}


                {/* Custom message input for SMS and Email */}
                {usingCustom && (
                    <>
                        <Typography variant="subtitle1" sx={{ color: "#12c099", fontWeight: "bold", mt: 2 }}>
                            Custom SMS Message:
                        </Typography>
                        <TextField
                            fullWidth
                            multiline
                            rows={3}
                            variant="outlined"
                            placeholder="Write your own SMS message..."
                            value={customSmsMessage}
                            onChange={(e) => setCustomSmsMessage(e.target.value)}
                            sx={{
                                bgcolor: "#282828",
                                mt: 1,
                                "& .MuiInputBase-input": { color: "#fff" },
                                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#12c099" },
                            }}
                            InputProps={{
                                style: { color: "#fff" },
                            }}
                        />

                        <Typography variant="subtitle1" sx={{ color: "#12c099", fontWeight: "bold", mt: 2 }}>
                            Custom Email Message:
                        </Typography>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            placeholder="Write your own Email message..."
                            value={customEmailMessage}
                            onChange={(e) => setCustomEmailMessage(e.target.value)}
                            sx={{
                                bgcolor: "#282828",
                                mt: 1,
                                "& .MuiInputBase-input": { color: "#fff" },
                                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#12c099" },
                            }}
                            InputProps={{
                                style: { color: "#fff" },
                            }}
                        />
                    </>
                )}

                {/* Action Buttons */}
                {/* Action Buttons */}
                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
                    <Button
                        variant="contained"
                        sx={{ bgcolor: "#12c099" }}
                        onClick={handleRetry}
                        disabled={loading}
                    >
                        {loading ? "Retry" : "Retry"}
                    </Button>

                    <Button
                        variant="contained"
                        sx={{ bgcolor: "#ffcc00" }}
                        onClick={() => {
                            setUsingCustom(!usingCustom);
                            if (!usingCustom) {
                                setCustomSmsMessage("");
                                setCustomEmailMessage("");
                            }
                        }}
                        disabled={loading}
                    >
                        {loading ? "Write My Own" : "Write My Own"}
                    </Button>

                    <Button
                        variant="contained"
                        sx={{ bgcolor: "#e57373" }}
                        onClick={onClose}
                        disabled={loading}
                    >
                        {loading ? "Cancel" : "Cancel"}
                    </Button>

                    <Button
                        variant="contained"
                        sx={{ bgcolor: "#0fa888" }}
                        onClick={handleApprove}
                        disabled={loading}
                    >
                        {loading ? "Processing..." : "Approve"}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CancellationPreviewModal;
