import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Paper, CircularProgress, Card, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom"; // Import Link for navigation
import axios from "axios";
import PersonIcon from "@mui/icons-material/Person";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

const WidePaper = styled(Paper)({
    padding: "1rem",
    backgroundColor: "#202020",
    color: "#fff",
    borderRadius: "12px",
    textAlign: "center",
    minHeight: "350px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    flexGrow: 1,
});

const VideoCard = styled(Card)({
    position: "relative",
    borderRadius: "20px",
    overflow: "hidden",
    backgroundColor: "#121212",
    width: "100%",
    maxWidth: "280px",
    aspectRatio: "9 / 16",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
});

const VideoContainer = styled(Box)({
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
});

const VideoElement = styled("video")({
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "20px",
    zIndex: 1,
});

const InfoButton = styled(IconButton)({
    position: "absolute",
    top: "10px",
    right: "10px",
    zIndex: 2,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
});

const VideoInfoOverlay = styled(Box)({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    padding: "20px",
    backgroundColor: "rgba(0, 0, 0, 0.85)",
    color: "#fff",
    zIndex: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
});

const VideoTitle = styled(Typography)({
    fontSize: "1.2rem",
    fontWeight: "600",
    color: "#12c099",
    marginBottom: "10px",
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "underline",
});

const IconText = styled(Typography)({
    display: "flex",
    alignItems: "center",
    gap: "6px",
    fontSize: "1rem",
    color: "#ddd",
    marginBottom: "8px",
    "& svg": {
        fontSize: "1.2rem",
        color: "#12c099",
    },
    "& a": {
        color: "#12c099",
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
});

const FeedComponent = () => {
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showInfo, setShowInfo] = useState(null); // To track which video's info to show

    useEffect(() => {
        const fetchLatestVideos = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/videos?page=1&limit=3`);
                if (response.data && Array.isArray(response.data.videos)) {
                    setVideos(response.data.videos);
                } else {
                    console.error("Unexpected API response:", response.data);
                    setVideos([]);
                }
            } catch (err) {
                console.error("Error fetching videos:", err);
                setError("Failed to load videos");
            } finally {
                setLoading(false);
            }
        };

        fetchLatestVideos();
    }, []);

    return (
        <WidePaper>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: "500" }}>
                Active Sessions
            </Typography>

            {loading ? (
                <CircularProgress sx={{ color: "#12c099" }} />
            ) : error ? (
                <Typography variant="body2" color="error">
                    {error}
                </Typography>
            ) : (
                <Grid container spacing={2} sx={{ marginTop: "1rem", justifyContent: "center" }}>
                    {videos.map((video) => (
                        <Grid
                            item xs={12} sm={6} md={4}
                            key={video._id}
                            sx={{ display: "flex", justifyContent: "center" }}
                        >
                            <VideoCard>
                                <VideoContainer>
                                    <VideoElement
                                        src={video.videoUrl}
                                        controls
                                        onError={() => console.error(`Error loading video: ${video.videoUrl}`)}
                                    />
                                    <InfoButton onClick={() => setShowInfo(video._id)}>
                                        <InfoIcon />
                                    </InfoButton>

                                    {showInfo === video._id && (
                                        <VideoInfoOverlay>
                                            <IconButton
                                                sx={{ position: "absolute", top: "10px", right: "10px", color: "#fff" }}
                                                onClick={() => setShowInfo(null)}
                                            >
                                                <CloseIcon />
                                            </IconButton>

                                            <Link
                                                to={`/session/${video.session?._id}`}
                                                style={{ textDecoration: "none" }}
                                            >
                                                <VideoTitle>
                                                    {video.session?.name || "No Session Name"}
                                                </VideoTitle>
                                            </Link>

                                            <IconText>
                                                <PersonIcon />
                                                <Link
                                                    to={`/site-manager/${video.uploadedBy?.firebaseUid}`}
                                                    style={{ color: "#12c099", textDecoration: "none" }}
                                                >
                                                    {video.uploadedBy?.name || "Unknown"}
                                                </Link>
                                            </IconText>

                                            <IconText>
                                                <CalendarTodayIcon />
                                                {new Date(video.createdAt).toLocaleDateString()}
                                            </IconText>
                                        </VideoInfoOverlay>
                                    )}
                                </VideoContainer>
                            </VideoCard>
                        </Grid>
                    ))}
                </Grid>
            )}
        </WidePaper>
    );
};

export default FeedComponent;
