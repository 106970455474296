import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const WeatherForecast = () => {
    const facility = {
        weather: 'Sunny, 75°F',
    };

    return (
        <Card
            sx={{
                backgroundColor: '#202020',
                color: '#fff',
                borderRadius: '12px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                padding: '1.5rem',
                flex: 1, // Keeps it responsive within a flex container
                marginBottom: '1rem', // Ensure spacing below
            }}
        >
            <CardContent>
                <Typography variant="h6">Nearby Weather</Typography>
                <Typography variant="h5">{facility.weather}</Typography>
            </CardContent>
        </Card>
    );
};

export default WeatherForecast;
