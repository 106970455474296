import React, { useState } from "react";
import { Card, CardContent, Typography, TextField, Button, List, ListItem, ListItemText } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const Comments = ({ comments = [] }) => {
    const [newComment, setNewComment] = useState("");
    const [allComments, setAllComments] = useState(comments);

    const addComment = () => {
        if (newComment.trim()) {
            setAllComments([{ user: "Admin", comment: newComment, timestamp: new Date().toISOString() }, ...allComments]);
            setNewComment("");
        }
    };

    return (
        <Card sx={{ backgroundColor: "#202020", padding: "1.5rem", borderRadius: "12px" }}>
            <CardContent>
                <Typography variant="h5" sx={{ color: "#12c099" }}>Comments</Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Write a comment..."
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    sx={{ mt: 2, backgroundColor: "#333", color: "#fff" }}
                />
                <Button onClick={addComment} sx={{ mt: 2, color: "#12c099" }} endIcon={<SendIcon />}>
                    Add Comment
                </Button>
                <List>
                    {allComments.map((comment, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={comment.comment}
                                secondary={new Date(comment.timestamp).toLocaleString()}
                                sx={{ color: "#fff" }}
                            />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default Comments;
