import React from 'react';
import { Paper, List, ListItem, ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)({
    backgroundColor: '#282828',
    padding: '1rem',
    marginBottom: '2rem',
});

const SectionTitle = styled(Typography)({
    marginBottom: '1rem',
    color: '#12c099',
    fontWeight: 'bold',
    fontSize: '1.5rem',
});

const StyledListItemText = styled(ListItemText)({
    '& .MuiTypography-root': {
        color: '#fff',
        fontSize: '1rem',
        fontWeight: 'bold',
    }
});

const Reservations = ({ reservations }) => {
    console.log(`🎟️ Reservations Data Received:`, reservations);

    return (
        <>
            <SectionTitle variant="h4">Reservations</SectionTitle>
            <StyledPaper>
                <List>
                    {reservations.map((reservation, index) => (
                        <ListItem key={index} sx={{ borderBottom: '1px solid #404040' }}>
                            <StyledListItemText
                                primary={
                                    <a href={`https://admin.marsreel.com/${reservation.type}/${reservation.firebaseUid}`}
                                       style={{ color: '#12c099', textDecoration: 'none' }}>
                                        {reservation.name} ({reservation.type})  {/* ✅ Full Name Here */}
                                    </a>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </StyledPaper>
        </>
    );
};


export default Reservations;
