import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import axios from 'axios';

const QuotasComponent = ({ quotaType, address = {}, sport = 'Unknown Sport', employeeId }) => {
    const [globalQuota, setGlobalQuota] = useState(0);
    const [regionUserCount, setRegionUserCount] = useState(0);
    const [regionQuota, setRegionQuota] = useState(0);
    const [employeeQuota, setEmployeeQuota] = useState(0);
    const [resolvedCounty, setResolvedCounty] = useState(null);
    const maxSessionsPerEmployee = 160;

    const { city = 'Unknown City', state = 'Unknown State', country = 'Unknown Country', county = 'Unknown County' } = address;

    useEffect(() => {
        if (!state || !country || !sport || state === 'Unknown State' || country === 'Unknown Country') {
            console.warn('Skipping API fetch due to missing or invalid location or sport data');
            return;
        }

        const fetchQuotaData = async () => {
            try {
                // ✅ Fetch Global User Count
                const userResponse = await axios.get('/api/users/count');
                const totalUsers = userResponse.data.totalUsers || 0;
                const userGroups = Math.ceil(totalUsers / 35); // ✅ Fix: Grouping into batches of 35
                const calculatedGlobalQuota = userGroups * 4;
                setGlobalQuota(calculatedGlobalQuota);

                let currentCounty = county;

                // Resolve county dynamically if missing
                if (!county || county === 'Unknown County') {
                    try {
                        const countyResponse = await axios.get(`/api/users/resolve-county?city=${city}&state=${state}`);
                        currentCounty = countyResponse.data.county || '';
                        setResolvedCounty(currentCounty);
                    } catch (error) {
                        console.error("❌ Error resolving county:", error);
                    }
                } else {
                    setResolvedCounty(county);
                }

                // ✅ Fetch Region User Count
                const regionUserResponse = await axios.get(
                    `/api/users/count/filtered?county=${currentCounty}&state=${state}&country=${country}&sport=${sport}`
                );
                const regionUsers = regionUserResponse.data.regionUsers || 0;
                setRegionUserCount(regionUsers);

                // ✅ Fix: Grouping users in 35s before calculating quota
                const regionGroups = Math.ceil(regionUsers / 35);
                let regionQuotaCalc = regionGroups * 4; // ✅ Fix: Correct formula

                switch (quotaType) {
                    case 'weekly':
                        regionQuotaCalc /= 4;
                        break;
                    case 'daily':
                        regionQuotaCalc /= 30;
                        break;
                    default:
                        break;
                }
                setRegionQuota(regionQuotaCalc);

                // ✅ Calculate Employee Quotas
                const totalRegionQuota = regionQuotaCalc ? Math.min(regionQuotaCalc, maxSessionsPerEmployee) : 0;
                setEmployeeQuota(totalRegionQuota);

            } catch (error) {
                console.error(`Error fetching ${quotaType} quota or user count:`, error);
            }
        };

        fetchQuotaData();
    }, [quotaType, county, resolvedCounty, state, country, sport, city]);

    return (
        <Card sx={{ backgroundColor: '#202020', color: '#fff', padding: '2rem', borderRadius: '12px', minHeight: '180px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CardContent>
                <Typography variant="h6">
                    {quotaType.charAt(0).toUpperCase() + quotaType.slice(1)} Session Quota
                </Typography>
                <Typography variant="h4" sx={{ color: '#12c099', fontWeight: 'bold' }}>
                    {employeeQuota ? employeeQuota.toFixed(2) : 0}
                </Typography>
                <Typography variant="body2">
                    Based on {regionUserCount} users in {resolvedCounty || county}, {state} for {sport || 'Unknown Sport'}.
                </Typography>
                <Typography variant="body2">
                    Global Quota: {globalQuota ? globalQuota.toFixed(2) : 0} | Region Quota: {isNaN(regionQuota) ? 0 : regionQuota.toFixed(2)}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default QuotasComponent;
