import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const UpcomingSessions = () => {
    // ✅ Hardcoded upcoming sessions (self-contained)
    const upcomingSessions = [
        { id: 101, name: 'Elite Basketball Training', date: '2025-02-10' },
        { id: 102, name: 'Youth Soccer League', date: '2025-02-15' },
        { id: 103, name: 'Strength & Conditioning', date: '2025-02-18' },
    ];

    return (
        <Box sx={{ backgroundColor: '#202020', padding: '1.5rem', borderRadius: '12px', mb: 2 }}> {/* ✅ Added margin-bottom */}
            <Typography variant="h6">Upcoming Sessions</Typography>
            <TableContainer component={Paper} sx={{ backgroundColor: '#202020' }}>
                <Table>
                    <TableBody>
                        {upcomingSessions.map((session) => (
                            <TableRow key={session.id}>
                                <TableCell sx={{ color: '#ddd' }}>{session.date}</TableCell>
                                <TableCell>
                                    <Button component={Link} to={`/sessions/${session.id}`} sx={{ color: '#12c099' }}>
                                        {session.name}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default UpcomingSessions;
