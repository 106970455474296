import React, { useEffect, useState } from 'react';
import {
    Avatar,
    TextField,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Paper,
    Pagination,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import InviteButton from '../components/waitlist/InviteButton';


const SearchBar = styled(TextField)({
    marginBottom: '20px',
    width: '100%',
    backgroundColor: '#202020',
    borderRadius: '8px',
    color: '#fff',
    '& .MuiInputBase-root': {
        color: '#fff',
        fontSize: '0.9rem',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#12c099',
    },
    '& .MuiInputLabel-root': {
        color: '#12c099',
        fontSize: '0.9rem',
    },
});

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#202020',
    borderRadius: '12px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    overflowX: 'auto',
    '@media (max-width: 768px)': {
        width: '100%',
    },
});

const CompactTableCell = styled(TableCell)({
    padding: '16px 20px',
    fontSize: '1rem',
    '@media (max-width: 768px)': {
        display: 'block',
        width: '100%',
        padding: '12px 0',
        fontSize: '0.9rem',
        textAlign: 'left',
    },
    color: '#fff',
});

const CompactTableRow = styled(TableRow)({
    '@media (max-width: 768px)': {
        display: 'block',
        width: '100%',
        marginBottom: '20px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        backgroundColor: '#202020',
        overflow: 'hidden',
    },
});

const PaginationBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    '@media (max-width: 768px)': {
        marginTop: '10px',
    },
});

const WaitlistContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#1c1c1c',
    color: '#fff',
    padding: '1rem',
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
        padding: '0.5rem',
    },
});

const HeaderContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    flexWrap: 'wrap',
    '@media (max-width: 768px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
});

const Title = styled(Typography)({
    fontSize: '1.8rem',
    color: '#fff',
    fontWeight: 'bold',
    '@media (max-width: 768px)': {
        fontSize: '1.5rem',
    },
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    marginTop: 'auto',
    width: '100%',
});

const Waitlist = () => {
    const [waitlistData, setWaitlistData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [geocodedData, setGeocodedData] = useState({});
    const [page, setPage] = useState(1);
    const rowsPerPage = 5;
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('firstName');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchWaitlist = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/waitlist`);
                setWaitlistData(response.data);
                await fetchGeocodedData(response.data.slice(0, rowsPerPage));
            } catch (error) {
                console.error('Error fetching waitlist data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchWaitlist();
    }, []);

    const fetchGeocodedData = async (paginatedWaitlist) => {
        const data = {};

        for (const person of paginatedWaitlist) {
            try {
                const zipCode = person.zipCode || person.zipcode;

                if (!zipCode) {
                    data[person._id] = { city: 'N/A', state: 'N/A' };
                    continue;
                }

                const geoResponse = await axios.get(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
                );

                if (geoResponse.data.results.length > 0) {
                    const addressComponents = geoResponse.data.results[0].address_components;
                    const city =
                        addressComponents.find((comp) => comp.types.includes('locality'))?.long_name ||
                        addressComponents.find((comp) => comp.types.includes('neighborhood'))?.long_name ||
                        addressComponents.find((comp) => comp.types.includes('sublocality'))?.long_name ||
                        person.city || // Fallback to the database city
                        'N/A';
                    const state =
                        addressComponents.find((comp) => comp.types.includes('administrative_area_level_1'))?.short_name ||
                        person.state || // Fallback to the database state
                        'N/A';

                    data[person._id] = { city, state };
                } else {
                    console.warn(`No results from Google API for ${zipCode}`);
                    data[person._id] = { city: person.city || 'N/A', state: person.state || 'N/A' };
                }
            } catch (error) {
                console.error(`Geocoding API failed for ${person.zipCode}:`, error);
                data[person._id] = { city: person.city || 'N/A', state: person.state || 'N/A' };
            }
        }
        setGeocodedData(data);
    };

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const filteredWaitlist = waitlistData.filter(person =>
        person.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        person.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedWaitlist = filteredWaitlist.sort((a, b) => {
        if (order === 'asc') {
            return a[orderBy].localeCompare(b[orderBy]);
        }
        return b[orderBy].localeCompare(a[orderBy]);
    });

    const paginatedWaitlist = sortedWaitlist.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const openDeleteDialog = (userId) => {
        setUserToDelete(userId);
        setDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setUserToDelete(null);
    };

    const confirmDelete = async () => {
        if (userToDelete) {
            try {
                await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/delete-from-waitlist/${userToDelete}`);
                setWaitlistData(waitlistData.filter((person) => person._id !== userToDelete));
                closeDeleteDialog();
            } catch (error) {
                console.error('Error deleting waitlist user:', error);
            }
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress color="inherit" />
            </Box>
        );
    }

    return (
        <WaitlistContainer>
            <HeaderContainer>
                <Title variant="h4" gutterBottom>Waitlist</Title>
                <SearchBar
                    variant="outlined"
                    label="Search Waitlist"
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </HeaderContainer>
            <StyledTableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <CompactTableCell>
                                <TableSortLabel
                                    active={orderBy === 'firstName'}
                                    direction={orderBy === 'firstName' ? order : 'asc'}
                                    onClick={() => handleSortRequest('firstName')}
                                    sx={{ color: '#12c099' }}
                                >
                                    Name
                                </TableSortLabel>
                            </CompactTableCell>
                            <CompactTableCell>
                                <TableSortLabel
                                    active={orderBy === 'city'}
                                    direction={orderBy === 'city' ? order : 'asc'}
                                    onClick={() => handleSortRequest('city')}
                                    sx={{ color: '#12c099' }}
                                >
                                    City
                                </TableSortLabel>
                            </CompactTableCell>
                            <CompactTableCell>
                                <TableSortLabel
                                    active={orderBy === 'state'}
                                    direction={orderBy === 'state' ? order : 'asc'}
                                    onClick={() => handleSortRequest('state')}
                                    sx={{ color: '#12c099' }}
                                >
                                    State
                                </TableSortLabel>
                            </CompactTableCell>
                            <CompactTableCell>
                                <TableSortLabel
                                    active={orderBy === 'zipCode'}
                                    direction={orderBy === 'zipCode' ? order : 'asc'}
                                    onClick={() => handleSortRequest('zipCode')}
                                    sx={{ color: '#12c099' }}
                                >
                                    Zip Code
                                </TableSortLabel>
                            </CompactTableCell>

                            <CompactTableCell>
                                <TableSortLabel
                                    active={orderBy === 'country'}
                                    direction={orderBy === 'country' ? order : 'asc'}
                                    onClick={() => handleSortRequest('country')}
                                    sx={{ color: '#12c099' }}
                                >
                                    Country
                                </TableSortLabel>
                            </CompactTableCell>
                            <CompactTableCell>
                                <TableSortLabel
                                    active={orderBy === 'dateJoined'}
                                    direction={orderBy === 'dateJoined' ? order : 'asc'}
                                    onClick={() => handleSortRequest('dateJoined')}
                                    sx={{ color: '#12c099' }}
                                >
                                    Date Joined
                                </TableSortLabel>
                            </CompactTableCell>
                            <CompactTableCell>
                                Actions
                            </CompactTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedWaitlist.map((person) => (
                            <CompactTableRow key={person._id}>
                                <CompactTableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar sx={{ marginRight: '10px', width: 32, height: 32, fontSize: '1rem' }}>
                                            {person.firstName.charAt(0)}
                                        </Avatar>
                                        <Link to={`/waitlist-member/${person._id}`}
                                              style={{ color: '#12c099', textDecoration: 'none', fontSize: '1rem' }}>
                                            {`${person.firstName} ${person.lastName}`}
                                        </Link>
                                    </Box>
                                </CompactTableCell>
                                <CompactTableCell>
                                    {geocodedData[person._id]?.city || person.city || 'N/A'}
                                </CompactTableCell>
                                <CompactTableCell>
                                    {geocodedData[person._id]?.state || person.state || 'N/A'}
                                </CompactTableCell>
                                <CompactTableCell>
                                    {person.zipCode || 'N/A'}
                                </CompactTableCell>
                                <CompactTableCell>
                                    {person.country || 'N/A'}
                                </CompactTableCell>
                                <CompactTableCell>
                                    {new Date(person.dateJoined).toLocaleDateString()}
                                </CompactTableCell>
                                <CompactTableCell>
                                    <IconButton onClick={() => openDeleteDialog(person._id)} sx={{ color: '#ff1744' }}>
                                        <DeleteIcon />
                                    </IconButton>
                                    <InviteButton
                                        email={person.email}
                                        zipCode={person.zipCode}
                                        city={geocodedData[person._id]?.city || person.city}
                                        state={geocodedData[person._id]?.state || person.state}
                                    />
                                </CompactTableCell>
                            </CompactTableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <PaginationBox>
                <Pagination
                    count={Math.ceil(filteredWaitlist.length / rowsPerPage)}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    sx={{ button: { color: '#12c099' }, ul: { color: '#12c099' } }}
                />
            </PaginationBox>
            <Dialog
                open={deleteDialogOpen}
                onClose={closeDeleteDialog}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove this user from the waitlist?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </WaitlistContainer>
    );
};

export default Waitlist;
