import React, { useState } from "react";
import {
    Card, CardContent, Typography, Grid, Button, Modal, Box, TextField, IconButton
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import axios from "axios";
import { useParams } from "react-router-dom";

const Campaigns = ({ campaigns = [] }) => {
    const { id: partnerId } = useParams();

    const [open, setOpen] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [newCampaign, setNewCampaign] = useState({
        name: "",
        startDate: "",
        endDate: "",
        spend: "",
        creative: null,
    });

    const handleOpen = (campaign = null, index = null) => {
        if (campaign) {
            setNewCampaign(campaign);
            setEditingIndex(index);
        } else {
            setNewCampaign({
                name: "",
                startDate: "",
                endDate: "",
                spend: "",
                creative: null,
            });
            setEditingIndex(null);
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setNewCampaign({
            name: "",
            startDate: "",
            endDate: "",
            spend: "",
            creative: null,
        });
        setEditingIndex(null);
    };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setNewCampaign((prev) => ({
            ...prev,
            [name]: files ? files[0] : value,
        }));
    };

    const handleSubmit = async () => {
        try {
            let updatedCampaigns = [...campaigns];

            if (editingIndex !== null) {
                updatedCampaigns[editingIndex] = { ...newCampaign };
            } else {
                updatedCampaigns.push({ ...newCampaign });
            }

            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/update-marketing-partner/${partnerId}`, {
                campaigns: updatedCampaigns
            });

            handleClose();
            window.location.reload(); // Optional: refetch instead of reload if needed
        } catch (error) {
            console.error("Failed to update campaigns", error);
        }
    };

    return (
        <>
            <Card sx={{ backgroundColor: "#202020", padding: "1.5rem", borderRadius: "12px", maxHeight: "600px", overflowY: "auto" }}>
                <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography variant="h5" sx={{ color: "#12c099" }}>Campaigns</Typography>
                        <IconButton onClick={() => handleOpen()} sx={{ color: "#12c099" }}>
                            <AddIcon />
                        </IconButton>
                    </Box>

                    {campaigns.length === 0 ? (
                        <Typography sx={{ color: "#fff" }}>No campaigns yet.</Typography>
                    ) : (
                        <Box display="flex" flexDirection="column" gap={2}>
                            {campaigns.map((c, idx) => (
                                <Box
                                    key={idx}
                                    sx={{
                                        p: 2,
                                        backgroundColor: "#1c1c1c",
                                        borderRadius: "8px",
                                        position: "relative"
                                    }}
                                >
                                    <IconButton
                                        onClick={() => handleOpen(c, idx)}
                                        size="small"
                                        sx={{ position: "absolute", top: 8, right: 8, color: "#12c099" }}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                    <Typography variant="subtitle1" sx={{ fontWeight: 600, color: "#fff" }}>{c.name}</Typography>
                                    <Typography variant="body2" sx={{ color: "#fff" }}>

                                        {new Date(c.startDate).toLocaleDateString()} – {new Date(c.endDate).toLocaleDateString()}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: "#ccc" }}>Spend: ${c.spend?.toLocaleString()}</Typography>
                                    {c.creativeUrl && (
                                        <Typography variant="body2">
                                            <a href={c.creativeUrl} target="_blank" rel="noopener noreferrer" style={{ color: "#12c099" }}>
                                                View Creative
                                            </a>
                                        </Typography>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    )}
                </CardContent>
            </Card>

            {/* Modal for Adding/Editing Campaign */}
            <Modal open={open} onClose={handleClose}>
                <Box sx={{
                    backgroundColor: "#202020",
                    color: "#fff",
                    p: 4,
                    borderRadius: 2,
                    width: "90%",
                    maxWidth: 500,
                    mx: "auto",
                    mt: "10%",
                    boxShadow: 24,
                }}>
                    <Typography variant="h5" sx={{ mb: 3, color: "#12c099", fontWeight: 600 }}>
                        {editingIndex !== null ? "Edit Campaign" : "Add New Campaign"}
                    </Typography>

                    <TextField
                        label="Campaign Name"
                        name="name"
                        fullWidth
                        variant="outlined"
                        value={newCampaign.name}
                        onChange={handleChange}
                        sx={{ mb: 2, backgroundColor: "#333", input: { color: "#fff" }, label: { color: "#ccc" } }}
                        InputLabelProps={{ style: { color: "#ccc" } }}
                    />
                    <TextField
                        label="Start Date"
                        name="startDate"
                        type="date"
                        fullWidth
                        value={newCampaign.startDate?.slice(0, 10)}
                        onChange={handleChange}
                        sx={{ mb: 2, backgroundColor: "#333", input: { color: "#fff" }, label: { color: "#ccc" } }}
                        InputLabelProps={{ shrink: true, style: { color: "#ccc" } }}
                    />
                    <TextField
                        label="End Date"
                        name="endDate"
                        type="date"
                        fullWidth
                        value={newCampaign.endDate?.slice(0, 10)}
                        onChange={handleChange}
                        sx={{ mb: 2, backgroundColor: "#333", input: { color: "#fff" }, label: { color: "#ccc" } }}
                        InputLabelProps={{ shrink: true, style: { color: "#ccc" } }}
                    />
                    <TextField
                        label="Spend"
                        name="spend"
                        type="number"
                        fullWidth
                        value={newCampaign.spend}
                        onChange={handleChange}
                        sx={{ mb: 2, backgroundColor: "#333", input: { color: "#fff" }, label: { color: "#ccc" } }}
                        InputLabelProps={{ style: { color: "#ccc" } }}
                    />

                    <Button
                        variant="outlined"
                        component="label"
                        sx={{ mb: 3, color: "#12c099", borderColor: "#12c099" }}
                        startIcon={<UploadFileIcon />}
                    >
                        Upload Creative
                        <input type="file" name="creative" hidden onChange={handleChange} />
                    </Button>

                    <Button
                        fullWidth
                        variant="contained"
                        sx={{
                            backgroundColor: "#12c099",
                            color: "#000",
                            "&:hover": {
                                backgroundColor: "#0fa586"
                            }
                        }}
                        onClick={handleSubmit}
                    >
                        Save Campaign
                    </Button>
                </Box>
            </Modal>

        </>
    );
};

export default Campaigns;
