import React, { useEffect, useState } from 'react';
import { Card, Typography, Button, Box } from '@mui/material';
import axios from 'axios';

const AvailableCollaboratorsComponent = () => {
    const [collaborators, setCollaborators] = useState([]);
    const [directReport, setDirectReport] = useState('');

    useEffect(() => {
        const fetchCollaborators = async () => {
            try {
                console.log('Fetching available collaborators');
                const response = await axios.get('/api/employees/contributors');
                console.log('Collaborators data:', response.data);
                setCollaborators(response.data.contributors || []); // Default to empty array if undefined
                setDirectReport(response.data.directReport || ''); // Default to empty string if undefined
            } catch (error) {
                console.error('Error fetching collaborators:', error);
            }
        };
        fetchCollaborators();
    }, []);

    console.log('Rendering AvailableCollaboratorsComponent with state:', { collaborators, directReport });

    return (
        <Card sx={{
            backgroundColor: '#202020',
            color: '#fff',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
        }}>
            <Typography variant="h6" gutterBottom>Available Collaborators</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {collaborators.map((collaborator, index) => (
                    <Button
                        key={index}
                        variant="outlined"
                        sx={{
                            borderColor: '#12c099',
                            color: '#12c099',
                            textTransform: 'none',
                            fontSize: '1rem', // Increased font size for consistency
                            padding: '6px 12px', // Adjusted padding for consistency
                            minWidth: '120px', // Ensuring buttons are of similar width to neighborhoods
                        }}
                        onClick={() => console.log('Collaborator clicked:', collaborator)}
                    >
                        {collaborator}
                    </Button>
                ))}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                <Typography variant="body1">
                    Direct Report: {directReport || 'No direct report assigned'}
                </Typography>
            </Box>
        </Card>
    );
};

export default AvailableCollaboratorsComponent;
