import React from "react";
import { Paper, Typography, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

const SectionTitle = styled(Typography)({
    color: "#12c099",
    fontWeight: "bold",
    marginBottom: "1rem",
});

const PlanCard = styled(Paper)({
    backgroundColor: "#202020",
    color: "#fff",
    padding: "1.5rem",
    borderRadius: "8px",
    marginTop: "2rem",
    textAlign: "left",
});

/** ✅ Function to determine plan type and description */
const getPlanDetails = (planDetails) => {
    const isRecurring = planDetails?.[0]?.isRecurring;
    return {
        type: isRecurring ? "Recurring Plan" : "Pay As You Go (PayGo)",
        description: isRecurring
            ? "This athlete is on a recurring plan, receiving 8 credits per month at a discounted rate for attending sessions on a flexible schedule."
            : "This athlete purchases credits individually as needed to attend sessions without a monthly commitment."
    };
};

const AthletePlan = ({ planDetails }) => {
    const { type, description } = getPlanDetails(planDetails);

    return (
        <PlanCard>
            <SectionTitle variant="h6">Current Plan</SectionTitle>
            <Typography variant="body1" sx={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                {type}
            </Typography>
            <Divider sx={{ backgroundColor: "#fff", margin: "1rem 0" }} />
            <Typography variant="body2" sx={{ color: "#b0b0b0" }}>
                {description}
            </Typography>
        </PlanCard>
    );
};

export default AthletePlan;
