import React, { useState } from "react";
import { Modal, Box, Typography, Button, CircularProgress } from "@mui/material";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || "https://api.admin.academy.marsreel.com";

const VerifyEmailModal = ({ open, onClose, employee, onVerificationSent }) => {
    console.log("🔍 VerifyEmailModal received employee:", employee); // ✅ Debugging

    const [loading, setLoading] = useState(false);

    const handleVerifyEmail = async () => {
        if (!employee?._id) {
            console.error("❌ Employee ID is missing.");
            return;
        }

        try {
            setLoading(true);
            await axios.post(`${API_BASE_URL}/api/outreach/verify-sender`, { employeeId: employee._id });
            alert("Verification email sent. Check your inbox.");
            onVerificationSent();
        } catch (error) {
            console.error("Error verifying email:", error);
        } finally {
            setLoading(false);
            onClose();
        }
    };


    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    backgroundColor: "#202020",
                    padding: "24px",
                    borderRadius: "8px",
                    width: "350px",
                    margin: "auto",
                    marginTop: "20vh",
                    textAlign: "center",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
                }}
            >
                <Typography variant="h6" sx={{ color: "#fff", marginBottom: "12px" }}>
                    Verify Your Email
                </Typography>
                <Typography variant="body2" sx={{ color: "#bbb", marginBottom: "16px" }}>
                    A verification email will be sent to <strong>{employee?.email || "Unknown Email"}</strong>.
                    Click the link in your inbox to complete verification.
                </Typography>
                <Button
                    variant="contained"
                    sx={{ backgroundColor: "#12c099", width: "100%", marginBottom: "12px" }}
                    onClick={handleVerifyEmail}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : "Send Verification Email"}
                </Button>
                <Button
                    variant="outlined"
                    sx={{ color: "#fff", borderColor: "#fff", width: "100%" }}
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </Box>
        </Modal>
    );
};

export default VerifyEmailModal;
