import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/system";

const FooterContainer = styled(Box)({
    width: "100%",
    padding: "2rem 0", // Increased padding for better spacing
    textAlign: "center",
    fontSize: "0.9rem",
    color: "#b0b0b0",
    borderTop: "1px solid #303030",
    backgroundColor: "#1c1c1c", // Updated to match Leads page
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "auto", // Pushes footer to the bottom
});

const Footer = () => {
    return <FooterContainer>© 2025 Mars Reel Media Corp. All rights reserved.</FooterContainer>;
};

export default Footer;
