import React from 'react';
import { Card, Typography } from '@mui/material';
import { GoogleMap, LoadScript, Circle } from '@react-google-maps/api';

const ScanDensityMap = ({ totalScans = [] }) => {
    const center = totalScans.find(scan => scan.latitude && scan.longitude) || {
        latitude: 37.0902,
        longitude: -95.7129,
    };

    const scanPoints = totalScans.filter(scan => scan.latitude && scan.longitude);

    return (
        <Card sx={{ backgroundColor: '#202020', color: '#fff', padding: '1.5rem', borderRadius: '12px', height: 400 }}>
            <Typography variant="h5" sx={{ color: '#12c099', mb: 2 }}>Scan Density</Typography>
            <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '300px' }}
                    center={{ lat: center.latitude, lng: center.longitude }}
                    zoom={4}
                >
                    {scanPoints.map((scan, index) => (
                        <Circle
                            key={index}
                            center={{ lat: scan.latitude, lng: scan.longitude }}
                            radius={30000}
                            options={{
                                strokeColor: '#12c099',
                                strokeOpacity: 0.6,
                                strokeWeight: 1,
                                fillColor: '#12c099',
                                fillOpacity: 0.3,
                            }}
                        />
                    ))}
                </GoogleMap>
            </LoadScript>
        </Card>
    );
};

export default ScanDensityMap;
