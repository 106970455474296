import React, { useEffect, useState } from "react";
import {
    Card,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Button,
    IconButton,
    TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close"; // X icon
import axios from "axios";
import { getAuth } from "firebase/auth";
import { Link } from "react-router-dom";

const MyVenuesComponent = () => {
    const [venues, setVenues] = useState([]);
    const [editingVenueId, setEditingVenueId] = useState(null);
    const [editedVenueName, setEditedVenueName] = useState("");

    useEffect(() => {
        const fetchVenues = async () => {
            try {
                const auth = getAuth();
                const user = auth.currentUser;

                if (!user) return;

                const response = await axios.get(`/api/venues/${user.uid}`);
                setVenues(response.data);
            } catch (error) {
                console.error("Error fetching venues:", error);
            }
        };

        fetchVenues();
    }, []);

    const handleEditClick = (venue) => {
        setEditingVenueId(venue._id);
        setEditedVenueName(venue.venueName);
    };

    const handleSaveClick = async (venueId) => {
        try {
            await axios.put(`/api/active-locations/${venueId}/venue-name`, {
                venueName: editedVenueName,
            });
            setVenues((prevVenues) =>
                prevVenues.map((venue) =>
                    venue._id === venueId ? { ...venue, venueName: editedVenueName } : venue
                )
            );
            setEditingVenueId(null);
        } catch (error) {
            console.error("Error updating venue name:", error);
        }
    };

    const handleCancelClick = () => {
        setEditingVenueId(null);
        setEditedVenueName("");
    };

    const formatDateTime = (utcString) => {
        return new Date(utcString).toLocaleString(undefined, {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        });
    };

    return (
        <Card
            sx={{
                backgroundColor: "#202020",
                color: "#fff",
                padding: "1.5rem",
                borderRadius: "12px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            }}
        >
            <Typography
                variant="h6"
                sx={{ color: "#fff", fontWeight: "bold", marginBottom: "1rem" }}
            >
                My Venues
            </Typography>

            {venues.length === 0 ? (
                <Typography sx={{ textAlign: "center", color: "#aaa" }}>
                    No active venues assigned.
                </Typography>
            ) : (
                venues.map((venue, index) => (
                    <Accordion
                        key={index}
                        sx={{
                            backgroundColor: "#282828",
                            color: "#fff",
                            marginBottom: "8px",
                            borderRadius: "8px",
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: "#12c099" }} />}
                        >
                            {editingVenueId === venue._id ? (
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <TextField
                                        value={editedVenueName}
                                        onChange={(e) => setEditedVenueName(e.target.value)}
                                        size="small"
                                        variant="outlined"
                                        sx={{
                                            backgroundColor: "#fff",
                                            borderRadius: "4px",
                                            marginRight: "8px",
                                        }}
                                    />
                                    <IconButton
                                        onClick={() => handleSaveClick(venue._id)}
                                        color="success"
                                    >
                                        <CheckIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={handleCancelClick}
                                        color="error"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            ) : (
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Button
                                        component={Link}
                                        to={`/facilities/${venue._id ? venue._id.toString() : "undefined"}`}
                                        sx={{
                                            fontSize: "1rem",
                                            fontWeight: "bold",
                                            textTransform: "none",
                                            color: "#12c099",
                                        }}
                                        onClick={() => console.log("Navigating to:", `/facilities/${venue._id}`)}
                                    >
                                        {venue.venueName}
                                    </Button>
                                    <IconButton
                                        onClick={() => handleEditClick(venue)}
                                        sx={{ color: "#12c099", marginLeft: "8px" }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Box>
                            )}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "10px",
                                    flexWrap: "wrap",
                                    paddingTop: "5px",
                                }}
                            >
                                {venue.sessions
                                    .filter(session => new Date(session.endTime) > new Date()) // Filter out past sessions
                                    .map((session, idx) => (
                                        <Button
                                            key={idx}
                                            variant="outlined"
                                            sx={{
                                                borderColor: "#12c099",
                                                color: "#12c099",
                                                fontSize: "0.85rem",
                                                fontWeight: "bold",
                                                padding: "6px 12px",
                                                borderRadius: "6px",
                                            }}
                                        >
                                            {formatDateTime(session.startTime)} - {formatDateTime(session.endTime)}
                                        </Button>
                                    ))}
                            </Box>
                        </AccordionDetails>

                    </Accordion>
                ))
            )}
        </Card>
    );
};

export default MyVenuesComponent;
