import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Box, Button } from "@mui/material";

const RevenueComponent = () => {
    const [selectedRange, setSelectedRange] = useState(30);
    const [revenueData, setRevenueData] = useState({ 30: 0, 60: 0, 90: 0 });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRevenue = async () => {
            try {
                const response = await fetch("https://api.admin.marsreel.com/api/financials/revenue/summary");
                const data = await response.json();
                setRevenueData(data.revenue);
            } catch (error) {
                console.error("Error fetching revenue:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchRevenue();
    }, []);

    return (
        <Card sx={{
            backgroundColor: "#202020",
            color: "#fff",
            padding: "1.5rem",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "180px",
            justifyContent: "center"
        }}>
            <CardContent sx={{ textAlign: "center" }}>
                <Typography variant="h5" sx={{ fontWeight: "500", marginBottom: "1rem" }}>
                    Total Revenue
                </Typography>
                {loading ? (
                    <Typography variant="h3" sx={{ color: "#12c099", fontWeight: "bold" }}>Loading...</Typography>
                ) : (
                    <Typography variant="h3" sx={{ color: "#12c099", fontWeight: "bold" }}>
                        ${parseFloat(revenueData[selectedRange]).toLocaleString()}
                    </Typography>
                )}
                <Box sx={{ display: "flex", gap: "8px", marginTop: "1rem" }}>
                    {[30, 60, 90].map((range) => (
                        <Button
                            key={range}
                            variant={selectedRange === range ? "contained" : "outlined"}
                            sx={{
                                backgroundColor: selectedRange === range ? "#0f8c72" : "transparent",
                                borderColor: "#12c099",
                                color: selectedRange === range ? "#fff" : "#12c099",
                                fontSize: "0.85rem",
                                fontWeight: "bold",
                                padding: "6px 12px",
                                borderRadius: "6px",
                                "&:hover": {
                                    backgroundColor: selectedRange === range ? "#0e7f68" : "#12c099",
                                    color: "#fff",
                                }
                            }}
                            onClick={() => setSelectedRange(range)}
                        >
                            {range} Days
                        </Button>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
};

export default RevenueComponent;
