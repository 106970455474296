import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
    Box, Typography, Button, Card, Table, TableBody,
    TableCell, TableContainer, TableRow, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LegalInfoModal from '../components/venues/LegalInfoModal'; // Import modal
import RevenueToDate from '../components/venues/RevenueToDate';
import TotalSessionsOrganized from '../components/venues/TotalSessionsOrganized';
import HourlyRate from '../components/venues/HourlyRate';
import WeatherForecast from '../components/venues/WeatherForecast';
import RecentUpdates from '../components/venues/RecentUpdates';
import PrimaryContact from '../components/venues/PrimaryContact';
import UpcomingSessions from '../components/venues/UpcomingSessions';
import CoachesNearby from '../components/venues/CoachesNearby';
import SiteManagersNearby from '../components/venues/SiteManagersNearby';

const StyledContainer = styled(Box)({
    padding: '2rem',
    backgroundColor: '#1c1c1c',
    color: '#fff',
    minHeight: '100vh',
});

const FacilityDetails = () => {
    const { id } = useParams();
    const [venueName, setVenueName] = useState("Loading...");
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (!id) {
            console.error("❌ Venue ID is undefined");
            return;
        }

        const fetchVenue = async () => {
            try {
                console.log(`Fetching data for facility ID: ${id}`); // Debugging
                const response = await axios.get(`https://api.admin.marsreel.com/api/facilities/${id}`);
                console.log("Facility API response:", response.data); // Debugging
                setVenueName(response.data.venueName || "Venue not found");
            } catch (error) {
                console.error("❌ Error fetching venue:", error);
                setVenueName("Venue not found");
            }
        };



        fetchVenue();
    }, [id]);

    return (
        <StyledContainer>
            <Typography variant="h4" gutterBottom>{venueName}</Typography>

            <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                <RevenueToDate />
                <HourlyRate />
            </Box>

            <TotalSessionsOrganized />

            <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', alignItems: 'stretch' }}>
                <WeatherForecast />
                <PrimaryContact />
            </Box>

            <RecentUpdates venueNotes={[]} facilityId={id} />

            <UpcomingSessions />

            <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                <CoachesNearby />
                <SiteManagersNearby />
            </Box>

            <Box sx={{ textAlign: 'center', marginTop: '2rem', padding: '1rem', color: '#aaa' }}>
                <Typography variant="body2">Copyright 2025 Mars Reel Media Corp.</Typography>
            </Box>

            <LegalInfoModal open={openModal} handleClose={() => setOpenModal(false)} />
        </StyledContainer>
    );
};

export default FacilityDetails;
