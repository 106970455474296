import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const LegalInfoModal = ({ open, handleClose }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
            <Box
                sx={{
                    backgroundColor: '#1c1c1c',
                    color: '#fff',
                    padding: '3rem',
                    maxWidth: '900px',
                    width: '90%',
                    maxHeight: '85vh',
                    borderRadius: '12px',
                    boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.5)',
                    overflowY: 'auto'
                }}
            >
                <Typography
                    variant="h4"
                    sx={{ fontWeight: 'bold', textAlign: 'center', mb: 3 }}
                >
                    AI-Assisted Note-Taking Legal & Technical Overview
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}>
                    Introduction
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                    At Mars Reel Academy, we strive to maintain operational efficiency while ensuring transparency and compliance
                    with applicable laws. Our AI-assisted note-taking system is designed to streamline venue booking, scheduling,
                    and session coordination by dynamically updating your venue changelogs in real time. This system allows our employees to
                    remain agile and aligned without the manual overhead of remembering to update logs after sending emails or placing phone calls to venues.
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}>
                    Legal Considerations
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                    Our AI note-taking system <b>does not record or store</b> any voice calls. Instead, it extracts key points related
                    to scheduling and operations in real-time, ensuring that all information is captured efficiently without retaining
                    any audio data.
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}>
                    Consent & Compliance with State Laws
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                    United States laws on call recording vary by state. Generally, states fall into two categories:
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                    One-Party Consent States:
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                    Only one person in the conversation needs to consent to note-taking or transcription. This means an employee using
                    the AI system can process key points without additional disclosure. The following states fall under one-party consent:
                </Typography>

                <Typography variant="body2" sx={{ mt: 1, mb: 2, lineHeight: 1.7 }}>
                    Alabama, Alaska, Arizona, Arkansas, Colorado, Connecticut (for in-person conversations), Georgia, Hawaii (if one-party is present),
                    Idaho, Indiana, Iowa, Kansas, Kentucky, Louisiana, Maine (if one-party is present), Minnesota, Mississippi, Missouri, Nebraska,
                    Nevada (for in-person conversations), New Jersey, New Mexico, New York, North Carolina, North Dakota, Ohio, Oklahoma, Oregon (for
                    in-person conversations), Rhode Island, South Carolina, South Dakota, Tennessee, Texas, Utah, Virginia, West Virginia, Wisconsin, Wyoming.
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    All-Party Consent States:
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                    All participants must be aware and consent to any form of transcription, even if the conversation is not recorded.
                    The following states require all-party consent:
                </Typography>

                <Typography variant="body2" sx={{ mt: 1, mb: 2, lineHeight: 1.7 }}>
                    California, Delaware, Florida, Illinois, Maryland, Massachusetts, Michigan, Montana, Nevada (for phone calls), New Hampshire,
                    Oregon (for phone calls), Pennsylvania, Vermont, Washington.
                </Typography>

                <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                    Since our system does not record conversations and only extracts relevant scheduling notes, it is not classified as a
                    traditional "call recording" system. However, in all-party consent states, employees are encouraged, but not required by law,
                    to provide a general disclosure that AI is assisting in note-taking.
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}>
                    How the System Works
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                    - The AI only listens for <b>scheduling-related keywords</b> (e.g., "venue booked at 5 PM," "reschedule session to Friday").<br />
                    - <b>No full transcripts</b> are generated, and <b>no audio is stored</b>.<br />
                    - The system <b>automatically deletes</b> all processed audio after extracting relevant details.<br />
                    - Employees and third parties are welcome to <b>opt out</b> if they prefer manual note-taking.
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}>
                    Employee Transparency Notice
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                    Our AI-powered note-taking feature exists to improve operational speed and accuracy. Employees should:
                </Typography>

                <Typography variant="body1" sx={{ mt: 2, lineHeight: 1.7 }}>
                    - Use the system <b>only</b> for work-related scheduling, booking, and coordination.<br />
                    - Notify other parties <b>if in an all-party consent state</b> and offer to share a copy of this policy if requested.<br />
                    - <b>Reassure</b> third parties that no audio is stored or recorded—<b>only key scheduling updates</b> are captured.
                </Typography>

                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }}>
                    Conclusion
                </Typography>
                <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                    This feature ensures Mars Reel Academy operates efficiently while maintaining compliance with call-processing laws.
                    By leveraging AI-assisted note-taking, we reduce human error, eliminate redundant tasks, and enable seamless coordination
                    between employees and venues.
                </Typography>

                <Typography variant="body1" sx={{ mt: 2, lineHeight: 1.7 }}>
                    Employees and call participants may request further clarification or opt out of this system at any time. However, this
                    system ensures that Mars Reel Academy can continue delivering a high-quality experience while maintaining compliance
                    with industry regulations.
                </Typography>

                {/* Close Button */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Button
                        sx={{
                            backgroundColor: '#12c099',
                            color: '#fff',
                            fontSize: '1rem',
                            padding: '10px 20px',
                            borderRadius: '8px',
                            '&:hover': { backgroundColor: '#0f9b87' }
                        }}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default LegalInfoModal;
