import React, { useState, useEffect } from "react";
import { Paper, Typography, List, ListItem, ListItemText, Link, Pagination, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";

const SectionTitle = styled(Typography)({
    color: "#12c099",
    fontWeight: "bold",
    marginBottom: "1rem",
});

const CoachCheckins = ({ firebaseUid }) => {
    const [sessions, setSessions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const sessionsPerPage = 5; // ✅ Show 5 check-ins per page

    useEffect(() => {
        const fetchCoachCheckins = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/sessions`);

                // ✅ Filter sessions where the coach's `firebaseUid` exists in `attendees`
                const filteredSessions = response.data.filter(session =>
                    session.attendees.includes(firebaseUid) // 🔥 Only sessions where the coach actually checked in
                );

                setSessions(filteredSessions);
            } catch (error) {
                console.error("Error fetching coach check-ins:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCoachCheckins();
    }, [firebaseUid]);

    if (loading) {
        return <Typography sx={{ color: "#fff" }}>Loading check-ins...</Typography>;
    }

    if (sessions.length === 0) {
        return (
            <Paper sx={{ padding: "1.5rem", backgroundColor: "#202020", color: "#fff", borderRadius: "8px", marginTop: "2rem" }}>
                <SectionTitle variant="h6">Checked-in Sessions</SectionTitle>
                <Typography sx={{ color: "#fff" }}>No check-ins found.</Typography>
            </Paper>
        );
    }

    // Calculate total pages
    const totalPages = Math.ceil(sessions.length / sessionsPerPage);

    // Get sessions for the current page
    const paginatedSessions = sessions.slice(
        (page - 1) * sessionsPerPage,
        page * sessionsPerPage
    );

    return (
        <Paper sx={{ padding: "1.5rem", backgroundColor: "#202020", color: "#fff", borderRadius: "8px", marginTop: "2rem" }}>
            <SectionTitle variant="h6">Coached Sessions</SectionTitle>
            <List>
                {paginatedSessions.map((session) => (
                    <ListItem key={session._id}>
                        <ListItemText
                            primary={
                                <Link href={`/session/${session._id}`} sx={{ color: "#12c099", textDecoration: "none", fontWeight: "bold" }}>
                                    {session.name}
                                </Link>
                            }
                            secondary={
                                <Typography sx={{ color: "#fff" }}>
                                    Date: {new Date(session.eventDate).toLocaleDateString()}
                                </Typography>
                            }
                        />
                    </ListItem>
                ))}
            </List>

            {/* ✅ Pagination Controls */}
            {totalPages > 1 && (
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                        sx={{
                            "& .MuiPaginationItem-root": { color: "#12c099" },
                        }}
                    />
                </Box>
            )}
        </Paper>
    );
};

export default CoachCheckins;
