import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)({
    backgroundColor: '#202020',
    color: '#fff',
    borderRadius: '12px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    padding: '1.5rem',
    marginBottom: '20px',
    flex: 1, // Ensures it takes equal space in flexbox
});

const TotalSessionsOrganized = () => {
    const facility = {
        sessionsOrganized: 320,
    };

    return (
        <StyledCard>
            <CardContent>
                <Typography variant="h6">Total Sessions Organized</Typography>
                <Typography variant="h4" color="#12c099">{facility.sessionsOrganized}</Typography>
            </CardContent>
        </StyledCard>
    );
};

export default TotalSessionsOrganized;
