import React, { useState, useEffect } from "react";
import { Box, Button, Modal, TextField, MenuItem, Fab, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || "https://api.admin.academy.marsreel.com";

const FloatingButton = styled(Fab)({
    position: "fixed",
    bottom: 16,
    right: 80, // Adjusted position to be next to Add Lead button
    backgroundColor: "#12c099",
    "&:hover": {
        backgroundColor: "#0ea87f",
    },
});

const ModalContainer = styled(Box)({
    padding: "20px",
    backgroundColor: "#202020",
    width: "400px",
    margin: "auto",
    marginTop: "15vh",
    borderRadius: "12px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
    color: "#fff",
});

const StyledInput = styled(TextField)({
    marginBottom: "12px",
    width: "100%",
    "& .MuiInputBase-root": {
        color: "#fff",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#12c099",
    },
    "& .MuiInputLabel-root": {
        color: "#12c099",
    },
});

const SharePipelineButton = ({ firebaseUid, refreshLeads }) => {
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState("");

    // ✅ Fetch employees for the dropdown
    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/employees`);
                setEmployees(response.data);
            } catch (error) {
                console.error("Error fetching employees:", error);
            }
        };

        fetchEmployees();
    }, []);

    // ✅ Handle pipeline sharing
    const handleSharePipeline = async () => {
        if (!selectedEmployee) return alert("Please select an employee to share with.");

        try {
            await axios.post(`${API_BASE_URL}/api/outreach/share`, {
                ownerId: firebaseUid,
                shareWithId: selectedEmployee,
            });

            alert("Pipeline shared successfully!");
            setShareModalOpen(false);
            refreshLeads();
        } catch (error) {
            console.error("Error sharing pipeline:", error);
            alert("Error sharing pipeline. Try again.");
        }
    };

    return (
        <>
            {/* ✅ Floating Share Pipeline Button */}
            <FloatingButton onClick={() => setShareModalOpen(true)}>
                <GroupAddIcon />
            </FloatingButton>

            {/* ✅ Share Pipeline Modal */}
            <Modal open={shareModalOpen} onClose={() => setShareModalOpen(false)}>
                <ModalContainer>
                    <Typography variant="h6">Share Your Pipeline</Typography>
                    <StyledInput
                        select
                        label="Select Employee"
                        fullWidth
                        value={selectedEmployee}
                        onChange={(e) => setSelectedEmployee(e.target.value)}
                    >
                        {employees.map((employee) => (
                            <MenuItem key={employee.firebaseUid} value={employee.firebaseUid}>
                                {employee.name} - {employee.title || "No Title"} ({employee.email})
                            </MenuItem>
                        ))}
                    </StyledInput>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 2, backgroundColor: "#12c099" }}
                        onClick={handleSharePipeline}
                    >
                        Share Pipeline
                    </Button>
                </ModalContainer>
            </Modal>
        </>
    );
};

export default SharePipelineButton;
