import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";

const DigitalSubscriptionsRevenueComponent = () => {
    const [revenue, setRevenue] = useState(null);
    const [projectedRevenue, setProjectedRevenue] = useState(null);
    const [change, setChange] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRevenue = async () => {
            try {
                const response = await fetch("https://api.admin.marsreel.com/api/financials/revenue/digital-subscriptions");
                const data = await response.json();

                setRevenue(data.totalRevenue);
                setProjectedRevenue(data.projectedRevenue);
                setChange(data.changePercentage);
            } catch (error) {
                console.error("Error fetching digital subscription revenue:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchRevenue();
    }, []);

    return (
        <Card sx={{
            backgroundColor: "#202020",
            color: "#fff",
            padding: "1.5rem",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "180px",
            justifyContent: "center"
        }}>
            <CardContent sx={{ textAlign: "center" }}>
                <Typography variant="h5" sx={{ fontWeight: "500", marginBottom: "1rem" }}>
                    Digital Subscriptions
                </Typography>
                {loading ? (
                    <Typography variant="h3" sx={{ color: "#12c099", fontWeight: "bold" }}>Loading...</Typography>
                ) : (
                    <>
                        <Typography variant="h3" sx={{ color: "#12c099", fontWeight: "bold" }}>
                            ${revenue ? revenue.toLocaleString() : "0"}
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: "0.5rem", color: "#aaa" }}>
                            Projected Next Month: <span style={{ color: "#12c099", fontWeight: "bold" }}>
                                ${projectedRevenue ? projectedRevenue.toLocaleString() : "0"}
                            </span>
                        </Typography>
                        <Typography variant="body2" sx={{ marginTop: "0.3rem", color: change >= 0 ? "#12c099" : "#e57373" }}>
                            {change >= 0 ? `+${change}%` : `${change}%`} from last month
                        </Typography>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default DigitalSubscriptionsRevenueComponent;
