import React, { useState, useEffect } from "react";
import { Box, Button, Modal, TextField, MenuItem, Fab, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://api.academy.marsreel.com";

const FloatingButton = styled(Fab)({
    position: "fixed",
    bottom: 16,
    right: 16,
    backgroundColor: "#12c099",
    "&:hover": {
        backgroundColor: "#0ea87f"
    }
});

const ModalContainer = styled(Box)({
    padding: "20px",
    backgroundColor: "#202020",
    width: "400px",
    margin: "auto",
    marginTop: "15vh",
    borderRadius: "12px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.4)",
    color: "#fff",
});

const StyledInput = styled(TextField)({
    marginBottom: "12px",
    width: "100%",
    "& .MuiInputBase-root": {
        color: "#fff"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#12c099"
    },
    "& .MuiInputLabel-root": {
        color: "#12c099"
    }
});

const AddLeadButton = ({ firebaseUid, refreshLeads }) => {
    const [open, setOpen] = useState(false);
    const [leadTypes, setLeadTypes] = useState([]);
    const [newLeadType, setNewLeadType] = useState("");
    const [addingNewType, setAddingNewType] = useState(false);
    const [newLead, setNewLead] = useState({
        name: "",
        email: "",
        phone: "",
        leadType: "",
        organization: "",
        city: "",
        state: "",
        stage: "New Leads"
    });

    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    useEffect(() => {
        const fetchLeadTypes = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/outreach/lead-types`);
                setLeadTypes(response.data);
            } catch (error) {
                console.error("Error fetching lead types:", error);
            }
        };

        fetchLeadTypes();
    }, []);

    /**
     * ✅ Fetch City/State Suggestions from API
     */
    const handleCityInput = async (input) => {
        if (!input || input.trim().length < 3) {
            setShowSuggestions(false);
            setAddressSuggestions([]);
            return;
        }

        try {
            console.log(`Fetching city suggestions for input: "${input}"`);
            const response = await axios.get(`${API_BASE_URL}/api/places/autocomplete`, {
                params: { input: input.trim() }
            });

            if (Array.isArray(response.data)) {
                setAddressSuggestions(response.data);
                setShowSuggestions(true);
            } else if (response.data && response.data.predictions) {
                setAddressSuggestions(response.data.predictions);
                setShowSuggestions(true);
            } else {
                console.error("❌ Unexpected API response format:", response.data);
                setAddressSuggestions([]);
                setShowSuggestions(false);
            }
        } catch (error) {
            console.error("❌ Error fetching city suggestions:", error);
            setAddressSuggestions([]);
            setShowSuggestions(false);
        }
    };

    /**
     * ✅ Fetch Full Address Details from API
     */
    const handleCitySelect = async (placeId) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/places/details`, {
                params: { placeId }
            });

            if (response.data) {
                const addressComponents = response.data.address_components;

                const city = addressComponents.find(c => c.types.includes("locality"))?.long_name || "";
                const state = addressComponents.find(c => c.types.includes("administrative_area_level_1"))?.short_name || "";

                console.log("✅ Extracted City/State:", { city, state });

                setNewLead(prevState => ({
                    ...prevState,
                    city,
                    state
                }));

                setShowSuggestions(false);
            } else {
                console.error("❌ No address details found in response:", response.data);
            }
        } catch (error) {
            console.error("❌ Error fetching city details:", error);
        }
    };

    /**
     * ✅ Add New Lead Type
     */
    const handleAddNewLeadType = async () => {
        if (!newLeadType.trim()) return;

        try {
            const response = await axios.post(`${API_BASE_URL}/api/outreach/lead-types/add`, { newLeadType });
            setLeadTypes([...leadTypes, response.data.newLeadType]);
            setAddingNewType(false);
            setNewLeadType("");
        } catch (error) {
            console.error("Error adding new lead type:", error);
        }
    };

    /**
     * ✅ Submit Lead Data
     */
    const handleAddLead = async () => {
        if (!firebaseUid) {
            console.warn("🚨 Cannot add lead: Firebase UID is not set.");
            return;
        }

        try {
            await axios.post(`${API_BASE_URL}/api/outreach/new-lead`, {
                ...newLead,
                assignedTo: firebaseUid,
            });

            refreshLeads();
            setOpen(false);
            setNewLead({
                name: "",
                email: "",
                phone: "",
                leadType: "",
                organization: "",
                city: "",
                state: "",
                stage: "New Leads"
            });
        } catch (error) {
            console.error("Error adding lead:", error);
        }
    };

    return (
        <>
            <FloatingButton onClick={() => setOpen(true)}>
                <AddIcon />
            </FloatingButton>

            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalContainer>
                    <Typography variant="h6">Add New Lead</Typography>
                    <StyledInput label="Full Name" value={newLead.name} onChange={(e) => setNewLead({ ...newLead, name: e.target.value })} />
                    <StyledInput label="Email" type="email" value={newLead.email} onChange={(e) => setNewLead({ ...newLead, email: e.target.value })} />
                    <StyledInput label="Phone Number" type="tel" value={newLead.phone} onChange={(e) => setNewLead({ ...newLead, phone: e.target.value })} />
                    <StyledInput label="Organization" value={newLead.organization} onChange={(e) => setNewLead({ ...newLead, organization: e.target.value })} />

                    {/* ✅ Lead Type Dropdown with Add New Type Option */}
                    {addingNewType ? (
                        <>
                            <StyledInput label="New Lead Type" value={newLeadType} onChange={(e) => setNewLeadType(e.target.value)} />
                            <Button sx={{ mt: 1, backgroundColor: "#12c099" }} variant="contained" onClick={handleAddNewLeadType}>
                                Save Lead Type
                            </Button>
                        </>
                    ) : (
                        <StyledInput select label="Lead Type" value={newLead.leadType} onChange={(e) => setNewLead({ ...newLead, leadType: e.target.value })}>
                            {leadTypes.map((type) => (
                                <MenuItem key={type} value={type}>{type}</MenuItem>
                            ))}
                            <MenuItem onClick={() => setAddingNewType(true)}>➕ Add New Type</MenuItem>
                        </StyledInput>
                    )}

                    {/* ✅ City Autocomplete */}
                    <StyledInput label="City" value={newLead.city} onChange={(e) => { setNewLead({ ...newLead, city: e.target.value }); handleCityInput(e.target.value); }} />
                    {showSuggestions && addressSuggestions.length > 0 && (
                        <Box sx={{ position: "absolute", backgroundColor: "#fff", zIndex: 1000 }}>
                            {addressSuggestions.map((suggestion, index) => (
                                <Box key={index} onClick={() => handleCitySelect(suggestion.place_id)} sx={{ cursor: "pointer", padding: "5px" }}>
                                    {suggestion.description}
                                </Box>
                            ))}
                        </Box>
                    )}
                    <StyledInput label="State" value={newLead.state} disabled />

                    <Button fullWidth variant="contained" sx={{ mt: 2, backgroundColor: "#12c099" }} onClick={handleAddLead}>
                        Add Lead
                    </Button>
                </ModalContainer>
            </Modal>
        </>
    );
};

export default AddLeadButton;
