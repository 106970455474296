import React, { useEffect, useState, useRef } from "react";
import { Card, CardContent, Typography, Box, Button } from "@mui/material";

const RecurringRevenueComponent = () => {
    const [revenueType, setRevenueType] = useState("MRR");
    const [revenueData, setRevenueData] = useState({ MRR: null, ARR: null });
    const [loading, setLoading] = useState(true);
    const hasFetched = useRef(false);

    useEffect(() => {
        if (hasFetched.current) return;
        hasFetched.current = true;

        const fetchRevenue = async () => {
            try {
                const response = await fetch("https://api.admin.marsreel.com/api/financials/revenue/recurring");
                const data = await response.json();
                setRevenueData(data);
            } catch (error) {
                console.error("Error fetching revenue:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchRevenue();
    }, []);

    const selectedData = revenueData[revenueType];

    const formatNumber = (num) => {
        return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(num);
    };

    return (
        <Card sx={{
            backgroundColor: "#202020",
            color: "#fff",
            padding: "1.5rem",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "350px",
            height: "100%", // ✅ Stretches to match FeedComponent
            flexGrow: 1, // ✅ Forces equal height
        }}>
            <CardContent sx={{
                textAlign: "center",
                flexGrow: 1, // ✅ Allows content to evenly distribute
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between" // ✅ Ensures spacing
            }}>
                <Box>
                    <Typography variant="h5" sx={{ fontWeight: "500", marginBottom: "1rem" }}>
                        {revenueType === "MRR" ? "Monthly Recurring Revenue" : "Annual Recurring Revenue"}
                    </Typography>

                    {loading ? (
                        <Typography variant="h3" sx={{ color: "#12c099", fontWeight: "bold" }}>Loading...</Typography>
                    ) : (
                        <>
                            <Typography variant="h3" sx={{ color: "#12c099", fontWeight: "bold" }}>
                                ${selectedData ? formatNumber(selectedData.totalRevenue) : "0.00"}
                            </Typography>
                            <Typography variant="body2" sx={{ marginTop: "0.5rem", color: "#aaa" }}>
                                Projected Next {revenueType === "MRR" ? "Month" : "Year"}:{" "}
                                <span style={{ color: "#12c099", fontWeight: "bold" }}>
                                    ${selectedData ? formatNumber(selectedData.projectedRevenue) : "0.00"}
                                </span>
                            </Typography>
                            <Typography variant="body2" sx={{ marginTop: "0.3rem", color: selectedData?.changePercentage >= 0 ? "#12c099" : "#e57373" }}>
                                {selectedData?.changePercentage >= 0
                                    ? `+${formatNumber(selectedData.changePercentage)}%`
                                    : `${formatNumber(selectedData?.changePercentage)}%`} from last period
                            </Typography>
                        </>
                    )}
                </Box>

                {/* Centered Buttons for MRR / ARR at the bottom */}
                <Box sx={{ display: "flex", gap: "8px", marginTop: "auto", justifyContent: "center" }}>
                    {["MRR", "ARR"].map((type) => (
                        <Button
                            key={type}
                            variant={revenueType === type ? "contained" : "outlined"}
                            sx={{
                                backgroundColor: revenueType === type ? "#0f8c72" : "transparent",
                                borderColor: "#12c099",
                                color: revenueType === type ? "#fff" : "#12c099",
                                fontSize: "0.85rem",
                                fontWeight: "bold",
                                padding: "6px 12px",
                                borderRadius: "6px",
                                "&:hover": {
                                    backgroundColor: revenueType === type ? "#0e7f68" : "#12c099",
                                    color: "#fff",
                                }
                            }}
                            onClick={() => setRevenueType(type)}
                        >
                            {type === "MRR" ? "Monthly" : "Annualized"}
                        </Button>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
};

export default RecurringRevenueComponent;
