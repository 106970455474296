import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { useParams } from "react-router-dom";
import CoachProfile from "../components/coaches/CoachProfile";
import CoachTransactions from "../components/coaches/CoachTransactions";
import CoachSessions from "../components/coaches/CoachSessions";
import CoachCheckins from "../components/coaches/CoachCheckins"; // ✅ Import CoachCheckins Component

const OverviewContainer = styled(Box)({
    padding: "2rem",
    backgroundColor: "#1c1c1c",
    color: "#fff",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
    paddingBottom: "6rem",
});

const Footer = styled(Box)({
    padding: "1rem",
    textAlign: "center",
    color: "#fff",
    backgroundColor: "transparent",
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
});

const Coach = () => {
    const { firebaseUid } = useParams();
    const [coach, setCoach] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCoach = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/coach/${firebaseUid}`);
                setCoach(response.data);
            } catch (error) {
                console.error("Error fetching coach data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchCoach();
    }, [firebaseUid]);

    if (loading) {
        return <Typography variant="h6" color="inherit">Loading...</Typography>;
    }

    if (!coach) {
        return <Typography variant="h6" color="inherit">Coach not found.</Typography>;
    }

    return (
        <OverviewContainer>
            <Typography variant="h4" gutterBottom>Coach Overview</Typography>

            {/* ✅ Coach Profile */}
            <CoachProfile coach={coach} />

            {/* ✅ Coach Sessions */}
            <CoachSessions firebaseUid={firebaseUid} />

            {/* ✅ Coach Check-ins */}
            <CoachCheckins firebaseUid={firebaseUid} />

            {/* ✅ Transaction History */}
            <CoachTransactions transactions={coach.transactions} />

            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </OverviewContainer>
    );
};

export default Coach;
