import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const TotalScans = ({ totalScans = [] }) => {
    const total = totalScans.length;

    return (
        <Card sx={{ backgroundColor: "#202020", padding: "1.5rem", borderRadius: "12px" }}>
            <CardContent>
                <Typography variant="h5" sx={{ color: "#12c099" }}>Total Scans</Typography>
                <Typography variant="h4" sx={{ color: "#fff" }}>{total}</Typography>
            </CardContent>
        </Card>
    );
};

export default TotalScans;
