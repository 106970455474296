import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Button,
    TextField,
    MenuItem
} from '@mui/material';
import axios from 'axios';

const AddMarketingPartner = ({ open, handleClose, refreshPartners }) => {
    const [newPartner, setNewPartner] = useState({
        name: '',
        primaryContact: '',
        primaryContactEmail: '',
        primaryContactPhoneNumber: '',
        partnerType: '',
        totalSpend: 0,
        rateCardUnitType: '',
        rateCardUnitPrice: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPartner({ ...newPartner, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/create-marketing-partner`, newPartner);
            console.log('Marketing Partner Created:', response.data);
            refreshPartners();
            handleClose();
            setNewPartner({
                name: '',
                primaryContact: '',
                primaryContactEmail: '',
                primaryContactPhoneNumber: '',
                partnerType: '',
                totalSpend: 0,
                rateCardUnitType: '',
                rateCardUnitPrice: ''
            });
        } catch (error) {
            console.error('Failed to create marketing partner:', error);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Add Marketing Partner</DialogTitle>
            <DialogContent>
                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                        label="Partner Name"
                        name="name"
                        value={newPartner.name}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        label="Primary Contact"
                        name="primaryContact"
                        value={newPartner.primaryContact}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        label="Primary Contact Email"
                        name="primaryContactEmail"
                        type="email"
                        value={newPartner.primaryContactEmail}
                        onChange={handleInputChange}
                        required
                    />
                    <TextField
                        label="Primary Contact Phone Number"
                        name="primaryContactPhoneNumber"
                        value={newPartner.primaryContactPhoneNumber}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Partner Type"
                        name="partnerType"
                        value={newPartner.partnerType}
                        onChange={handleInputChange}
                        select
                        required
                    >
                        <MenuItem value="Media">Media</MenuItem>
                        <MenuItem value="Influencer">Influencer</MenuItem>
                        <MenuItem value="Affiliate">Affiliate</MenuItem>
                    </TextField>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} sx={{ color: '#12c099' }}>Cancel</Button>
                <Button onClick={handleSubmit} sx={{ color: '#12c099' }}>Add Partner</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddMarketingPartner;
