import React, { useState } from "react";
import { Card, CardContent, Typography, Box, Button } from "@mui/material";

const GrossMarginComponent = () => {
    const [selectedRange, setSelectedRange] = useState(30);
    const grossMarginData = { 30: "68%", 60: "70%", 90: "72%" }; // Dummy data

    return (
        <Card sx={{
            backgroundColor: "#202020",
            color: "#fff",
            padding: "1.5rem",
            borderRadius: "12px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "180px",
            justifyContent: "center"
        }}>
            <CardContent sx={{ textAlign: "center" }}>
                <Typography variant="h5" sx={{ fontWeight: "500", marginBottom: "1rem" }}>
                    Gross Margin
                </Typography>
                <Typography variant="h3" sx={{ color: "#12c099", fontWeight: "bold" }}>
                    {grossMarginData[selectedRange]}
                </Typography>
                <Box sx={{ display: "flex", gap: "8px", marginTop: "1rem" }}>
                    {[30, 60, 90].map((range) => (
                        <Button
                            key={range}
                            variant={selectedRange === range ? "contained" : "outlined"}
                            sx={{
                                backgroundColor: selectedRange === range ? "#0f8c72" : "transparent", // Darker green when selected
                                borderColor: "#12c099",
                                color: selectedRange === range ? "#fff" : "#12c099", // White text when selected
                                fontSize: "0.85rem",
                                fontWeight: "bold",
                                padding: "6px 12px",
                                borderRadius: "6px",
                                "&:hover": {
                                    backgroundColor: selectedRange === range ? "#0e7f68" : "#12c099",
                                    color: "#fff",
                                }
                            }}
                            onClick={() => setSelectedRange(range)}
                        >
                            {range} Days
                        </Button>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
};

export default GrossMarginComponent;
