import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info'; // ✅ Importing InfoIcon
import LegalInfoModal from './LegalInfoModal'; // ✅ Importing LegalInfoModal
import CallVenueButton from './CallVenueButton';

const PrimaryContact = () => {
    const [openModal, setOpenModal] = useState(false);

    const facility = {
        primaryContact: 'John Doe',
        phone: '(310) 555-1234',
        email: 'contact@downtownsports.com',
    };

    return (
        <Box sx={{ backgroundColor: '#202020', padding: '1.5rem', borderRadius: '12px', mb: 2 }}> {/* ✅ Added mb: 2 */}
            <Typography variant="h6">Primary Contact</Typography>
            <Typography variant="h5">{facility.primaryContact}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', mt: 1 }}>
                <CallVenueButton phone={facility.phone} />
                <InfoIcon sx={{ color: '#12c099', cursor: 'pointer' }} onClick={() => setOpenModal(true)} />
            </Box>
            <Button sx={{ color: '#12c099', mt: 1 }} href={`mailto:${facility.email}`}>
                Email: {facility.email}
            </Button>

            {/* ✅ LegalInfoModal is now correctly placed inside the component */}
            <LegalInfoModal open={openModal} handleClose={() => setOpenModal(false)} />
        </Box>
    );
};

export default PrimaryContact;
