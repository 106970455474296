import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const RateCard = ({ rateCard }) => {
    return (
        <Card sx={{ backgroundColor: "#202020", padding: "1.5rem", borderRadius: "12px" }}>
            <CardContent>
                <Typography variant="h5" sx={{ color: "#12c099" }}>Rate Card</Typography>
                <Typography variant="h6" sx={{ color: "#fff" }}>{rateCard?.unitType || "No Unit Type"}</Typography>
                <Typography variant="h6" sx={{ color: "#fff" }}>
                    Price: ${rateCard?.unitPrice?.toLocaleString() || "N/A"}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default RateCard;
