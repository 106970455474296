import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const UniqueScans = ({ uniqueScans }) => {
    const unique = uniqueScans?.reduce((sum, scans) => sum + scans, 0) || 0;

    return (
        <Card sx={{ backgroundColor: "#202020", padding: "1.5rem", borderRadius: "12px" }}>
            <CardContent>
                <Typography variant="h5" sx={{ color: "#12c099" }}>Unique Scans</Typography>
                <Typography variant="h4" sx={{ color: "#fff" }}>{unique}</Typography>
            </CardContent>
        </Card>
    );
};

export default UniqueScans;
