import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || 'https://api.admin.marsreel.com';

export const initiateCall = async (employeeId, venueId) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/call-venue/${venueId}`, {
            employeeId
        });

        return response.data;
    } catch (error) {
        console.error("Error initiating call:", error.response?.data || error.message);
        throw error;
    }
};
