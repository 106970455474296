import React, { useEffect, useState } from 'react';
import {
    Box,
    Card,
    Typography,
    TextField,
    InputAdornment,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    TableSortLabel,
    Button,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import axios from 'axios';

// Styled Components
const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#202020',
    width: '100%',
    overflowX: 'auto',
    '@media (max-width: 600px)': {
        width: '100%',
    },
});

const CompactTableCell = styled(TableCell)({
    padding: '12px 16px',
    fontSize: '0.9rem',
    '@media (max-width: 768px)': {
        display: 'block',
        width: '100%',
        padding: '12px 0',
        fontSize: '0.8rem',
        textAlign: 'left',
    },
    color: '#fff',
    fontWeight: 'normal', // Consistent with design
});

const CompactTableRow = styled(TableRow)({
    '@media (max-width: 768px)': {
        display: 'block',
        width: '100%',
        marginBottom: '20px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        backgroundColor: '#202020',
        overflow: 'hidden',
    },
});

const SessionsTableComponent = () => {
    const [sessions, setSessions] = useState([]);
    const [contributorsMap, setContributorsMap] = useState({});
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('eventDate');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch sessions and contributors
    useEffect(() => {
        const fetchSessionsAndContributors = async () => {
            try {
                setLoading(true);
                const response = await axios.get('/api/sessions');
                const sessionsData = Array.isArray(response.data) ? response.data : [];
                setSessions(sessionsData);

                // Get unique contributor UIDs from sessions
                const allContributorUids = [...new Set(sessionsData.flatMap(session => session.contributors || []))];

                if (allContributorUids.length > 0) {
                    // Fetch contributor names based on firebaseUid
                    const contributorPromises = allContributorUids.map(async (uid) => {
                        const employeeResponse = await axios.get(`/api/employee/${uid}`);
                        return { uid, name: employeeResponse.data.name };
                    });

                    const contributors = await Promise.all(contributorPromises);
                    const contributorsObj = contributors.reduce((acc, contributor) => {
                        acc[contributor.uid] = contributor.name;
                        return acc;
                    }, {});
                    setContributorsMap(contributorsObj);
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching sessions or contributors:', error);
                setError('Failed to fetch sessions or contributors');
                setLoading(false);
            }
        };
        fetchSessionsAndContributors();
    }, []);

    const handleSortRequest = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const filteredSessions = sessions
        .filter((session) => session.name?.toLowerCase().includes(searchQuery.toLowerCase()))
        .sort((a, b) => {
            if (orderBy === 'eventDate') {
                return order === 'asc'
                    ? new Date(a.eventDate) - new Date(b.eventDate)
                    : new Date(b.eventDate) - new Date(a.eventDate);
            }
            if (orderBy === 'name') {
                return order === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
            }
            return 0;
        });

    return (
        <Card sx={{ backgroundColor: '#202020', color: '#fff', padding: '1rem' }}>
            <Typography variant="h5">My Sessions</Typography>
            <TextField
                variant="outlined"
                placeholder="Search Sessions"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{
                    width: '100%',
                    marginBottom: '1rem',
                    backgroundColor: '#202020',
                    color: '#fff',
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon sx={{ color: '#fff' }} />
                        </InputAdornment>
                    ),
                }}
            />
            <StyledTableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <CompactTableCell>
                                <TableSortLabel
                                    active={orderBy === 'eventDate'}
                                    direction={order}
                                    onClick={() => handleSortRequest('eventDate')}
                                    sx={{ color: '#12c099' }}
                                >
                                    Date
                                </TableSortLabel>
                            </CompactTableCell>
                            <CompactTableCell>
                                <TableSortLabel
                                    active={orderBy === 'name'}
                                    direction={order}
                                    onClick={() => handleSortRequest('name')}
                                    sx={{ color: '#12c099' }}
                                >
                                    Session
                                </TableSortLabel>
                            </CompactTableCell>
                            <CompactTableCell sx={{ color: '#12c099' }}>
                                Contributors
                            </CompactTableCell>
                            <CompactTableCell sx={{ color: '#12c099', textAlign: 'center', verticalAlign: 'middle' }}>
                                Actions
                            </CompactTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <Typography variant="h6" sx={{ color: '#fff' }}>
                                Loading...
                            </Typography>
                        ) : error ? (
                            <Typography variant="h6" sx={{ color: '#ff0000' }}>
                                {error}
                            </Typography>
                        ) : filteredSessions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((session) => (
                            <CompactTableRow key={session._id}>
                                <CompactTableCell>
                                    {session.eventDate ? new Date(session.eventDate).toLocaleDateString() : 'N/A'}
                                </CompactTableCell>
                                <CompactTableCell>{session.name}</CompactTableCell>
                                <CompactTableCell>
                                    {session.contributors?.length
                                        ? session.contributors.map((uid) => contributorsMap[uid] || 'Loading...').join(', ')
                                        : 'No contributors'}
                                </CompactTableCell>
                                <CompactTableCell align="center" sx={{ verticalAlign: 'middle' }}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#12c099',
                                            color: '#fff',
                                            fontSize: '0.8rem',
                                            padding: '4px 12px',
                                        }}
                                        component={Link}
                                        to={`/session/${session._id}`}
                                    >
                                        View Session
                                    </Button>
                                </CompactTableCell>
                            </CompactTableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={filteredSessions.length}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
                    sx={{ backgroundColor: '#202020', color: '#fff' }}
                />
            </StyledTableContainer>
        </Card>
    );
};

export default SessionsTableComponent;
