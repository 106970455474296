import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Paper,
    Grid,
    Button,
    Chip,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Collapse,
    IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ProfitLossModal from '../components/markets/ProfitLossModal';

const locations = [
    {
        id: 1,
        country: 'United States',
        state: 'California',
        city: 'Los Angeles',
        teamLeader: 'John Doe',
        employees: ['Alice Smith', 'Bob Johnson'],
        lat: 34.0522,
        lng: -118.2437,
        coverage: 80,
        neighborhoods: ['Downtown', 'Hollywood'],
    },
    {
        id: 2,
        country: 'United States',
        state: 'California',
        city: 'San Francisco',
        teamLeader: 'Jane Doe',
        employees: ['Charlie Brown', 'Dana White'],
        lat: 37.7749,
        lng: -122.4194,
        coverage: 75,
        neighborhoods: ['Mission District', 'SoMa'],
    },
    // Additional locations...
];

const employeesList = [
    'Alice Smith', 'Bob Johnson', 'Charlie Brown', 'Dana White', 'Eve Black',
    'Frank Green', 'Grace Lee', 'Henry Scott', 'Olivia Harris', 'Noah Martin',
    'Sophia Brown', 'Liam Wilson', 'Isabella Clark', 'Lucas King',
    'Emma Thomas', 'Mason Lee', 'Sophia Roberts', 'James Scott',
    'Andrew White', 'Diana Ross'
];

const countryMapConfig = {
    'United States': { center: { lat: 39.8283, lng: -98.5795 }, zoom: 4 },
    Canada: { center: { lat: 56.1304, lng: -106.3468 }, zoom: 4 },
    Australia: { center: { lat: -25.2744, lng: 133.7751 }, zoom: 4 },
    'United Kingdom': { center: { lat: 54.526, lng: -2.035 }, zoom: 5 },
};

const PageContainer = styled(Box)({
    padding: '2rem',
    backgroundColor: '#1c1c1c',
    minHeight: '100vh',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const Title = styled(Typography)({
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '2rem',
});

const ContentContainer = styled(Box)({
    flexGrow: 1,
    paddingBottom: '3rem', // Adds space for the footer
});

const CustomPaper = styled(Paper)({
    padding: '0.5rem', // Reduced padding for compactness
    backgroundColor: '#282828',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    minHeight: '250px', // Adjusted minHeight for compact design
    borderRadius: '8px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
});

const CoverageBadge = styled(Box)({
    backgroundColor: '#12c099',
    color: '#fff',
    padding: '0.15rem 0.3rem', // Reduced padding for a more compact badge
    borderRadius: '4px',
    fontSize: '0.75rem', // Slightly smaller font size
    fontWeight: 'bold',
    display: 'inline-block',
    marginBottom: '0.2rem', // Reduced space between badge and text
});

const CompactTypography = styled(Typography)({
    marginTop: '0.2rem', // Reduced top margin for compactness
    fontSize: '0.875rem', // Smaller font size for compact look
});

const ButtonGroup = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px', // Reduced gap between buttons for tighter layout
});

const CountryOverview = () => {
    const { country } = useParams();
    const decodedCountry = decodeURIComponent(country);
    const [selectedEmployee, setSelectedEmployee] = useState('');
    const [assigningLocation, setAssigningLocation] = useState(null);
    const [locationData, setLocationData] = useState(locations);
    const [mapConfig, setMapConfig] = useState(countryMapConfig[decodedCountry] || { center: { lat: 20, lng: 0 }, zoom: 2 });
    const [expanded, setExpanded] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);
    const [employeeListOpen, setEmployeeListOpen] = useState({});

    useEffect(() => {
        setMapConfig(countryMapConfig[decodedCountry] || { center: { lat: 20, lng: 0 }, zoom: 2 });
    }, [decodedCountry]);

    const handleAssignEmployee = () => {
        if (selectedEmployee && assigningLocation !== null) {
            const newLocations = locationData.map((location) => {
                if (location.id === assigningLocation) {
                    return { ...location, employees: [...location.employees, selectedEmployee] };
                }
                return location;
            });
            setSelectedEmployee('');
            setAssigningLocation(null);
            setLocationData(newLocations);
        }
    };

    const handleRemoveEmployee = (locationId, employeeName) => {
        const newLocations = locationData.map((location) => {
            if (location.id === locationId) {
                return {
                    ...location,
                    employees: location.employees.filter((employee) => employee !== employeeName),
                };
            }
            return location;
        });
        setLocationData(newLocations);
    };

    const handleExpandClick = (index) => {
        setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    const handleProfitLossClick = (location, neighborhood = null) => {
        setSelectedLocation(location);
        setSelectedNeighborhood(neighborhood);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedNeighborhood(null);
    };

    const handleEmployeeToggle = (index) => {
        setEmployeeListOpen((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    const filteredLocations = locationData.filter(location => location.country === decodedCountry);

    return (
        <PageContainer>
            <ContentContainer>
                <Title>{decodedCountry} | Markets</Title>
                <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '500px', marginBottom: '20px' }}
                        center={mapConfig.center}
                        zoom={mapConfig.zoom}
                    >
                        {filteredLocations.map((location) => (
                            <Marker
                                key={location.id}
                                position={{ lat: location.lat, lng: location.lng }}
                                title={`${location.city}, ${location.state}`}
                            />
                        ))}
                    </GoogleMap>
                </LoadScript>
                <Grid container spacing={2}> {/* Reduced spacing between grid items */}
                    {filteredLocations.map((location, index) => (
                        <Grid item xs={12} md={6} key={location.id}>
                            <CustomPaper>
                                <Box sx={{ marginBottom: '0.5rem' }}> {/* Reduced bottom margin */}
                                    <Typography variant="h6">{location.city}, {location.state}</Typography>
                                    <CoverageBadge>{location.coverage}% Coverage</CoverageBadge>
                                    <Typography variant="body2" sx={{ marginTop: '0.2rem' }}>{location.country}</Typography> {/* Reduced top margin */}
                                </Box>
                                <ButtonGroup>
                                    <Button
                                        onClick={() => handleProfitLossClick(location)}
                                        sx={{ color: '#12c099', alignSelf: 'flex-start' }}
                                    >
                                        Profit/Loss
                                    </Button>
                                    <Button
                                        onClick={() => handleExpandClick(index)}
                                        sx={{ color: '#12c099', alignSelf: 'flex-start' }}
                                        endIcon={expanded[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    >
                                        {expanded[index] ? 'Hide Neighborhoods' : 'Show Neighborhoods'}
                                    </Button>
                                    <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                                        <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: '4px' }}> {/* Reduced gap between chips */}
                                            {location.neighborhoods.map((neighborhood, nIndex) => (
                                                <Chip
                                                    key={nIndex}
                                                    label={neighborhood}
                                                    sx={{ backgroundColor: '#404040', color: '#fff', cursor: 'pointer' }}
                                                    onClick={() => handleProfitLossClick(location, neighborhood)}
                                                />
                                            ))}
                                        </Box>
                                    </Collapse>
                                    <Button
                                        onClick={() => handleEmployeeToggle(index)}
                                        sx={{ color: '#12c099', alignSelf: 'flex-start' }}
                                        endIcon={employeeListOpen[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                    >
                                        {employeeListOpen[index] ? 'Hide Employees' : 'Assign Employees'}
                                    </Button>
                                    <Collapse in={employeeListOpen[index]} timeout="auto" unmountOnExit>
                                        <Box sx={{ mt: 1 }}> {/* Reduced top margin */}
                                            <Typography variant="body2">Team Leader: {location.teamLeader}</Typography>
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px', marginTop: '8px' }}> {/* Reduced gap */}
                                                {location.employees.map((employee, empIndex) => (
                                                    <Chip
                                                        key={empIndex}
                                                        label={employee}
                                                        onDelete={() => handleRemoveEmployee(location.id, employee)}
                                                        deleteIcon={<DeleteIcon />}
                                                        sx={{ backgroundColor: '#404040', color: '#fff' }}
                                                    />
                                                ))}
                                            </Box>
                                            <FormControl fullWidth variant="outlined" sx={{ marginTop: '1rem', backgroundColor: '#202020' }}>
                                                <InputLabel sx={{ color: '#12c099' }}>Assign Employee</InputLabel>
                                                <Select
                                                    value={selectedEmployee}
                                                    onChange={(e) => setSelectedEmployee(e.target.value)}
                                                    label="Assign Employee"
                                                    sx={{
                                                        color: '#fff',
                                                        '& .MuiOutlinedInput-notchedOutline': {
                                                            borderColor: '#12c099',
                                                        },
                                                    }}
                                                >
                                                    {employeesList
                                                        .filter((employee) => !location.employees.includes(employee))
                                                        .map((employee) => (
                                                            <MenuItem key={employee} value={employee}>
                                                                {employee}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>
                                            <Button
                                                variant="contained"
                                                sx={{ backgroundColor: '#12c099', color: '#000', marginTop: '10px' }}
                                                onClick={() => {
                                                    setAssigningLocation(location.id);
                                                    handleAssignEmployee();
                                                }}
                                                startIcon={<AddCircleIcon />}
                                                fullWidth
                                            >
                                                Assign Employee
                                            </Button>
                                        </Box>
                                    </Collapse>
                                </ButtonGroup>
                            </CustomPaper>
                        </Grid>
                    ))}
                </Grid>
            </ContentContainer>
            {selectedLocation && (
                <ProfitLossModal
                    open={modalOpen}
                    onClose={handleModalClose}
                    location={{ ...selectedLocation, neighborhood: selectedNeighborhood }}
                />
            )}
            <Footer />
        </PageContainer>
    );
};

const Footer = () => (
    <Box sx={{ padding: '1rem', textAlign: 'center', color: '#fff', backgroundColor: 'transparent' }}>
        <Typography variant="body2">
            &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
        </Typography>
    </Box>
);

export default CountryOverview;
