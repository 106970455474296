import React, { useState } from 'react';
import { Button, CircularProgress, Box, Typography } from '@mui/material'; // ✅ Added Typography
import { initiateCall } from '../../services/apiService';

const CallVenueButton = ({ employeeId, venueId }) => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');

    const handleCall = async () => {
        setLoading(true);
        setMessage('Connecting...');

        try {
            await initiateCall(employeeId, venueId);
            setMessage('Call initiated successfully!');
        } catch (error) {
            setMessage('Failed to initiate call. Please try again.');
        }

        setLoading(false);
    };

    return (
        <Box>
            <Button
                onClick={handleCall}
                disabled={loading}
                sx={{
                    backgroundColor: '#12c099', // ✅ Green color
                    color: '#fff',
                    '&:hover': { backgroundColor: '#0f9b87' }, // ✅ Slightly darker green on hover
                    padding: '10px 20px',
                    fontSize: '16px',
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                }}
            >
                {loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : 'Call Venue'}
            </Button>
            {message && (
                <Typography sx={{ marginTop: '10px', color: 'green', fontSize: '14px' }}>
                    {message}
                </Typography>
            )}
        </Box>
    );
};

export default CallVenueButton;
