import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import axios from "axios";
import { styled } from "@mui/material/styles"; // ✅ Import styled
import AddLeadButton from "../components/outreach/AddLeadButton";
import SharePipelineButton from "../components/outreach/SharePipelineButton";
import DragDropBoard from "../components/outreach/DragDropBoard"; // ✅ Import new DragDropBoard

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || "https://api.admin.academy.marsreel.com";

const PipelineContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    backgroundColor: "#1c1c1c",
    padding: "16px",
});

const MyPipeline = () => {
    const [firebaseUid, setFirebaseUid] = useState(null);
    const [leads, setLeads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [employee, setEmployee] = useState(null);

    // ✅ Fetch Firebase User
    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                setFirebaseUid(user.uid);
            } else {
                setFirebaseUid(null);
            }
        });

        return () => unsubscribe();
    }, []);

    // ✅ Fetch Leads and Employee Data
    const fetchLeadsAndEmployee = async () => {
        if (!firebaseUid) return;
        try {
            setLoading(true);

            const leadsResponse = await axios.get(`${API_BASE_URL}/api/outreach/employee/${firebaseUid}`);
            if (!Array.isArray(leadsResponse.data)) {
                console.error("Unexpected leads response format:", leadsResponse.data);
                return;
            }
            setLeads(leadsResponse.data);

            const employeeResponse = await axios.get(`${API_BASE_URL}/api/employee/${firebaseUid}`);
            setEmployee(employeeResponse.data || null);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (firebaseUid) fetchLeadsAndEmployee();
    }, [firebaseUid]);

    return (
        <PipelineContainer>
            <Typography variant="h5" sx={{ color: "#fff", marginBottom: "12px", textAlign: "center" }}>
                My Pipeline
            </Typography>

            {loading ? (
                <CircularProgress sx={{ color: "#fff", display: "block", margin: "auto", marginTop: "20px" }} />
            ) : (
                <DragDropBoard leads={leads} setLeads={setLeads} refreshLeads={fetchLeadsAndEmployee} employee={employee} />
            )}

            <Box sx={{ display: "flex", justifyContent: "flex-end", position: "fixed", bottom: 16, right: 16, gap: 1 }}>
                <AddLeadButton firebaseUid={firebaseUid} onAdd={fetchLeadsAndEmployee} />
                {firebaseUid && <SharePipelineButton firebaseUid={firebaseUid} refreshLeads={fetchLeadsAndEmployee} />}
            </Box>
        </PipelineContainer>
    );
};

export default MyPipeline;
