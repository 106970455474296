import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const FirstScanDate = ({ firstScanDates }) => {
    const formattedDate = firstScanDates?.length ? new Date(firstScanDates[0]).toLocaleDateString() : "N/A";

    return (
        <Card sx={{ backgroundColor: "#202020", padding: "1.5rem", borderRadius: "12px" }}>
            <CardContent>
                <Typography variant="h5" sx={{ color: "#12c099" }}>First Scan Date</Typography>
                <Typography variant="h4" sx={{ color: "#fff" }}>{formattedDate}</Typography>
            </CardContent>
        </Card>
    );
};

export default FirstScanDate;
