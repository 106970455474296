import React, { useEffect, useState, useCallback } from "react";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Typography,
    Pagination,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import debounce from "lodash/debounce";
import Footer from "../components/Footer/Footer";

// Styles
const StyledTableContainer = styled(TableContainer)({
    backgroundColor: "#202020",
    borderRadius: "12px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
    overflowX: "auto",
});

const CompactTableCell = styled(TableCell)({
    padding: "8px 12px",
    fontSize: "0.875rem",
    color: "#fff",
    whiteSpace: "nowrap",
});

const PaginationBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    "& .MuiPaginationItem-root": {
        color: "#12c099",
    },
});

const SearchBar = styled(TextField)({
    marginBottom: "20px",
    width: "100%",
    backgroundColor: "#303030",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
        color: "#fff",
        fontSize: "0.9rem",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#12c099",
    },
    "& .MuiInputLabel-root": {
        color: "#12c099",
        fontSize: "0.9rem",
    },
});

// ✅ Fallback data for problematic zip codes
const fallbackZipCodeData = {
    "90056": { city: "Ladera Heights", state: "CA", country: "USA" },
};

const Leads = () => {
    const [leads, setLeads] = useState([]);
    const [geocodedData, setGeocodedData] = useState({});
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [leadToDelete, setLeadToDelete] = useState(null);
    const [totalLeads, setTotalLeads] = useState(0);
    const rowsPerPage = 25;

    useEffect(() => {
        fetchLeads();
    }, []);

    const fetchLeads = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/leads`
            );
            setLeads(response.data);
            setTotalLeads(response.data.length);
            setPage(1);
            fetchGeocodedData(response.data);
        } catch (error) {
            console.error("Error fetching leads:", error);
        } finally {
            setLoading(false);
        }
    };

    // ✅ Fetch geocoding data ONCE for all leads
    const fetchGeocodedData = async (allLeads) => {
        const data = {};
        for (const lead of allLeads) {
            try {
                if (fallbackZipCodeData[lead.zipcode]) {
                    data[lead._id] = fallbackZipCodeData[lead.zipcode];
                    continue;
                }

                const geoResponse = await axios.get(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${lead.zipcode}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
                );

                if (geoResponse.data.results.length > 0) {
                    const addressComponents =
                        geoResponse.data.results[0].address_components;
                    const city =
                        addressComponents.find((comp) =>
                            comp.types.includes("locality")
                        )?.long_name ||
                        addressComponents.find((comp) =>
                            comp.types.includes("neighborhood")
                        )?.long_name ||
                        "N/A";
                    const state =
                        addressComponents.find((comp) =>
                            comp.types.includes("administrative_area_level_1")
                        )?.short_name || "N/A";
                    const country =
                        addressComponents.find((comp) =>
                            comp.types.includes("country")
                        )?.long_name || "N/A";

                    data[lead._id] = { city, state, country };
                } else {
                    console.warn(`No results from Google API for ${lead.zipcode}`);
                    data[lead._id] = { city: "N/A", state: "N/A", country: "N/A" };
                }
            } catch (error) {
                console.error("Geocoding failed:", error);
                data[lead._id] = { city: "N/A", state: "N/A", country: "N/A" };
            }
        }
        setGeocodedData(data);
    };

    const handleDeleteClick = (lead) => {
        setLeadToDelete(lead);
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = async () => {
        if (!leadToDelete) return;

        try {
            await axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/api/leads/${leadToDelete._id}`
            );
            setLeads((prevLeads) =>
                prevLeads.filter((lead) => lead._id !== leadToDelete._id)
            );
            setTotalLeads((prev) => prev - 1);
            setShowDeleteModal(false);
            setLeadToDelete(null);
        } catch (error) {
            console.error("Error deleting lead:", error);
        }
    };

    const handleCloseModal = () => {
        setShowDeleteModal(false);
        setLeadToDelete(null);
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        debouncedSearch();
    };

    const debouncedSearch = useCallback(
        debounce(() => {
            setPage(1);
        }, 300),
        []
    );

    const filteredLeads = leads.filter((lead) => {
        const search = searchTerm.toLowerCase();
        return (
            lead.name.toLowerCase().includes(search) ||
            lead.email.toLowerCase().includes(search) ||
            lead.phone.toLowerCase().includes(search) ||
            lead.zipcode.toLowerCase().includes(search)
        );
    });

    const paginatedLeads = filteredLeads.slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
    );

    return (
        <Box sx={{ padding: "1rem", backgroundColor: "#1c1c1c", minHeight: "100vh", color: "#fff" }}>
            <Typography variant="h5" gutterBottom>Leads ({totalLeads})</Typography>

            <SearchBar
                variant="outlined"
                placeholder="Search by name, email, phone, zip code, city, state, or country"
                value={searchTerm}
                onChange={handleSearchChange}
            />

            <StyledTableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {["Name", "Email", "Phone", "Zip Code", "City", "State", "Country", "Actions"].map((header) => (
                                <CompactTableCell key={header}>{header}</CompactTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedLeads.map((lead) => (
                            <TableRow key={lead._id}>
                                <CompactTableCell>{lead.name}</CompactTableCell>
                                <CompactTableCell>{lead.email}</CompactTableCell>
                                <CompactTableCell>{lead.phone}</CompactTableCell>
                                <CompactTableCell>{lead.zipcode}</CompactTableCell>
                                <CompactTableCell>{geocodedData[lead._id]?.city || "Loading..."}</CompactTableCell>
                                <CompactTableCell>{geocodedData[lead._id]?.state || "Loading..."}</CompactTableCell>
                                <CompactTableCell>{geocodedData[lead._id]?.country || "Loading..."}</CompactTableCell>
                                <CompactTableCell>
                                    <IconButton onClick={() => handleDeleteClick(lead)} sx={{ color: "#ff1744" }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </CompactTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>

            <PaginationBox>
                <Pagination count={Math.ceil(filteredLeads.length / rowsPerPage)} page={page} onChange={(event, value) => setPage(value)} />
            </PaginationBox>

            {/* ✅ Delete Confirmation Modal */}
            <Dialog open={showDeleteModal} onClose={handleCloseModal}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent><DialogContentText>Are you sure you want to delete {leadToDelete?.name}?</DialogContentText></DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button onClick={handleConfirmDelete} color="error">Delete</Button>
                </DialogActions>
            </Dialog>
            <Footer />
        </Box>
    );
};

export default Leads;
