import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Modal, Button, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // ✅ Verified Indicator
import WarningAmberIcon from "@mui/icons-material/WarningAmber"; // ✅ NOT Verified Indicator
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead"; // ✅ Send Email Icon
import axios from "axios";
import VerifyEmailModal from "./VerifyEmailModal";
import EmailPreviewModal from "./EmailPreviewModal";
import UpdateStatusModal from "./updateStatusModal"; // ✅ Import the modal

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || "https://api.admin.academy.marsreel.com";

// ✅ Styled Component for Lead Card
const LeadCardContainer = styled(Box)({
    padding: "10px",
    backgroundColor: "#2c2c2c",
    color: "#fff",
    marginBottom: "8px",
    borderRadius: "6px",
    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.3)",
    fontSize: "0.9rem",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    cursor: "grab",
    transition: "all 0.2s ease",
});

// ✅ Styled Badge for Status Indicator
const StatusBadge = styled(Box)(({ status }) => ({
    position: "absolute",
    bottom: 8,
    right: 8,
    backgroundColor: status === "Interested" ? "#12c099" : "#FF3B3B",
    color: "#fff",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "0.75rem",
    fontWeight: "bold",
    textTransform: "uppercase",
}));

const Card = ({ lead, refreshLeads, employee }) => {
    const [openDelete, setOpenDelete] = useState(false);
    const [openVerifyModal, setOpenVerifyModal] = useState(false);
    const [openEmailPreview, setOpenEmailPreview] = useState(false);
    const [openStatusModal, setOpenStatusModal] = useState(false); // ✅ State for status modal
    const [isVerified, setIsVerified] = useState(employee?.isSendGridVerified || false);

    console.log("🔹 Lead Object:", lead);

    const leadId = lead?._id ? String(lead._id) : null;

    useEffect(() => {
        if (employee?.isSendGridVerified !== undefined) {
            setIsVerified(employee.isSendGridVerified);
        }
    }, [employee?.isSendGridVerified]);

    // ✅ Trigger status modal when lead moves to "Closed" and has no status
    useEffect(() => {
        if (lead.stage === "Closed" && !lead.status) {
            setOpenStatusModal(true);
        }
    }, [lead.stage, lead.status]);

    if (!leadId) {
        console.error("❌ ERROR: Lead has no _id!", lead);
        return null;
    }

    const deleteLead = async () => {
        try {
            await axios.delete(`${API_BASE_URL}/api/outreach/delete/${leadId}`);
            refreshLeads();
            setOpenDelete(false);
        } catch (error) {
            console.error("Error deleting lead:", error);
        }
    };

    return (
        <>
            <LeadCardContainer>
                <Typography variant="body2">{lead.name}</Typography>
                <Typography variant="caption" sx={{ color: "#bbb" }}>{lead.email}</Typography>
                <Typography variant="caption" sx={{ color: "#00FF99" }}>
                    {lead.organization} - {lead.leadType}
                </Typography>
                <Typography variant="caption" sx={{ color: "#bbb" }}>
                    📍 {lead.city ? `${lead.city}, ${lead.state}` : "Location N/A"}
                </Typography>
                <Typography variant="caption" sx={{ color: "#bbb" }}>
                    📞 {lead.phone || "N/A"}
                </Typography>

                {/* ✅ Improved Verification Logic: Warning for NOT Verified, Check for Verified */}
                {isVerified ? (
                    <CheckCircleIcon sx={{ color: "#12c099", position: "absolute", top: 6, right: 64 }} />
                ) : (
                    <IconButton
                        sx={{ position: "absolute", top: 6, right: 64, color: "#FFA726" }} // ✅ Stronger Orange
                        onClick={() => setOpenVerifyModal(true)}
                        size="small"
                    >
                        <WarningAmberIcon fontSize="small" />
                    </IconButton>
                )}

                {/* ✅ Send Email Icon (Mailbox) with Tooltip */}
                <Tooltip title={isVerified ? "Send Email" : "Verify your email first"}>
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: 6,
                            right: 32,
                            color: isVerified ? "#12c099" : "#ccc", // ✅ Brighter disabled color
                            opacity: 1, // ✅ Ensures full visibility
                            cursor: isVerified ? "pointer" : "not-allowed", // ✅ More obvious it's inactive
                            "&:hover": {
                                filter: isVerified ? "brightness(1.2)" : "none", // ✅ Slight brightness when hovered
                            },
                        }}
                        onClick={() => isVerified && setOpenEmailPreview(true)}
                        size="small"
                        disabled={!isVerified}
                    >
                        <MarkEmailReadIcon fontSize="small" />
                    </IconButton>
                </Tooltip>

                {/* ✅ Delete Icon */}
                <IconButton
                    sx={{ position: "absolute", top: 6, right: 4, color: "#FF3B3B" }}
                    onClick={() => setOpenDelete(true)}
                    size="small"
                >
                    <DeleteIcon fontSize="small" />
                </IconButton>

                {/* ✅ Status Badge (Only in 'Closed' Stage) */}
                {lead.stage === "Closed" && lead.status && (
                    <StatusBadge status={lead.status}>
                        {lead.status}
                    </StatusBadge>
                )}
            </LeadCardContainer>

            {/* ✅ Status Update Modal (Triggers when moving to "Closed" without a status) */}
            <UpdateStatusModal
                open={openStatusModal}
                onClose={() => setOpenStatusModal(false)}
                leadId={leadId}
                refreshLeads={refreshLeads}
            />

            {/* ✅ Delete Confirmation Modal */}
            <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
                <Box sx={{
                    padding: "20px",
                    backgroundColor: "#202020",
                    width: "320px",
                    margin: "auto",
                    marginTop: "20vh",
                    borderRadius: "12px",
                    boxShadow: "0px 4px 10px rgba(255, 255, 255, 0.2)",
                    color: "#fff",
                    textAlign: "center",
                }}>
                    <Typography variant="h6">Delete Lead?</Typography>
                    <Typography variant="body2" sx={{ marginBottom: "12px" }}>
                        Are you sure you want to delete {lead.name}?
                    </Typography>
                    <Button variant="contained" sx={{ backgroundColor: "#FF3B3B", marginRight: "8px" }} onClick={deleteLead}>
                        Delete
                    </Button>
                    <Button variant="outlined" sx={{ color: "#00FF99", borderColor: "#00FF99" }} onClick={() => setOpenDelete(false)}>
                        Cancel
                    </Button>
                </Box>
            </Modal>

            {/* ✅ Email Verification Modal */}
            <VerifyEmailModal
                open={openVerifyModal}
                onClose={() => setOpenVerifyModal(false)}
                employee={employee}
                onVerificationSent={() => setIsVerified(true)}
            />

            {/* ✅ Email Preview Modal (Only Works if Verified) */}
            <EmailPreviewModal
                open={openEmailPreview}
                onClose={() => setOpenEmailPreview(false)}
                lead={lead}
                employee={employee}
                isVerified={isVerified}
            />
        </>
    );
};

export default Card;
