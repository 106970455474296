import React, { useState, useEffect } from "react";
import {
    Modal, Box, Typography, Button, RadioGroup,
    FormControlLabel, Radio, TextField, Snackbar, Alert
} from "@mui/material";
import { getAuth } from "firebase/auth"; // Get authenticated user
import axios from "axios";
import CancellationPreviewModal from ".//CancellationPreviewModal";
import ConfirmDeleteModal from ".//ConfirmDeleteModal";

const CancelSessionModal = ({ open, onClose, sessionId, createdBy, onCancelSuccess }) => {
    const [reason, setReason] = useState("");
    const [customReason, setCustomReason] = useState(""); // Always visible
    const [confirmText, setConfirmText] = useState("");
    const [errorOpen, setErrorOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [employeeUid, setEmployeeUid] = useState(null);
    const [caseError, setCaseError] = useState(false); // Tracks case sensitivity error
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [cancellationPreview, setCancellationPreview] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false); // Track delete modal state


    const correctText = "CANCEL"; // Required confirmation text

    const reasons = [
        "Weather event",
        "Scheduling conflict",
        "This sessions was created for testing purposes",
        "Venue-related conflict",
        "Other"
    ];


    useEffect(() => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            setEmployeeUid(user.uid); // Capture logged-in employee UID
        }
    }, []);

// 🔄 Reset `customReason` when the selected reason is NOT "Other"
    useEffect(() => {
        if (reason !== "Other") {
            setCustomReason(""); // Ensures the input is cleared when switching options
        }
    }, [reason]);

    const handleCancel = async () => {
        if (!reason.trim() || confirmText.trim() !== correctText) {
            setErrorOpen(true);
            return;
        }

        const fullReason = customReason.trim()
            ? `${reason}. Additional details: ${customReason.trim()}`
            : reason;

        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/api/session/${sessionId}/cancel-preview`,
                { params: { reason: fullReason } }
            );
            console.log("Cancellation Preview Response:", response.data);

            if (response.data?.smsPreview && response.data?.emailPreview) {
                console.log("Passing to CancellationPreviewModal - CreatedBy:", createdBy);

                setCancellationPreview({
                    ...response.data,
                    cancellationReason: fullReason,
                    createdBy: createdBy // ✅ Make sure createdBy is included
                });
                setPreviewModalOpen(true);
                setErrorOpen(false);
            } else {
                throw new Error("Invalid response from cancellation preview API.");
            }
        } catch (error) {
            console.error("Error fetching cancellation preview:", error);

            if (error.response?.status === 400) {
                setDeleteModalOpen(true);
            } else {
                setErrorOpen(true);
            }
        } finally {
            setLoading(false);
        }
    };






    const handleConfirmCancel = async () => {
        setPreviewModalOpen(false);
        onCancelSuccess(sessionId); // Proceed with final cancellation logic
    };

    // 🚨 Display a visible error message when incorrect text is entered
    useEffect(() => {
        if (confirmText && confirmText !== correctText) {
            setCaseError(true);
        } else {
            setCaseError(false);
        }
    }, [confirmText]);

    return (
        <>
            <Modal open={open} onClose={onClose} aria-labelledby="cancel-session-modal" disableEnforceFocus>
                <Box sx={{
                    width: 450,
                    bgcolor: "#202020",
                    color: "#fff",
                    p: 3,
                    borderRadius: 2,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                }}>
                    <Typography variant="h6" gutterBottom sx={{ color: "#fff", fontWeight: "bold" }}>
                        Please provide context for cancellation.
                    </Typography>

                    {/* Radio Button Options */}
                    <RadioGroup
                        value={reason}
                        onChange={(e) => {
                            console.log("Selected Reason:", e.target.value); // ✅ Debugging log
                            setReason(e.target.value);
                        }}
                        sx={{ mb: 2 }}
                    >                        {reasons.map((option) => (
                            <FormControlLabel key={option} value={option} control={<Radio sx={{ color: "#12c099" }} />} label={option} />
                        ))}
                    </RadioGroup>

                    {/* Required Indicator if 'Other' is Selected */}
                    {/* Required Indicator if 'Other' is Selected */}
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={customReason}
                        onChange={(e) => setCustomReason(e.target.value)}
                        placeholder="Provide more details..."
                        multiline
                        rows={4} // Bigger input field for multiple lines
                        sx={{
                            bgcolor: "#282828",
                            color: "#fff",
                            mt: 2,
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: reason === "Other" && !customReason.trim() ? "#e57373" : "#12c099"
                                }, // Red border if empty when "Other" is selected
                                "&:hover fieldset": { borderColor: "#12c099" },
                                "&.Mui-focused fieldset": { borderColor: "#12c099" },
                            },
                            "& .MuiInputBase-input": {
                                color: "#fff", // 🔥 Bright text inside field for readability
                                // ❌ Remove fontWeight: "bold" so text isn't bold
                            }
                        }}
                        error={reason === "Other" && !customReason.trim()} // Show error styling if empty
                        helperText={reason === "Other" && !customReason.trim() ? "This field is required for 'Other' reason." : ""}
                    />


                    {/* Confirmation TextField */}
                    <TextField
                        fullWidth
                        variant="outlined"
                        value={confirmText}
                        onChange={(e) => setConfirmText(e.target.value)}
                        placeholder={`Type "${correctText}" to confirm`}
                        sx={{
                            bgcolor: "#282828",
                            color: "#fff",
                            mt: 2,
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": { borderColor: "#12c099" },
                                "&:hover fieldset": { borderColor: "#12c099" },
                                "&.Mui-focused fieldset": { borderColor: "#12c099" },
                            },
                            "& .MuiInputBase-input": {
                                color: "#fff", // 🔥 Bright text inside field for readability
                                fontWeight: "bold"
                            }
                        }}
                    />

                    {/* Case Sensitivity Error Display */}
                    {caseError && (
                        <Box sx={{
                            bgcolor: "#e57373",
                            color: "#fff",
                            p: 1,
                            borderRadius: 1,
                            textAlign: "center",
                            mt: 2
                        }}>
                            ⚠️ Case-Sensitive Warning: Please type "CANCEL" in all caps.
                        </Box>
                    )}

                    <ConfirmDeleteModal
                        open={deleteModalOpen}
                        onClose={() => setDeleteModalOpen(false)}
                        sessionId={sessionId}
                        onDeleteSuccess={(sessionId) => {
                            onCancelSuccess(sessionId); // Remove the deleted sessions from the UI
                            setDeleteModalOpen(false); // Close modal after deletion
                        }}
                    />


                    <CancellationPreviewModal
                        open={previewModalOpen}
                        onClose={() => setPreviewModalOpen(false)}
                        sessionId={sessionId}
                        initialPreviewData={cancellationPreview}
                        onConfirmCancel={handleConfirmCancel}
                        cancellationReason={reason === "Other" && customReason ? `${reason}: ${customReason}` : reason}
                        createdBy={createdBy}
                    />



                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
                        <Button
                            variant="contained"
                            sx={{ backgroundColor: "#12c099", "&:hover": { backgroundColor: "#0fa888" } }}
                            onClick={onClose}
                            disabled={loading}
                        >
                            Close
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "#e57373",
                                "&:hover": { backgroundColor: "#ef5350" }
                            }}
                            onClick={handleCancel}
                            disabled={loading || confirmText !== correctText}
                        >
                            {loading ? "Canceling..." : "Confirm Cancel"}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Snackbar
                open={errorOpen}
                autoHideDuration={2000}
                onClose={() => setErrorOpen(false)}
            >
                <Alert severity="warning" sx={{ bgcolor: "#e57373", color: "#fff" }}>
                    Please complete all required fields correctly.
                </Alert>
            </Snackbar>

        </>
    );
};

export default CancelSessionModal;
