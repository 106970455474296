import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    LinearProgress,
    ToggleButton,
    ToggleButtonGroup,
    Box
} from "@mui/material";
import axios from "axios";

const ProgressComponent = ({ firebaseUid }) => {
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(true);
    const [view, setView] = useState("weekly"); // Default to weekly progress

    useEffect(() => {
        const fetchProgress = async () => {
            if (!firebaseUid) {
                console.warn("firebaseUid is missing, skipping API call.");
                setLoading(false);
                return;
            }

            setLoading(true);

            try {
                console.log(`Fetching ${view} progress for firebaseUid:`, firebaseUid);
                const response = await axios.get(`/api/quotas/progress?firebaseUid=${firebaseUid}`);

                if (response.data) {
                    console.log("API Response Data:", response.data); // Debugging Log

                    const weeklyProgress = parseFloat(response.data.weeklyProgressPercentage) || 0;
                    const monthlyProgress = parseFloat(response.data.monthlyProgressPercentage) || 0;

                    const progressValue = view === "weekly" ? weeklyProgress : monthlyProgress;

                    setProgress(progressValue);
                } else {
                    console.error("Empty response from API");
                    setProgress(0);
                }
            } catch (error) {
                console.error("Error fetching progress:", error);
                setProgress(0);
            } finally {
                setLoading(false);
            }
        };

        fetchProgress();
    }, [view, firebaseUid]);

    return (
        <Card sx={{ backgroundColor: "#202020", color: "#fff", padding: "1rem" }}>
            <CardContent>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Progress This {view === "weekly" ? "Week" : "Month"}
                </Typography>

                {/* Custom Toggle Group with Outlined Style */}
                <Box sx={{ display: "flex", gap: "10px", mb: 2 }}>
                    <ToggleButton
                        value="weekly"
                        selected={view === "weekly"}
                        onClick={() => setView("weekly")}
                        sx={{
                            borderColor: "#12c099",
                            color: view === "weekly" ? "#fff" : "#12c099",
                            backgroundColor: view === "weekly" ? "#12c099" : "transparent",
                            textTransform: "none",
                            fontSize: "1rem",
                            padding: "6px 12px",
                            minWidth: "120px",
                            "&:hover": {
                                backgroundColor: "#0ea17f",
                                color: "#fff"
                            }
                        }}
                    >
                        Weekly
                    </ToggleButton>
                    <ToggleButton
                        value="monthly"
                        selected={view === "monthly"}
                        onClick={() => setView("monthly")}
                        sx={{
                            borderColor: "#12c099",
                            color: view === "monthly" ? "#fff" : "#12c099",
                            backgroundColor: view === "monthly" ? "#12c099" : "transparent",
                            textTransform: "none",
                            fontSize: "1rem",
                            padding: "6px 12px",
                            minWidth: "120px",
                            "&:hover": {
                                backgroundColor: "#0ea17f",
                                color: "#fff"
                            }
                        }}
                    >
                        Monthly
                    </ToggleButton>
                </Box>

                {loading ? (
                    <Typography variant="body1">Loading progress...</Typography>
                ) : (
                    <>
                        <Typography variant="body1">
                            You have organized {progress}% of your sessions this{" "}
                            <strong>{view === "weekly" ? "week" : "month"}</strong>.
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                            sx={{
                                height: "10px",
                                borderRadius: "5px",
                                backgroundColor: "#404040",
                                "& .MuiLinearProgress-bar": {
                                    backgroundColor: "#12c099"
                                },
                                mt: 1
                            }}
                        />
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default ProgressComponent;
