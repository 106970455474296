import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#282828',
    color: '#fff',
    marginBottom: '2rem',
});

const SectionHeader = styled(Typography)({
    marginBottom: '1rem',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    color: '#12c099',
    display: 'flex',
    alignItems: 'center',
});

const StyledTableCell = styled(TableCell)({
    color: '#fff',
    fontSize: '1rem',
    fontWeight: 'bold',
});

const ProfitLoss = ({ profitLossData }) => {
    console.log("🔍 Debug: profitLossData received", profitLossData);

    const isEmpty = !profitLossData || typeof profitLossData !== 'object' || Object.keys(profitLossData).length === 0;

    return (
        <>
            <SectionHeader variant="h4">Profit/Loss Breakdown</SectionHeader>

            <StyledTableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Category</StyledTableCell>
                            <StyledTableCell align="right">Amount ($)</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isEmpty ? (
                            [
                                { category: "Coach Payments", amount: Array.isArray(profitLossData.coachPayments) ? profitLossData.coachPayments.reduce((sum, p) => sum + (p.paid || 0), 0) : 0 },
                                { category: "Site Manager Payments", amount: Array.isArray(profitLossData.siteManagerPayments) ? profitLossData.siteManagerPayments.reduce((sum, p) => sum + (p.paid || 0), 0) : 0 },
                                { category: "Venue Fees", amount: Array.isArray(profitLossData.venueFees) ? profitLossData.venueFees.reduce((sum, p) => sum + (p.paid || 0), 0) : 0 },
                                { category: "Insurance Fees", amount: profitLossData.insuranceFees?.paid || 0 },
                                { category: "Other Fees", amount: Array.isArray(profitLossData.otherFees) ? profitLossData.otherFees.reduce((sum, fee) => sum + (fee.amount || 0), 0) : 0 },
                                { category: "Total Revenue", amount: profitLossData.sessionRevenue?.totalRevenue || 0 },
                                { category: "Projected Profit", amount: profitLossData.projectedProfit || 0 },
                                { category: "Actual Profit", amount: profitLossData.actualProfit || 0 },
                            ].map((entry, index) => (
                                <TableRow key={index}>
                                    <StyledTableCell>{entry.category}</StyledTableCell>
                                    <StyledTableCell align="right">{entry.amount.toLocaleString()}</StyledTableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <StyledTableCell colSpan={2} align="center" sx={{ color: "red", fontWeight: "bold" }}>
                                    No financial data available.
                                </StyledTableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </>
    );
};

export default ProfitLoss;
