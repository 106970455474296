import React, { useState } from 'react';
import { Modal, Box, Typography, Button, TextField, MenuItem } from '@mui/material';
import axios from 'axios';

const paymentMethods = [
    { label: 'Bank Transfer (Plaid)', value: 'plaid' },
    { label: 'Credit Card (Stripe)', value: 'stripe' },
    { label: 'PayPal', value: 'paypal' },
];

const PaymentModal = ({ open, handleClose, details, selectedPerson }) => {
    const [paymentMethod, setPaymentMethod] = useState(paymentMethods[0].value);
    const [confirming, setConfirming] = useState(false);

    const handlePaymentMethodChange = (event) => {
        setPaymentMethod(event.target.value);
    };

    const handlePayment = async () => {
        setConfirming(true);

        try {
            if (paymentMethod === 'stripe') {
                // ✅ Stripe Payout Logic
                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/payments/stripe/create-payout`, {
                    amount: selectedPerson.owed,
                    stripeAccountId: selectedPerson.stripeAccountId,  // Ensure this is passed
                    description: `Payout to ${selectedPerson.name}`,
                });

                alert('Payment successful with Stripe');
            }

            else if (paymentMethod === 'paypal') {
                // ✅ PayPal Payout Logic (Include recipient email)
                const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/payments/paypal/create-payment`, {
                    amount: selectedPerson.owed,
                    currency: 'USD',
                    description: `Payout to ${selectedPerson.name}`,
                    recipientEmail: selectedPerson.paypalEmail,  // Ensure PayPal email is passed
                    returnUrl: `${window.location.origin}/paypal-success`,
                    cancelUrl: `${window.location.origin}/paypal-cancel`,
                });

                // Redirect user to PayPal for approval
                window.location.href = data.approvalUrl;
            }

            else if (paymentMethod === 'plaid') {
                // ✅ Plaid Payment Logic (Ensure correct token handling)
                const { data: accessTokenResponse } = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/payments/plaid/get-access-token`, {
                    public_token: selectedPerson.plaidPublicToken,  // Ensure this is fetched first
                });

                await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/payments/plaid/create-transfer`, {
                    amount: selectedPerson.owed,
                    plaidAccountId: selectedPerson.plaidAccountId, // Ensure this is passed
                    accessToken: accessTokenResponse.accessToken,
                });

                alert('Payment successful with Plaid');
            }
        } catch (error) {
            alert('Payment failed: ' + error.message);
        }

        setConfirming(false);
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
            }}>
                <Typography variant="h6">Payment Details</Typography>
                <Typography sx={{ mt: 2 }}>{details}</Typography>
                <TextField
                    select
                    label="Payment Method"
                    value={paymentMethod}
                    onChange={handlePaymentMethodChange}
                    fullWidth
                    sx={{ mt: 3 }}
                >
                    {paymentMethods.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleClose} sx={{ mr: 2 }} disabled={confirming}>Cancel</Button>
                    <Button variant="contained" color="primary" onClick={handlePayment} disabled={confirming}>
                        {confirming ? 'Processing...' : 'Proceed to Pay'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default PaymentModal;
