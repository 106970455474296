import React, { useState } from "react";
import { Modal, Box, Typography, Button, TextField } from "@mui/material";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || "https://api.admin.academy.marsreel.com";

const EmailPreviewModal = ({ open, onClose, lead, employee, isVerified }) => {
    const [subject, setSubject] = useState("Invitation to Join Mars Reel® Academy - Free Session");
    const [message, setMessage] = useState(`
        Hi ${lead?.name?.split(" ")[0] || "Coach"},
        
        My name is ${employee?.name || "Unknown"}, and I’m part of Mars Reel® Academy—a basketball development platform.
        
        We're offering a FREE basketball clinic for first-time visitors in your area. Would love for you to check it out.
        
        Register here: academy.marsreel.com

        Let me know if you have any questions!

        Best,
        ${employee?.name || ""}
        ${employee?.title || ""}
    `);

    const sendEmail = async () => {
        if (!isVerified) {
            alert("Your email must be verified before sending!");
            return;
        }

        if (!lead?._id || !employee?._id) {
            console.error("❌ Lead or employee ID is missing.");
            return;
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/api/outreach/send-email`, {
                leadId: lead._id,
                employeeId: employee._id,
                customMessage: message,
                customSubject: subject,
            });

            if (response.data.success) {
                alert("Email sent successfully!");
                onClose();
            } else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error("Error sending email:", error);
            alert("Failed to send email.");
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ padding: "20px", backgroundColor: "#202020", width: "400px", margin: "auto", marginTop: "20vh", borderRadius: "12px", color: "#fff" }}>
                <Typography variant="h6">Preview Email</Typography>

                <TextField fullWidth margin="normal" label="Subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
                <TextField fullWidth multiline rows={6} margin="normal" label="Message" value={message} onChange={(e) => setMessage(e.target.value)} />

                <Button variant="contained" color="primary" onClick={sendEmail} sx={{ marginTop: "10px" }}>
                    Send Email
                </Button>
            </Box>
        </Modal>
    );
};

export default EmailPreviewModal;
