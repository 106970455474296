import React, { useState } from "react";
import { Modal, Box, Typography, Button, CircularProgress } from "@mui/material";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BACKEND_URL || "https://api.admin.academy.marsreel.com";

const UpdateStatusModal = ({ open, onClose, leadId, refreshLeads }) => {
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);

    const updateStatus = async () => {
        if (!selectedStatus) return;

        setLoading(true);
        try {
            await axios.put(`${API_BASE_URL}/api/outreach/update-status/${leadId}`, { status: selectedStatus });
            refreshLeads();
            onClose();
        } catch (error) {
            console.error("Error updating status:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="update-status-modal" disableEnforceFocus>
            <Box sx={{
                width: 350,
                bgcolor: "#252525",
                color: "#fff",
                p: 3,
                borderRadius: 2,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                textAlign: "center",
            }}>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
                    Update Lead Status
                </Typography>

                {/* 🆕 Status Selection Buttons */}
                <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 3 }}>
                    <Button
                        variant={selectedStatus === "Interested" ? "contained" : "outlined"}
                        sx={{
                            borderColor: "#12c099",
                            color: "#12c099",
                            "&:hover": { backgroundColor: "#12c099", color: "#fff" },
                        }}
                        onClick={() => setSelectedStatus("Interested")}
                    >
                        Interested
                    </Button>
                    <Button
                        variant={selectedStatus === "Not Interested" ? "contained" : "outlined"}
                        sx={{
                            borderColor: "#ff4d4d",
                            color: "#ff4d4d",
                            "&:hover": { backgroundColor: "#ff4d4d", color: "#fff" },
                        }}
                        onClick={() => setSelectedStatus("Not Interested")}
                    >
                        Not Interested
                    </Button>
                </Box>

                {/* ⚡️ Action Buttons */}
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button
                        variant="text"
                        sx={{ color: "#bbb" }}
                        onClick={onClose}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: selectedStatus ? "#12c099" : "#666",
                            color: "#fff",
                            "&:hover": { backgroundColor: selectedStatus ? "#0fa888" : "#666" },
                        }}
                        onClick={updateStatus}
                        disabled={!selectedStatus || loading}
                    >
                        {loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Update"}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default UpdateStatusModal;
