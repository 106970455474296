import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// ✅ Register required Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TotalSpend = () => {
    const [view, setView] = useState('monthly');

    const monthlyData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
            {
                label: 'Marketing Spend ($)',
                data: [50000, 75000, 68000, 92000, 100000, 115000],
                backgroundColor: '#FF6384',
            },
        ],
    };

    const weeklyData = {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
        datasets: [
            {
                label: 'Marketing Spend ($)',
                data: [12000, 18000, 15000, 20000],
                backgroundColor: '#FF6384',
            },
        ],
    };

    return (
        <Card sx={{ backgroundColor: '#202020', color: '#12c099', padding: '1.5rem', borderRadius: '12px', textAlign: 'center', minHeight: '300px' }}>
            <CardContent>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                    Total Marketing Spend
                </Typography>

                {/* 🔹 Buttons for Weekly/Monthly View */}
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '10px', my: 2 }}>
                    <Button
                        size="small"
                        onClick={() => setView('weekly')}
                        variant={view === 'weekly' ? 'contained' : 'outlined'}
                        sx={{
                            backgroundColor: view === 'weekly' ? "#0f8c72" : "transparent",
                            borderColor: "#12c099",
                            color: view === 'weekly' ? "#fff" : "#12c099",
                            "&:hover": { backgroundColor: "#0e7f68", color: "#fff" }
                        }}
                    >
                        Weekly
                    </Button>
                    <Button
                        size="small"
                        onClick={() => setView('monthly')}
                        variant={view === 'monthly' ? 'contained' : 'outlined'}
                        sx={{
                            backgroundColor: view === 'monthly' ? "#0f8c72" : "transparent",
                            borderColor: "#12c099",
                            color: view === 'monthly' ? "#fff" : "#12c099",
                            "&:hover": { backgroundColor: "#0e7f68", color: "#fff" }
                        }}
                    >
                        Monthly
                    </Button>
                </Box>

                {/* 🔹 Bar Chart */}
                <Bar data={view === 'weekly' ? weeklyData : monthlyData} />
            </CardContent>
        </Card>
    );
};

export default TotalSpend;
