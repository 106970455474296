import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import ProgressComponent from '../components/dashboard/ProgressComponent';
import NeighborhoodsComponent from '../components/dashboard/NeighborhoodsComponent';
import AssignedNeighborhoodsComponent from '../components/dashboard/AssignedNeighborhoodsComponent';
import AvailableCollaboratorsComponent from '../components/dashboard/AvailableCollaboratorsComponent';
import QuotasComponent from '../components/dashboard/QuotasComponent';
import SessionsTableComponent from '../components/dashboard/SessionsTableComponent';
import FooterComponent from '../components/dashboard/FooterComponent';
import UserBadge from '../components/dashboard/UserBadge';
import MyVenuesComponent from "../components/dashboard/MyVenuesComponent";

// Set Axios base URL from environment variable
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

const BookingDashboard = () => {
    const [employeeName, setEmployeeName] = useState('');
    const [formattedDate, setFormattedDate] = useState('');
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [locationDetails, setLocationDetails] = useState({ city: '', state: '', country: '', county: '' });
    const [mapCenter, setMapCenter] = useState({ lat: 37.7749, lng: -122.4194 }); // 🟡 Default SF (temporary)
    const [sport, setSport] = useState('');

    useEffect(() => {
        const auth = getAuth();
        console.log('Setting up auth state change listener');

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            console.log('Auth state changed:', user);
            if (user) {
                try {
                    const firebaseUid = user.uid;
                    console.log('Fetching employee details for firebaseUid:', firebaseUid);

                    const response = await axios.get(`/api/employees/${firebaseUid}/neighborhoods`);
                    console.log('Employee data fetched:', response.data);

                    if (response.data) {
                        const { neighborhoods, city, state, country, sport: employeeSport } = response.data;
                        setNeighborhoods(neighborhoods || []);
                        setLocationDetails({ city, state, country });

                        // 🔹 Fetch county and exact coordinates
                        fetchCountyAndSetMapCenter(city, state);

                        setSport(employeeSport || 'Unknown Sport');
                    } else {
                        console.warn('No data found for employee neighborhoods');
                    }

                    // Fetch employee name
                    const employeeResponse = await axios.get(`/api/employee/${firebaseUid}`);
                    if (employeeResponse.data) {
                        setEmployeeName(employeeResponse.data.name || 'Employee');
                    }

                } catch (error) {
                    console.error('Error fetching employee data:', error);
                    setEmployeeName('Employee');
                }
            } else {
                console.warn('User is not authenticated');
                setEmployeeName('Guest');
            }
        });

        const today = new Date();
        setFormattedDate(today.toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        }));

        return () => {
            console.log('Cleaning up auth state listener');
            unsubscribe();
        };
    }, []);

    // 🔹 Function to fetch county and set map center
    const fetchCountyAndSetMapCenter = async (city, state) => {
        try {
            const response = await axios.get(`/api/geocode/county`, { params: { city, state } });

            if (response.data) {
                const { county, lat, lng } = response.data;
                setLocationDetails(prev => ({ ...prev, county }));
                setMapCenter({ lat, lng });
                console.log(`Map centered to ${county}:`, { lat, lng });
            } else {
                console.error('County or coordinates not found');
            }
        } catch (error) {
            console.error('Error fetching county data:', error);
        }
    };

    return (
        <Box sx={{ padding: '2rem', backgroundColor: '#1c1c1c', color: '#fff', minHeight: '100vh', display: 'flex', flexDirection: 'column', gap: '2rem' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '0.2rem' }}>
                <Typography variant="h4" gutterBottom>{employeeName} | {formattedDate}</Typography>
                <UserBadge firebaseUid={getAuth().currentUser?.uid} />
            </Box>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <ProgressComponent firebaseUid={getAuth().currentUser?.uid} />
                </Grid>
                <Grid item xs={12} sx={{ height: '600px' }}> {/* ⬆ Increased height */}
                    <NeighborhoodsComponent mapCenter={mapCenter} setMapCenter={setMapCenter} />
                </Grid>
                <Grid item xs={12} sx={{ mt: 3 }}> {/* ⬆ Added margin-top for spacing */}
                    <AssignedNeighborhoodsComponent neighborhoods={neighborhoods} userLocation={locationDetails} />
                </Grid>
                <Grid item xs={12}>
                    <MyVenuesComponent />
                </Grid>
                <Grid item xs={12}>
                    <AvailableCollaboratorsComponent />
                </Grid>
                <Grid item xs={12} md={4}>
                    <QuotasComponent quotaType="daily" address={locationDetails} sport={sport} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <QuotasComponent quotaType="weekly" address={locationDetails} sport={sport} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <QuotasComponent quotaType="monthly" address={locationDetails} sport={sport} />
                </Grid>
                <Grid item xs={12}>
                    <SessionsTableComponent />
                </Grid>
                <Grid item xs={12}>
                    <FooterComponent />
                </Grid>
            </Grid>
        </Box>
    );
};

export default BookingDashboard;
