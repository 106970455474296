import React from "react";
import { Card, CardContent, Typography, Link, Box } from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const QRCode = ({ qrCodes }) => {
    const qrLink = qrCodes?.[0] || "";

    return (
        <Card
            sx={{
                backgroundColor: "#202020",
                padding: "2rem",
                borderRadius: "12px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "340px"
            }}
        >
            <CardContent>
                <Typography variant="h5" sx={{ color: "#12c099", mb: 2 }}>
                    QR Code
                </Typography>

                <QRCodeCanvas value={qrLink} size={220} />

                {qrLink && (
                    <Box mt={2}>
                        <Link
                            href={qrLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            underline="hover"
                            sx={{ color: "#12c099", display: "flex", alignItems: "center", gap: "4px" }}
                        >
                            Open QR Link
                            <OpenInNewIcon sx={{ fontSize: "18px" }} />
                        </Link>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default QRCode;
