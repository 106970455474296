import React, { useState } from "react";
import { Paper, Typography, List, ListItem, ListItemText, Link, Pagination, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const SectionTitle = styled(Typography)({
    color: "#12c099",
    fontWeight: "bold",
    marginBottom: "1rem",
});

const AthleteReservations = ({ reservations }) => {
    const [page, setPage] = useState(1);
    const reservationsPerPage = 5; // ✅ Display 5 reservations per page

    // Calculate total pages
    const totalPages = Math.ceil(reservations.length / reservationsPerPage);

    // Get reservations for the current page
    const paginatedReservations = reservations.slice(
        (page - 1) * reservationsPerPage,
        page * reservationsPerPage
    );

    return (
        <Paper sx={{ padding: "1.5rem", backgroundColor: "#202020", color: "#fff", borderRadius: "8px", marginTop: "2rem" }}>
            <SectionTitle variant="h6">Reservations</SectionTitle>
            {reservations.length === 0 ? (
                <Typography sx={{ color: "#fff" }}>No reservations found.</Typography>
            ) : (
                <>
                    <List>
                        {paginatedReservations.map((session, index) => (
                            <ListItem key={index}>
                                <ListItemText
                                    primary={
                                        <Link href={`/session/${session._id}`} sx={{ color: "#12c099", textDecoration: "none", fontWeight: "bold" }}>
                                            {session.name}
                                        </Link>
                                    }
                                    secondary={
                                        <Typography sx={{ color: "#fff" }}> {/* ✅ Date is now white */}
                                            Date: {new Date(session.eventDate).toLocaleDateString()}
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>

                    {/* ✅ Pagination Controls */}
                    {totalPages > 1 && (
                        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
                            <Pagination
                                count={totalPages}
                                page={page}
                                onChange={(event, value) => setPage(value)}
                                sx={{
                                    "& .MuiPaginationItem-root": { color: "#12c099" },
                                }}
                            />
                        </Box>
                    )}
                </>
            )}
        </Paper>
    );
};

export default AthleteReservations;
