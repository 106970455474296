import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    Box,
    Typography,
    Divider,
    TextField,
    Card,
    CardContent,
    IconButton,
    CircularProgress,
    Button
} from "@mui/material";
import axios from "axios";
import { getAuth } from "firebase/auth";

const FacilityChangelog = () => {
    const { id } = useParams();
    const [venueName, setVenueName] = useState("");
    const [updates, setUpdates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editingIndex, setEditingIndex] = useState(null);
    const [editText, setEditText] = useState("");
    const [newNoteText, setNewNoteText] = useState("");
    const [addingIndex, setAddingIndex] = useState(null);
    const [todayComment, setTodayComment] = useState("");
    const [currentUser, setCurrentUser] = useState("Fetching user...");
    const [employeeId, setEmployeeId] = useState(null); // ✅ Store ObjectId


    useEffect(() => {
        const fetchChangelog = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/venues/${id}/changelog`
                );

                console.log("✅ Changelog Response:", response.data);

                // ✅ Ensure response.data.changelog is always an array
                if (!response.data || !Array.isArray(response.data.changelog)) {
                    setUpdates([]); // Fallback to an empty array
                    return;
                }

                setVenueName(response.data.name || "Unknown Venue");
                setUpdates(response.data.changelog.sort((a, b) => new Date(b.date) - new Date(a.date)));
            } catch (err) {
                console.error("❌ Failed to fetch changelog:", err);
                setUpdates([]); // Prevent undefined issues
            } finally {
                setLoading(false);
            }
        };


        const fetchCurrentUser = async () => {
            const auth = getAuth();
            const user = auth.currentUser;

            if (user) {
                try {
                    console.log("🔍 Fetching employee details for Firebase UID:", user.uid);

                    // Step 1: Fetch employee document using Firebase UID
                    const response = await axios.get(
                        `${process.env.REACT_APP_BACKEND_URL}/api/employee/${user.uid}`
                    );

                    console.log("✅ Employee response:", response.data);

                    if (response.data && response.data._id) {
                        setEmployeeId(response.data._id); // ✅ Store employee ObjectId
                        setCurrentUser(response.data.name || "Unknown User");
                    } else {
                        setCurrentUser("Unknown User");
                    }
                } catch (error) {
                    console.error("❌ Error fetching user details:", error);
                    setCurrentUser("Unknown User");
                }
            } else {
                setCurrentUser("Not Logged In");
            }
        };

        fetchChangelog();
        fetchCurrentUser();
    }, [id]);

    const saveTodayComment = async () => {
        if (!todayComment.trim()) return;

        if (!employeeId) {
            console.error("❌ No employeeId found. Cannot save changelog.");
            return;
        }

        let updatedChangelog = [...updates]; // Clone existing updates array

        // ✅ Check if there's an existing changelog entry for today
        const today = new Date().toISOString().split("T")[0]; // Get YYYY-MM-DD format
        let existingEntryIndex = updatedChangelog.findIndex(entry => entry.date.startsWith(today));

        if (existingEntryIndex !== -1) {
            // ✅ If a changelog entry exists today, append comment
            updatedChangelog[existingEntryIndex].comments.push(todayComment);
        } else {
            // ✅ Otherwise, create a new changelog entry
            const newUpdate = {
                date: new Date().toISOString(),
                status: "New Entry",
                comments: [todayComment],
                employeeName: currentUser,
                employeeId
            };

            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_URL}/api/add-changelog/${id}`,
                    newUpdate
                );

                updatedChangelog.unshift({
                    ...newUpdate,
                    _id: response.data._id || `temp-${Date.now()}`
                });
            } catch (error) {
                console.error("❌ Error adding today's update:", error.response ? error.response.data : error);
            }
        }

        // ✅ Ensure sorting from most recent to oldest
        updatedChangelog.sort((a, b) => new Date(b.date) - new Date(a.date));

        setUpdates(updatedChangelog);
        setTodayComment("");
    };


    const deleteChangelogEntry = async (changelogId) => {
        try {
            await axios.delete(
                `${process.env.REACT_APP_BACKEND_URL}/api/delete-changelog/${id}/${changelogId}`
            );

            // ✅ Remove deleted entry from UI state
            setUpdates(updates.filter(entry => entry._id !== changelogId));
        } catch (error) {
            console.error("❌ Error deleting changelog entry:", error.response ? error.response.data : error);
        }
    };



    return (
        <Box sx={{ padding: "2rem", backgroundColor: "#1c1c1c", minHeight: "100vh", color: "#ddd" }}>
            {loading ? (
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <CircularProgress sx={{ color: "#12c099" }} />
                </Box>
            ) : error ? (
                <Typography sx={{ textAlign: "center", marginTop: "20px", color: "red" }}>{error}</Typography>
            ) : (
                <>
                    <Typography variant="h3" gutterBottom sx={{ textAlign: "center", fontWeight: "bold", color: "#12c099" }}>
                        {venueName} - Changelogs
                    </Typography>

                    {/* 📌 Today's Changelog Entry */}
                    <Card sx={{ backgroundColor: "#202020", marginBottom: "1.5rem", borderRadius: "8px", padding: "1rem" }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ color: "#12c099", fontWeight: "bold" }}>
                                {new Date().toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" })}
                            </Typography>
                            <Typography variant="body2" sx={{ color: "#bbb", fontStyle: "italic", mb: 2 }}>
                                Updated by: {currentUser}
                            </Typography>
                            <Divider sx={{ backgroundColor: "#12c099", marginBottom: "10px" }} />

                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                multiline
                                rows={4}
                                placeholder="Enter updates (you can add multiple at once)..."
                                value={todayComment}
                                onChange={(e) => setTodayComment(e.target.value)}
                                sx={{
                                    backgroundColor: "#333",
                                    color: "#fff", // ✅ Ensures text inside is white
                                    "& .MuiInputBase-input": {
                                        color: "#fff" // ✅ Makes typed text white
                                    },
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor: "#12c099" // Optional: green border
                                        },
                                        "&:hover fieldset": {
                                            borderColor: "#12c099"
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor: "#12c099"
                                        }
                                    }
                                }}
                            />


                            <Button sx={{ color: "#12c099", mt: 1 }} onClick={saveTodayComment}>
                                Save Update
                            </Button>
                        </CardContent>
                    </Card>

                    {Array.isArray(updates) && updates.length > 0 ? (
                        updates.map((log, updateIndex) => (
                            <Card key={updateIndex} sx={{ backgroundColor: "#202020", marginBottom: "1.5rem", borderRadius: "8px", padding: "1rem" }}>
                                <CardContent>
                                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography variant="h5" sx={{ color: "#12c099", fontWeight: "bold" }}>
                                            {log.date ? new Date(log.date).toLocaleDateString("en-US", { month: "long", day: "numeric", year: "numeric" }) : "Invalid Date"}
                                        </Typography>

                                        {/* 🗑️ Delete button */}
                                        <IconButton onClick={() => deleteChangelogEntry(log._id)} sx={{ color: "red" }}>
                                            🗑️
                                        </IconButton>
                                    </Box>

                                    <Typography variant="body2" sx={{ color: "#bbb", fontStyle: "italic", mb: 2 }}>
                                        Updated by: {log.employeeName || "Unknown"}
                                    </Typography>
                                    <Divider sx={{ backgroundColor: "#12c099", marginBottom: "10px" }} />

                                    {Array.isArray(log.comments) && log.comments.length > 0 ? (
                                        log.comments.map((note, noteIndex) => (
                                            <Typography key={noteIndex} variant="body1" sx={{ color: "#ddd" }}>
                                                • {note}
                                            </Typography>
                                        ))
                                    ) : (
                                        <Typography variant="body1" sx={{ color: "#bbb" }}>
                                            No comments available.
                                        </Typography>
                                    )}
                                </CardContent>
                            </Card>
                        ))
                    ) : (
                        <Typography sx={{ textAlign: "center", color: "#bbb" }}>
                            No updates available.
                        </Typography>
                    )}

                </>
            )}
        </Box>
    );
};

export default FacilityChangelog;
