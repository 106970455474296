import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';

const SiteManagersNearby = () => {
    const facility = {
        siteManagersNearby: ['Emily Davis', 'James Carter'],
    };

    return (
        <Card sx={{ flex: 1, backgroundColor: '#202020', color: '#fff', borderRadius: '12px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', padding: '1.5rem', marginBottom: '1rem' }}>
            <CardContent>
                <Typography variant="h6">Site Managers Nearby</Typography>
                {facility.siteManagersNearby.map((manager, index) => (
                    <Typography key={index}>• {manager}</Typography>
                ))}
            </CardContent>
        </Card>
    );
};

export default SiteManagersNearby;
