import React from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Underperformers = () => {
    const underperformers = [
        { name: "Partner X", loss: "$20K" },
        { name: "Partner Y", loss: "$18K" },
        { name: "Partner Z", loss: "$15K" },
        { name: "Partner W", loss: "$12K" },
        { name: "Partner V", loss: "$10K" }
    ];

    return (
        <Card sx={{ backgroundColor: '#202020', color: '#fff', padding: '1.5rem', borderRadius: '12px' }}>
            <CardContent>
                <Typography variant="h6" sx={{ marginBottom: '1rem', fontWeight: '500' }}>
                    Top 5 Underperformers
                </Typography>
                <List>
                    {underperformers.map((partner, index) => (
                        <ListItem key={index}>
                            <ListItemText
                                primary={partner.name}
                                secondary={`Loss: ${partner.loss}`}
                                sx={{ color: '#e57373' }}
                            />
                        </ListItem>
                    ))}
                </List>

                {/* 🆕 View All Partners Link */}
                <Box sx={{ textAlign: 'center', marginTop: '1.5rem' }}>
                    <Button
                        component={Link}
                        to="/partners"
                        sx={{ color: '#12c099', fontWeight: 'bold', textTransform: 'none' }}
                    >
                        View All Partners
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default Underperformers;
