import React from 'react';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)({
    backgroundColor: '#202020',
    color: '#fff',
    borderRadius: '12px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    padding: '1.5rem',
    marginBottom: '20px',
    flex: 1, // Ensures equal flex space
});

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: '#202020',
    borderRadius: '12px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
});

const HourlyRate = () => {
    const facility = {
        hourlyRate: "$250 per hour",
        insuranceFee: "$25",
        maintenanceFee: "$15",
        additionalFees: "$10",
        lastUpdated: "2025-01-28",
    };

    return (
        <StyledCard>
            <CardContent>
                <Typography variant="h6">Hourly Rate Breakdown</Typography>
                <Typography variant="h4" color="#12c099">{facility.hourlyRate}</Typography>
                <StyledTableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ color: "#ddd" }}>Insurance Fee</TableCell>
                                <TableCell sx={{ color: "#ddd" }}>{facility.insuranceFee}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: "#ddd" }}>Maintenance Fee</TableCell>
                                <TableCell sx={{ color: "#ddd" }}>{facility.maintenanceFee}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: "#ddd" }}>Additional Fees</TableCell>
                                <TableCell sx={{ color: "#ddd" }}>{facility.additionalFees}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ color: "#aaa" }}>Last Updated</TableCell>
                                <TableCell sx={{ color: "#aaa" }}>{facility.lastUpdated}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            </CardContent>
        </StyledCard>
    );
};

export default HourlyRate;
