import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
    Home as HomeIcon,
    CalendarToday as CalendarIcon,
    Group as GroupIcon,
    Sports as SportsIcon,
    LocationOn as LocationOnIcon,
    BarChart as BarChartIcon,
    Event as EventIcon,
    VideoLibrary as VideoLibraryIcon,
    PlaylistAddCheck as WaitlistIcon,
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    ExitToApp as ExitToAppIcon,
    Store as StoreIcon,
    SupervisorAccount as SupervisorAccountIcon,
    Business as BusinessIcon,
    PeopleAlt as PeopleAltIcon,
    PersonAdd as ApplicantsIcon,
    Work as WorkIcon,
    TrendingUp as TrendingUpIcon,
    Outbox as OutboxIcon,
} from '@mui/icons-material';

const drawerWidth = 240;

const CustomDrawer = styled(Drawer)(({ open }) => ({
    width: open ? drawerWidth : 60,
    transition: 'width 0.3s',
    whiteSpace: 'nowrap',
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: open ? drawerWidth : 60,
        transition: 'width 0.3s',
        overflowX: 'hidden',
        backgroundColor: '#202020',
        color: '#fff',
    },
}));

const CustomListItemIcon = styled(ListItemIcon)({
    color: '#12c099',
});

const Sidebar = ({ open, handleDrawerToggle, handleLogout }) => {
    const [totalLeads, setTotalLeads] = useState(0);

    useEffect(() => {
        const fetchTotalLeads = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/leads`);
                setTotalLeads(response.data.length);
            } catch (error) {
                console.error("❌ Error fetching lead count:", error);
            }
        };

        fetchTotalLeads();
    }, []);

    return (
        <CustomDrawer variant="permanent" open={open}>
            <IconButton onClick={handleDrawerToggle} sx={{ color: '#12c099' }}>
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>

            {/* ✅ Sidebar Logo */}
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px 0' }}>
                {open && (
                    <img
                        src="https://d3dbfxrvl5y81z.cloudfront.net/academy_white.png"
                        alt="MarsReel"
                        style={{ height: '40px' }}
                    />
                )}
            </Box>

            <List sx={{ flexGrow: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', mt: 2 }}>
                    <ListItem button component={Link} to="/dashboard">
                        <CustomListItemIcon>
                            <HomeIcon />
                        </CustomListItemIcon>
                        {open && <ListItemText primary="Overview" />}
                    </ListItem>

                    <ListItem button component={Link} to="/BookingDashboard">
                        <CustomListItemIcon>
                            <CalendarIcon />
                        </CustomListItemIcon>
                        {open && <ListItemText primary="Dashboard" />}
                    </ListItem>

                    {/* ✅ Leads with Total Count */}
                    <ListItem button component={Link} to="/leads">
                        <CustomListItemIcon>
                            <PeopleAltIcon />
                        </CustomListItemIcon>
                        {open && (
                            <ListItemText
                                primary={
                                    <Typography variant="body1">
                                        Leads <strong>({totalLeads})</strong>
                                    </Typography>
                                }
                            />
                        )}
                    </ListItem>

                    <ListItem button component={Link} to="/outreach">
                        <CustomListItemIcon>
                            <OutboxIcon />
                        </CustomListItemIcon>
                        {open && <ListItemText primary="Outreach" />}
                    </ListItem>

                    <ListItem button component={Link} to="/waitlist">
                        <CustomListItemIcon>
                            <WaitlistIcon />
                        </CustomListItemIcon>
                        {open && <ListItemText primary="Waitlist" />}
                    </ListItem>

                    <ListItem button component={Link} to="/athletes">
                        <CustomListItemIcon>
                            <GroupIcon />
                        </CustomListItemIcon>
                        {open && <ListItemText primary="Athletes" />}
                    </ListItem>

                    <ListItem button component={Link} to="/coaches">
                        <CustomListItemIcon>
                            <SportsIcon />
                        </CustomListItemIcon>
                        {open && <ListItemText primary="Coaches" />}
                    </ListItem>

                    <ListItem button component={Link} to="/sitemanagers">
                        <CustomListItemIcon>
                            <SupervisorAccountIcon />
                        </CustomListItemIcon>
                        {open && <ListItemText primary="Site Managers" />}
                    </ListItem>

                    <ListItem button component={Link} to="/employees">
                        <CustomListItemIcon>
                            <WorkIcon />
                        </CustomListItemIcon>
                        {open && <ListItemText primary="Employees" />}
                    </ListItem>

                    <ListItem button component={Link} to="/applicants">
                        <CustomListItemIcon>
                            <ApplicantsIcon />
                        </CustomListItemIcon>
                        {open && <ListItemText primary="Applicants" />}
                    </ListItem>

                    <ListItem button component={Link} to="/facilities/countries">
                        <CustomListItemIcon>
                            <BusinessIcon />
                        </CustomListItemIcon>
                        {open && <ListItemText primary="Facilities" />}
                    </ListItem>

                    <ListItem button component={Link} to="/markets">
                        <CustomListItemIcon>
                            <LocationOnIcon />
                        </CustomListItemIcon>
                        {open && <ListItemText primary="Markets" />}
                    </ListItem>

                    <ListItem button component={Link} to="/sessions">
                        <CustomListItemIcon>
                            <EventIcon />
                        </CustomListItemIcon>
                        {open && <ListItemText primary="Sessions" />}
                    </ListItem>

                    <ListItem button component={Link} to="/financials">
                        <CustomListItemIcon>
                            <BarChartIcon />
                        </CustomListItemIcon>
                        {open && <ListItemText primary="Financials" />}
                    </ListItem>

                    <ListItem button component={Link} to="/contentfeed">
                        <CustomListItemIcon>
                            <VideoLibraryIcon />
                        </CustomListItemIcon>
                        {open && <ListItemText primary="Content Feed" />}
                    </ListItem>

                    <ListItem button component={Link} to="/marketing">
                        <CustomListItemIcon>
                            <TrendingUpIcon />
                        </CustomListItemIcon>
                        {open && <ListItemText primary="Marketing" />}
                    </ListItem>
                </Box>
            </List>

            {/* ✅ Logout Button */}
            <List>
                <ListItem button onClick={handleLogout}>
                    <CustomListItemIcon>
                        <ExitToAppIcon />
                    </CustomListItemIcon>
                    {open && <ListItemText primary="Logout" />}
                </ListItem>
            </List>
        </CustomDrawer>
    );
};

export default Sidebar;
