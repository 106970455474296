import React, { useState, useEffect } from "react";
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, CircularProgress
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import SearchBar from "../components/venues/SearchBar";
import axios from "axios";

const StyledTableContainer = styled(TableContainer)({
    backgroundColor: "#202020",
    borderRadius: "12px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
    overflowX: "auto",
});

const CompactTableCell = styled(TableCell)({
    padding: "16px 20px",
    fontSize: "1rem",
    color: "#fff",
});

const FacilitiesCountry = () => {
    const [countries, setCountries] = useState([]); // Default countries list (on page load)
    const [searchResults, setSearchResults] = useState([]); // Results when searching
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSearching, setIsSearching] = useState(false);

    // ✅ Fetch countries only ONCE (on page load)
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                console.log("🔹 Fetching unique countries with venue counts...");
                const response = await axios.get("https://api.admin.marsreel.com/api/venue-countries-count");

                if (!response.data || response.data.length === 0) {
                    setError("No countries found in the database.");
                    return;
                }

                const sortedCountries = response.data.sort((a, b) => a.name.localeCompare(b.name));
                setCountries(sortedCountries);
                sessionStorage.setItem("countries", JSON.stringify(sortedCountries)); // ✅ Store in sessionStorage
            } catch (error) {
                console.error("❌ Error fetching countries:", error);
                setError("Failed to load countries. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        const cachedCountries = sessionStorage.getItem("countries");
        if (cachedCountries) {
            setCountries(JSON.parse(cachedCountries));
            setLoading(false);
        } else {
            fetchCountries();
        }
    }, []);

    // 🔎 **Search Function**
    const handleSearch = async (query) => {
        if (!query) {
            setSearchResults([]); // Reset search
            setIsSearching(false);
            return;
        }

        setIsSearching(true);
        try {
            console.log(`🔎 Searching for venues with: "${query}"`);
            const response = await axios.get(`https://api.admin.marsreel.com/api/search-venues?query=${query}`);
            console.log("🔎 Search response data:", response.data);

            setSearchResults(response.data);
        } catch (error) {
            console.error("❌ Error searching venues:", error);
            setSearchResults([]);
        } finally {
            setIsSearching(true); // ✅ Ensures UI updates
        }
    };

    // **Dynamic Table Headers**
    const tableHeaders = searchResults.length > 0
        ? ["Name", "Changelog", "View Details"]  // 🔍 Searching mode
        : ["Country", "Total Facilities", "View Facilities"]; // 🌍 Default mode

    // **Dynamic Data Display**
    const displayData = searchResults.length > 0 ? searchResults : countries;

    return (
        <Box sx={{ padding: "1rem", backgroundColor: "#1c1c1c", minHeight: "100vh", color: "#fff" }}>
            <Typography variant="h4" gutterBottom>Global Facilities Directory</Typography>

            {/* 🔎 Search Bar */}
            <SearchBar placeholder="Search Facilities" onSearch={handleSearch} />

            {/* 🔄 Loading Spinner */}
            {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                    <CircularProgress sx={{ color: "#12c099" }} />
                </Box>
            )}

            {/* ❌ Error Handling */}
            {error && !loading && (
                <Typography sx={{ textAlign: "center", marginTop: "20px", color: "red" }}>
                    {error}
                </Typography>
            )}

            {/* 🔎 Search Results / Default Data */}
            {!loading && (
                <StyledTableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableHeaders.map((header, index) => (
                                    <CompactTableCell key={index}>{header}</CompactTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayData.length > 0 ? (
                                displayData.map((item) => (
                                    <TableRow key={item.id || item._id}>
                                        {searchResults.length > 0 ? (
                                            // ✅ If searching, show "Name" & "Changelog"
                                            <>
                                                <CompactTableCell>{item.name}</CompactTableCell>
                                                <CompactTableCell>
                                                    <Button
                                                        component={Link}
                                                        to={`/facilities/${encodeURIComponent(item._id)}/changelog`}
                                                        sx={{ color: "#12c099", textTransform: "none" }}
                                                    >
                                                        View Changelog
                                                    </Button>
                                                </CompactTableCell>
                                                <CompactTableCell>
                                                    <Button
                                                        component={Link}
                                                        to={`/venues/${encodeURIComponent(item._id)}`}
                                                        sx={{ color: "#12c099", textTransform: "none" }}
                                                    >
                                                        View Details
                                                    </Button>
                                                </CompactTableCell>
                                            </>
                                        ) : (
                                            // 🌍 Default view (Countries List)
                                            <>
                                                <CompactTableCell>{item.name}</CompactTableCell>
                                                <CompactTableCell>{item.venueCount || "N/A"}</CompactTableCell>
                                                <CompactTableCell>
                                                    <Button
                                                        component={Link}
                                                        to={`/facilities/${encodeURIComponent(item.name)}/regions/`}
                                                        sx={{ color: "#12c099", textTransform: "none" }}
                                                    >
                                                        View Facilities
                                                    </Button>
                                                </CompactTableCell>
                                            </>
                                        )}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={3} align="center" sx={{ color: "#fff" }}>
                                        {isSearching ? "No results found." : "No data available."}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            )}
        </Box>
    );
};

export default FacilitiesCountry;
