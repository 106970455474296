import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const CPL = () => {
    const dummyCPL = 12.30; // Dummy data

    return (
        <Card sx={{
            backgroundColor: '#202020',
            color: '#12c099',
            padding: '1.5rem',
            borderRadius: '12px',
            textAlign: 'center',
            minHeight: '150px',
        }}>
            <CardContent>
                <Typography variant="h6">Cost Per Lead (CPL)</Typography>
                <Typography variant="h4" sx={{ fontWeight: 'bold' }}>${dummyCPL.toFixed(2)}</Typography>
            </CardContent>
        </Card>
    );
};

export default CPL;
