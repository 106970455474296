import React, { useState } from "react";
import { TextField, Box } from "@mui/material";
import debounce from "lodash/debounce";

const SearchBar = ({ onSearch, placeholder = "Search..." }) => {
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = debounce((event) => {
        const value = event.target.value;
        setSearchTerm(value);
        onSearch(value); // ✅ Calls `handleSearch` from parent (FacilitiesCountry.js)
    }, 500); // ⏳ Debounced API calls

    return (
        <Box sx={{ marginBottom: "16px" }}>
            <TextField
                fullWidth
                variant="outlined"
                label={placeholder}
                onChange={handleSearchChange}
                sx={{
                    backgroundColor: "#202020",
                    borderRadius: "8px",
                    "& .MuiInputBase-root": { color: "#fff" },
                    "& .MuiOutlinedInput-notchedOutline": { borderColor: "#12c099" },
                    "& .MuiInputLabel-root": { color: "#12c099" },
                }}
            />
        </Box>
    );
};

export default SearchBar;
