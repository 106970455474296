import React from "react";
import { Card, CardContent, Typography } from "@mui/material";

const Region = ({ scanRegions }) => {
    return (
        <Card sx={{ backgroundColor: "#202020", padding: "1.5rem", borderRadius: "12px" }}>
            <CardContent>
                <Typography variant="h5" sx={{ color: "#12c099" }}>Region</Typography>
                {scanRegions?.length > 0 ? (
                    scanRegions.map((region, index) => (
                        <Typography key={index} variant="h6" sx={{ color: "#fff" }}>
                            {region.regionName || "Unknown Region"}
                        </Typography>
                    ))
                ) : (
                    <Typography variant="h6" sx={{ color: "#fff" }}>No Region Data</Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default Region;
