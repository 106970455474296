import React, { useState } from "react";
import { Modal, Box, Typography, Button, TextField } from "@mui/material";
import axios from "axios";

const ConfirmDeleteModal = ({ open, onClose, partner, onDeleteSuccess }) => {
    const [confirmText, setConfirmText] = useState("");
    const [loading, setLoading] = useState(false);
    const correctText = "DELETE";

    const handleDelete = async () => {
        if (confirmText !== correctText) return;

        setLoading(true);
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/delete-marketing-partner/${partner._id}`);
            onDeleteSuccess(partner._id);
            onClose();
        } catch (error) {
            console.error("Error deleting partner:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="delete-partner-modal">
            <Box sx={{
                width: 400,
                bgcolor: "#202020",
                color: "#fff",
                p: 3,
                borderRadius: 2,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            }}>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                    Confirm Delete
                </Typography>

                <Typography variant="body2" sx={{ mb: 2, color: "#bbb" }}>
                    Are you sure you want to delete <strong>{partner?.name}</strong>? This action <strong>cannot</strong> be undone.
                    Type <span style={{ color: "red", fontWeight: "bold" }}>DELETE</span> to confirm.
                </Typography>

                <TextField
                    fullWidth
                    variant="outlined"
                    value={confirmText}
                    onChange={(e) => setConfirmText(e.target.value)}
                    placeholder={`Type "${correctText}" to confirm`}
                    sx={{
                        bgcolor: "#282828",
                        color: "#fff",
                        mb: 2,
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": { borderColor: "#12c099" },
                            "&:hover fieldset": { borderColor: "#12c099" },
                            "&.Mui-focused fieldset": { borderColor: "#12c099" },
                        },
                        "& .MuiInputBase-input": { color: "#fff", fontWeight: "bold" }
                    }}
                />

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button sx={{ color: "#12c099" }} onClick={onClose} disabled={loading}>
                        Cancel
                    </Button>
                    <Button
                        sx={{ color: "red" }}
                        onClick={handleDelete}
                        disabled={confirmText !== correctText || loading}
                    >
                        {loading ? "Deleting..." : "Delete"}
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ConfirmDeleteModal;
