import React, { useState } from "react";
import { Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from "@mui/material";
import { styled } from "@mui/material/styles";

const SectionTitle = styled(Typography)({
    color: "#12c099",
    fontWeight: "bold",
    marginBottom: "1rem",
});

const TransactionsContainer = styled(Paper)({
    padding: "1.5rem",
    backgroundColor: "#202020",
    color: "#fff",
    borderRadius: "8px",
    marginTop: "2rem",
});

const CoachTransactions = ({ transactions }) => {
    const [page, setPage] = useState(0);
    const rowsPerPage = 5;

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <TransactionsContainer>
            <SectionTitle variant="h6">Transaction History</SectionTitle>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ color: "#fff" }}>Date</TableCell>
                            <TableCell sx={{ color: "#fff" }}>Amount</TableCell>
                            <TableCell sx={{ color: "#fff" }}>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((transaction) => (
                                <TableRow key={transaction._id}>
                                    <TableCell sx={{ color: "#fff" }}>
                                        {new Date(transaction.date).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell sx={{ color: "#fff" }}>${transaction.amount}</TableCell>
                                    <TableCell sx={{ color: "#fff" }}>{transaction.status}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={transactions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPageOptions={[5]}
                sx={{ backgroundColor: "#202020", color: "#fff" }}
            />
        </TransactionsContainer>
    );
};

export default CoachTransactions;
