import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button
} from '@mui/material';
import { Delete as DeleteIcon, GetApp as DownloadIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';

const ContentFeed = () => {
    const [videoList, setVideoList] = useState([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [videoToDelete, setVideoToDelete] = useState(null);
    const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
    const [videoToDownload, setVideoToDownload] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const { ref, inView } = useInView();

    useEffect(() => {
        fetchVideos(page);
    }, [page]);

    const fetchVideos = async (pageNumber = 1) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/videos?page=${pageNumber}&limit=10`);
            console.log('API Response:', response.data);

            if (response.data && Array.isArray(response.data.videos)) {
                setVideoList((prevVideos) => [...prevVideos, ...response.data.videos]);
                setTotalPages(response.data.totalPages);
            } else {
                console.error('Expected an array of videos but got:', response.data);
            }
        } catch (error) {
            console.error('Error fetching videos:', error);
        }
    };

    useEffect(() => {
        if (inView && page < totalPages) {
            setPage((prevPage) => prevPage + 1);
        }
    }, [inView, page, totalPages]);

    const handleDelete = async (id) => {
        console.log(`Attempting to delete video with ID: ${id}`);
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/videos/${id}`);
            setVideoList((prevVideos) => prevVideos.filter((video) => video._id !== id));
        } catch (error) {
            console.error('Error deleting video:', error);
        }
    };

    const openDeleteDialog = (videoId) => {
        setVideoToDelete(videoId);
        setDeleteDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setVideoToDelete(null);
    };

    const confirmDelete = () => {
        if (videoToDelete) {
            handleDelete(videoToDelete);
        }
        closeDeleteDialog();
    };

    const openDownloadDialog = (videoUrl) => {
        setVideoToDownload(videoUrl);
        setDownloadDialogOpen(true);
    };

    const closeDownloadDialog = () => {
        setDownloadDialogOpen(false);
        setVideoToDownload(null);
    };

    const confirmDownload = () => {
        if (videoToDownload) {
            const link = document.createElement('a');
            link.href = videoToDownload;
            link.setAttribute('download', videoToDownload.split('/').pop()); // Ensure it's downloaded
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        closeDownloadDialog();
    };


    return (
        <PageContainer>
            <ContentFeedContainer>
                <Typography variant="h4" gutterBottom>Content Feed</Typography>
                <Grid container spacing={2}>
                    {videoList.map((video, index) => (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={video._id}
                            ref={index === videoList.length - 1 ? ref : null}
                        >
                            <Card sx={{ position: 'relative', backgroundColor: '#282828', color: '#fff', borderRadius: '10px', overflow: 'hidden' }}>
                                <video
                                    src={video.videoUrl}
                                    controls
                                    onError={() => console.error(`Failed to load video from URL: ${video.videoUrl}`)}
                                    style={{ height: 'auto', width: '100%', objectFit: 'cover' }}
                                    alt={`Video uploaded by ${video.uploadedBy?.name || 'Unknown'}`}
                                    loading="lazy"
                                />
                                <CardContent sx={{ padding: '0.5rem', backgroundColor: '#333' }}>
                                    <Typography variant="body2" sx={{ color: '#ccc', marginBottom: '5px' }}>
                                        {`Uploaded by: ${video.uploadedBy?.name || 'Unknown'}`}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#ccc', marginBottom: '5px' }}>
                                        {`Session: ${video.session?.name || 'No Session Name'}`}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: '#ccc' }}>
                                        {`Date: ${new Date(video.createdAt).toLocaleDateString()}`}
                                    </Typography>
                                </CardContent>

                                {/* White Download Button with Confirmation */}
                                <IconButton
                                    sx={{ position: 'absolute', top: '10px', left: '10px', color: '#ffffff' }}
                                    onClick={() => openDownloadDialog(video.videoUrl)}
                                >
                                    <DownloadIcon />
                                </IconButton>

                                {/* Delete Button */}
                                <IconButton
                                    sx={{ position: 'absolute', top: '10px', right: '10px', color: '#ff1744' }}
                                    onClick={() => openDeleteDialog(video._id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </ContentFeedContainer>

            {/* Confirm Delete Dialog */}
            <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this video? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog}>Cancel</Button>
                    <Button onClick={confirmDelete} color="error">Delete</Button>
                </DialogActions>
            </Dialog>

            {/* Confirm Download Dialog */}
            <Dialog open={downloadDialogOpen} onClose={closeDownloadDialog}>
                <DialogTitle>Confirm Download</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Do you want to download this video?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDownloadDialog}>Cancel</Button>
                    <Button onClick={confirmDownload} color="primary">Download</Button>
                </DialogActions>
            </Dialog>

            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </PageContainer>
    );
};

const PageContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
});

const ContentFeedContainer = styled(Box)({
    flex: 1,
    overflowY: 'auto',
    padding: '2rem',
    backgroundColor: '#1c1c1c',
    color: '#fff',
    paddingBottom: '6rem',
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: '#333',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 10,
});

export default ContentFeed;
