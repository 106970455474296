import React from "react";
import { Avatar, Box, Typography, Paper, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

const ProfileCard = styled(Paper)({
    padding: "1.5rem",
    backgroundColor: "#202020",
    color: "#fff",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    gap: "1.5rem",
});

const InfoContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
});

const AthleteProfile = ({ athlete }) => {
    return (
        <ProfileCard>
            <Avatar src={athlete.avatar} sx={{ width: 120, height: 120 }} />
            <InfoContainer>
                <Typography variant="h5">{athlete.firstName} {athlete.lastName}</Typography>
                <Divider sx={{ backgroundColor: "#fff", margin: "0.5rem 0" }} />
                <Typography variant="body1"><strong>Email:</strong> {athlete.email}</Typography>
                <Typography variant="body1"><strong>Phone:</strong> {athlete.phone}</Typography>
                <Typography variant="body1"><strong>Account Balance:</strong> {athlete.accountBalance} credits</Typography>
                <Typography variant="body1"><strong>Location:</strong> {athlete.address?.city}, {athlete.address?.state}</Typography>
                <Typography variant="body1"><strong>Sports:</strong> {athlete.sports.length > 0 ? athlete.sports.join(", ") : "N/A"}</Typography>
            </InfoContainer>
        </ProfileCard>
    );
};

export default AthleteProfile;
