import React from 'react';
import { Box, Typography, Card, CardContent } from '@mui/material';

const CoachesNearby = () => {
    const facility = {
        coachesNearby: ['Mike Thompson', 'Sarah Lee', 'David Beckham'],
    };

    return (
        <Card sx={{ flex: 1, backgroundColor: '#202020', color: '#fff', borderRadius: '12px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)', padding: '1.5rem', marginBottom: '1rem' }}>
            <CardContent>
                <Typography variant="h6">Coaches Nearby</Typography>
                {facility.coachesNearby.map((coach, index) => (
                    <Typography key={index}>• {coach}</Typography>
                ))}
            </CardContent>
        </Card>
    );
};

export default CoachesNearby;
