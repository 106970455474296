import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Card,
    CardContent,
    Typography,
    List,
    ListItem,
    ListItemText,
    Box,
    Button,
    IconButton
} from '@mui/material';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import AddMarketingPartner from './AddMarketingPartner';

const TopPartners = () => {
    const [partners, setPartners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        const fetchPartners = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/all-marketing-partners`);

                // ✅ Sort by totalSpend in descending order and take the top 5
                const sortedPartners = response.data
                    .sort((a, b) => b.totalSpend - a.totalSpend)
                    .slice(0, 5);

                setPartners(sortedPartners);
            } catch (error) {
                console.error("Error fetching partners:", error);
                setError("Failed to load partners. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchPartners();
    }, []);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    return (
        <Card sx={{ backgroundColor: '#202020', color: '#fff', padding: '1.5rem', borderRadius: '12px', position: 'relative' }}>
            <CardContent>
                <Typography variant="h6" sx={{ marginBottom: '1rem', fontWeight: '500' }}>
                    Top Marketing Partners
                </Typography>
                <IconButton
                    onClick={handleOpenModal}
                    sx={{ position: 'absolute', top: '1rem', right: '1rem', color: '#12c099' }}
                    aria-label="Add Marketing Partner"
                >
                    <AddIcon />
                </IconButton>

                {/* ✅ Show loading or error state */}
                {loading ? (
                    <Typography variant="body2" sx={{ color: '#ccc' }}>Loading...</Typography>
                ) : error ? (
                    <Typography variant="body2" sx={{ color: 'red' }}>{error}</Typography>
                ) : (
                    <List>
                        {partners.map((partner) => (
                            <ListItem
                                key={partner._id}
                                component={Link}
                                to={`/partner/${partner._id}`}
                                sx={{ textDecoration: 'none', color: 'inherit' }}
                            >
                                <ListItemText
                                    primary={partner.name}
                                    secondary={`Total Spend: $${partner.totalSpend.toLocaleString()}`}
                                    sx={{ color: '#12c099' }}
                                />
                            </ListItem>
                        ))}
                    </List>
                )}

                <Box sx={{ textAlign: 'center', marginTop: '1.5rem' }}>
                    <Button
                        component={Link}
                        to="/partners"
                        sx={{ color: '#12c099', fontWeight: 'bold', textTransform: 'none' }}
                    >
                        View All Partners
                    </Button>
                </Box>
            </CardContent>

            {/* ✅ Add Partner Modal */}
            <AddMarketingPartner open={openModal} handleClose={handleCloseModal} />
        </Card>
    );
};

export default TopPartners;
