import React from 'react';
import { Box, CssBaseline, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import GlobalQuotaComponent from '../components/dashboard/GlobalQuotaComponent';
import PayGoMembersComponent from '../components/dashboard/PayGoMembersComponent';
import RecurringMembersComponent from '../components/dashboard/RecurringMembersComponent';
import ActiveLocationsComponent from '../components/dashboard/ActiveLocationsComponent';
import GrossMarginComponent from "../components/dashboard/GrossMarginComponent"; // ✅ Import Gross Margin Component
import RevenueComponent from "../components/dashboard/RevenueComponent"; // ✅ Import Revenue Component
import MonthlyRecurringRevenueComponent from "../components/dashboard/RecurringRevenueComponent"; // ✅ Import Component
import DigitalSubscriptionsRevenueComponent from "../components/dashboard/DigitalSubscriptionsRevenueComponent"; // ✅ Import new component
import FeedComponent from "../components/dashboard/FeedComponent";

const Root = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: '#1c1c1c',
    color: '#fff',
    boxSizing: 'border-box',
    overflowX: 'hidden',
});

const Footer = styled(Box)({
    padding: '1rem',
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'transparent',
    marginTop: 'auto',
    width: '100%',
});

const Dashboard = () => {
    return (
        <Root>
            <CssBaseline />
            <Box sx={{ flex: 1, padding: '2rem' }}>
                <Box sx={{ marginBottom: '2.5rem' }}>
                    <Typography variant="h3" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
                        Management Overview
                    </Typography>
                </Box>

                {/* 🔹 Ensuring Grid is properly closed */}
                <Grid container spacing={4} alignItems="stretch">
                    <Grid item xs={12} md={8} sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                        <FeedComponent />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                        <MonthlyRecurringRevenueComponent />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <GrossMarginComponent />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <RevenueComponent />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <DigitalSubscriptionsRevenueComponent />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <RecurringMembersComponent />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <PayGoMembersComponent />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <ActiveLocationsComponent />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <GlobalQuotaComponent />
                    </Grid>
                </Grid> {/* ✅ Properly closed Grid */}
            </Box> {/* ✅ Properly closed Box */}

            {/* Footer Section */}
            <Footer>
                <Typography variant="body2">
                    &copy; {new Date().getFullYear()} Mars Reel Media Corp. All rights reserved.
                </Typography>
            </Footer>
        </Root>
    );
};

export default Dashboard;
